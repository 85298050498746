import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { is } from 'date-fns/locale';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {

  constructor(private auth: AuthenticationService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    // if the user is logged in, redirect to home page
    const isAuthenticated = await this.auth.isAuthenticated();
    if (isAuthenticated) {
      // if there is an RFQ ID in local storage, then redirect to the site address page
      const rfqId = localStorage.getItem('rfqId');
      if (rfqId) {
        this.router.navigate(['/quotes/siteaddressvalidation']);
        return;
      }
      // otherwise, redirect to home page
      this.router.navigate(['/home']);
    }
  }

}
