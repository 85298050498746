import { Account } from "./account-model";
import { Address } from "./address-model";
import { JobsiteModel } from "./jobsite-model";
import { BundlesForm } from "./product-model";
import { QuoteLineModel } from "./quote-line-model";
import { Contact } from "./quoteState-model";

// {
//   accountId: "0018I00000fYFDpQAO"; //
//   startDate: "2023-09-20"; //form
//   endDate: "2049-12-31"; //form
//   orderType: "Recurring without End Date"; // form
//   zipcode: "01581"; //entered
//   duration: "12-24 Months"; // picklist
//   billTiming: "Bill in Advance";
//   billingPeriod: "28 Day Bill Period";
//   customerType: "Consumer";
//   businessType: "Consumer";
//   address: {
//     //   USF_Account__c: obj.accountId;
//     USF_City__c: "Boston";
//     USF_Country__c: "US";
//     USF_Ship_To_Address__c: true;
//     USF_State__c: "MA";
//     USF_Street__c: "118 Flanders Rd";
//     USF_Zip_Code__c: "01581";
//     Site_Name__c: "Test Site Name";
//     Address_Latitude_Longitude__Latitude__s: 42.278927;
//     Address_Latitude_Longitude__Longitude__s: -71.5732386;
//     NF_Placement__c: "By the office";
//   }
//   productDetails: [
//     {
//       productIdBundle: "01t3m00000PPH5RAAX", // Id //ProductType__c = Bundle
//       productOptionSkuBundle: "",
//       bundleSummery: "",
//       bundleQty: 1,
//       productIdService: "01t3m00000NTix0AAD",
//       productOptionSkuService: "",
//       serviceSummery: "",
//       productIdAsset: "01t3m00000NTiuFAAT", //SBQQ__Options__r.records[i].SBQQ__OptionalSKU__c
//       productOptionSkuAsset: "", //SBQQ__Options__r.records[i].Id
//       assetSummary: "Standard Restroom", //Asset_Summary__c // ProductType__c: Asset
//       additionalProduct: [
//         {
//           productIdAS: "01t3m00000POgyHAAT", // Id //ProductType__c = Bundle
//           productOptionSkuAS: "",
//           aSSummery: "",
//         },
//       ],
//     },
//   ];
// }

export class UnitAndServiceModel {
  accountId: String;
  startDate: String;
  endDate: String;
  orderType: String;
  zipcode: String;
  duration: String;
  billTiming: String;
  billingPeriod: String;
  customerType: String;
  businessType: String;
  address: Address;
  prodSubType: string;
  productDetails: ProductDetails[];
}

export class ProductDetails {
  productIdBundle: String;
  productOptionSkuBundle: String;
  bundleSummery: String;
  bundleQty: Number;
  productIdService: String;
  productOptionSkuService: String;
  serviceSummery: String;
  productIdAsset: String;
  productOptionSkuAsset: String;
  assetSummary: String;
  additionalProduct: AdditionalProduct[];
}

export class AdditionalProduct {
  productIdAS: String;
  productOptionSkuAS: String;
  aSSummery: String;
}

export class UnitAndServiceResponseModel {
  success: boolean;
  status: number;
  quoteId: string;
}

export class CreateQuoteReq {
  requestId: string;
  accountId: string | undefined;
  address: Address | {};
  orderType: string;
  zipcode: string;
  billTiming: string;
  billingPeriod: string;
  prodSubType: string;
  contactId: string | undefined;
  addressExist: boolean;
  addressId: string | null;
  customerType: string | undefined;
  businessType: string | undefined;
}

export class DocumentResData{
  documentId: string;
  documentName:string;
  quoteNumber:string;
  // account:{
  //   id:string;
  //   name:string;
  //   accountNumber:string;
  // };
  // primaryContact:{

  // }
  
}

export class CreateQuoteRes {
  success: boolean;
  status: number;
  message: string;
  data: CreateQuoteResData;
}
export class CreateQuoteResData {
  quoteId: string;
  quoteName: string;
  addressId: string;
}
export class updateQuoteSummaryReq {
  quoteId: string;
  startDate: string;
  endDate: string;
  estimatedEndDate: string;
  orderType: string;
  duration: string;
  billTiming: string;
  billingPeriod: string;
  customerType: string;
  businessType: string;
  contactId: string;
  accountId: string;
  prodSubType: string;
  addressId: string;
  zipcode: string;
  productDetails: BundlesForm[];
}

export class updateQuoteSummaryRes {
  quoteId: string;
}
