import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { UploadDialogInput } from "src/app/models/case-details-model";

@Component({
  selector: "app-case-success",
  templateUrl: "./case-success.component.html",
  styleUrls: ["./case-success.component.scss"],
})
export class CaseSuccessComponent {
  isMobile:boolean=false;
  constructor(private dialogref: MatDialogRef<CaseSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public record: UploadDialogInput,
    private platform:Platform,
    private _router: Router,
  ) {
    this.isMobile=this.platform.is('hybrid');
  }

  close(param:string) {
    this.dialogref.close(param);
  }
  

}
