import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  effect,
} from "@angular/core";
import { UserProfile } from "firebase/auth";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

@Directive({
  selector: "[appHideIfReadOnly]",
})
export class HideIfReadOnlyDirective<T> {
  user: UserProfile;
  constructor(
    private profileService: ProfileService,
    private templateRef: TemplateRef<T>,
    private viewContainer: ViewContainerRef
  ) {
    effect(() => {
      const account = this.profileService.selectedAccount();
      if (account?.accountId != this.user?.accountId) {
        this.user = account;
        this.hideFunction();
      }
    });
  }

  hideFunction() {
    const isTemplatePresent = this.viewContainer.length > 0;
    if (
      this.user.myussUserRole === "Read Only" ||
      this.profileService.isAccountInActive()
    ) {
      this.viewContainer.clear();
    } else {
      if(!isTemplatePresent){
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }
}
