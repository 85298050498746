import { Injectable } from "@angular/core";
import { ConfigService } from "../config/config.service";
import { catchError, from, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Platform } from "@ionic/angular";
import {
  Geolocation,
  Position as geoLocationPosition,
} from "@capacitor/geolocation";
import { ToastrService } from "ngx-toastr";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";


@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {
  isNative: boolean;

  constructor(private configService: ConfigService, private http: HttpClient,private platform:Platform,
    public toastr: ToastrService,

  ) {
    this.isNative = platform.is("hybrid");
  }

   getTimeZone(lat: number, lng: number) {

    let url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(
      Date.now() / 1000
    )}&key=${this.configService.getMapKey()}`;

    
   
    return from(fetch(url)).pipe(switchMap((response) => response.json()),catchError((err) => {
      console.error(`error getting in time zone: ${JSON.stringify(err)}`);
      throw err;
    }));
  }

  async getCurrentLocation(): Promise<geoLocationPosition | undefined> {
    //check this.isNative and return the position accordingly for native and web and ask for getNativePermission for native
    let position: geoLocationPosition | undefined;
    try {
      if (this.isNative) {
        const permission = await this.getNativePermission();
        if (!permission) {
          return;
        }
        position = await Geolocation.getCurrentPosition();
      } else {
        if (navigator.geolocation) {
          await new Promise<void>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (positions) => {
                // Handle successful retrieval of location
                position = positions;
                resolve();
              },
              (error) => {
                // Handle errors or denied permission
                console.error(error);
                this.toastr.error("Please allow location access to continue.");
                reject();
              }
            );
          });
        } else {
          console.error("Geolocation is not supported by this browser.");
          return;
        }
      }
    } catch (e: unknown) {
      if (
        e instanceof GeolocationPositionError &&
        e.message === "Location services are not enabled"
      ) {
        await this.openSettings();
      }
    }
    return position;
  }

  async getNativePermission(): Promise<boolean> {
    const permissonStatus = await Geolocation.checkPermissions();

    if (permissonStatus.location != "granted") {
      const requestStatus = await Geolocation.requestPermissions();
      if (requestStatus.location != "granted") {
        // this.toastr.error("Please allow location access to continue.");
        await this.openSettings(true);
        return false;
      }
    }
    return true;
  }

  openSettings(app = false) {
    return NativeSettings.open({
      optionAndroid: app
        ? AndroidSettings.ApplicationDetails
        : AndroidSettings.Location,
      optionIOS: app ? IOSSettings.App : IOSSettings.LocationServices,
    });
  }
}
