import { Injectable } from "@angular/core";
import { LoadingService } from "../../../../shared/services/loading/loading.service";
import { UssApiService } from "../../../myuss/services/uss-api/uss-api.service";
import { BehaviorSubject, Observable, catchError, firstValueFrom, map, of } from "rxjs";
import { id } from "date-fns/locale";
import { RFQ } from "src/app/models/rfq-model";

@Injectable({
  providedIn: "root",
})
export class RfqService {
  public rfq$ = new BehaviorSubject<RFQ | null>(null);
  public rfqId: string | null = null;
  public rfqDetailsLoaded = false;

  constructor(
    private api: UssApiService,
    private loadingService: LoadingService
  ) {
    this.rfqId = localStorage.getItem("rfqId");
    this.rfq$.subscribe((rfq) => {
      this.rfqDetailsLoaded = true;
    });
  }

  public async getRFQDetailsAsync(rfqId?: string, token?: string): Promise<any> {
    return firstValueFrom(this.getRFQDetails(rfqId, token));
  }

  getRFQDetails(rfqId?: string, token?: string): Observable<RFQ | null> {
    let id = rfqId || this.rfqId || localStorage.getItem("rfqId");
    let rfqToken = token || localStorage.getItem("rfqToken");
    if (!id) {
      return of(null);
    }
    console.log(id);
    return this.api.getRfq(id, rfqToken).pipe(
      map((resp) => {
        console.log(resp);
        if (resp.status == 1000) {
          const rfq = Object.assign(new RFQ(), resp["data"]);
          this.rfq$.next(rfq);
          return rfq;
        }
      }),
      catchError((err) => {
        console.error(`error getting rfq : ${JSON.stringify(err)}`);
        // remove the rfqId from local storage
        localStorage.removeItem("rfqId");
        localStorage.removeItem("rfqToken");
        throw err;
      })
    );
  }

  createUser(data) {
    this.loadingService.setLoader(true);
    console.log(id);
    return this.api.postRfq(`create_user`, data).pipe(
      map((res) => {
        console.log(res);
        this.loadingService.setLoader(false);
        return res;
      }),
      catchError((err) => {
        console.error(`error getting rfq : ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
      })
    );
  }
}
