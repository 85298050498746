export const environment = {
  production: false,
  API_URL: "https://myuss-api-4vbamnatdq-uc.a.run.app/",
  //API_URL: "https://myuss-api-int-4vbamnatdq-uc.a.run.app/",
  // API_URL: "http://localhost:8090/",
  DOMAIN: "dev-lc5wqi0e8ig384su.us.auth0.com",
  CLIENT_ID: "wu5WttPZ13JAWCrsMBfwzsKM0vGXzWRn",
  AUDIENCE: "https://myuss-api-4vbamnatdq-uc.a.run.app/",
  GOOGLE_MAP_API_KEY: "AIzaSyBGrlr5WM3-W9XhRENythtloV6_2kq7_Tc",
  firebaseConfig : {
    apiKey: "AIzaSyBGrlr5WM3-W9XhRENythtloV6_2kq7_Tc",
    authDomain: "uss-portal-dev.firebaseapp.com",
    projectId: "uss-portal-dev",
    storageBucket: "uss-portal-dev.appspot.com",
    messagingSenderId: "209644420187",
    appId: "1:209644420187:web:a6862137180e51508e537e",
    measurementId: "G-R3XTHHYLZW"
  }
};
