import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { phoneNumber } from "src/assets/config/constants";
import { helpMyUssEmail, customerOnboardingEmail } from "src/assets/config/constants";

@Component({
  selector: 'app-signup-popup',
  templateUrl: './signup-popup.component.html',
  styleUrls: ['./signup-popup.component.scss']
})
export class SignupPopupComponent {
  constructor(private dialogref: MatDialogRef<SignupPopupComponent>,    
    @Inject(MAT_DIALOG_DATA) public roleMap:boolean
  ){}

  phoneNumber: string;
  isSelected: boolean = false;
  showCopyIcon: boolean = false;
  helpMyUssEmail: string;
  customerOnboardingEmail: string;
  isRoleBased:boolean = false;
  
  ngOnInit() {
    this.phoneNumber = phoneNumber; 
    this.helpMyUssEmail = helpMyUssEmail;
    this.customerOnboardingEmail = customerOnboardingEmail;
    this.isRoleBased = this.roleMap["isRoleBasedEnabled"];
  }

  cardClick(){
    this.dialogref.close();
  }

  openGmailCompose() {
    this.isSelected = true;
    const subject = encodeURIComponent("MySiteServices User Setup Request");
    const email = this.customerOnboardingEmail;
    window.open(`mailto:${email}?subject=${subject}`);
  }

}
