<div class="custom-card">
    <div class="bg-primary" style="margin-bottom: 1rem" fxLayout="row" fxLayoutAlign="start center">
        <h3 style="padding: 0.5rem 0.8rem">Reset password</h3>
    </div>

    <div class="p-2">
        <div style="margin-bottom: 0.8rem" class="mr-2 ml-2">
            <p>
                Are you sure you want to reset password?
                <!-- <span style="font-weight: bold">{{ type }}</span> -->
            </p>
        </div>

        <mat-divider class="my-2 mt-3"></mat-divider>

        <div class="btnDiv">
            <button class="btnsize" mat-button mat-dialog-close>No</button>
            <button class="btnsize" id="resetYesBtnTestId" mat-raised-button color="primary" (click)="onYes()">Yes</button>
        </div>
    </div>
</div>