<section class="search-wrapper p-2">
  <mat-icon class="close-icon" (click)="close()">close</mat-icon>
  <!-- <div class="search-header">
    <p class="p-2">Enter Unit Number</p>
  </div> -->
  <div class="search-body">
    <div class="search-illustration">
      <img
        src="../../../../../assets/img/search_unit_illustration.png"
        alt=""
      />
    </div>
    <div class="search-form p-2">
      <p class="search-form-header pt-2 w-100">Find a Unit</p>
      <p class="search-form-description pb-2 w-100">
        Enter the Unit Number found on your unit’s Asset Tag <span><mat-icon color="primary" #tooltip="mtxTooltip"  [mtxTooltip]="tooltipTpl"
          mtxTooltipPosition="right" (click)="tooltip.toggle()">info</mat-icon></span>
      </p>
      <ng-template #tooltipTpl>
        <app-tool-tip-template text="Look for an Asset Tag like this on your unit, likely inside above the door. The Unit Number is below the QR Code." imageUrl="../../../../assets/./img/icons/scan-help-text.svg"  ></app-tool-tip-template>
      </ng-template>
      <div class="w-100 field-wrapper">

        <mat-form-field class="search-input  py-1" appearance="outline">
          <input matInput placeholder="Enter Unit Number" #input minlength="8" maxlength="10" (input)="onInput(input.value)" [formControl]="unitNumber"/>
          <mat-error *ngIf="unitNumber.errors?.required">Please enter unit number.</mat-error>
          <mat-error *ngIf="unitNumber.errors?.minlength">Enter 8-10 digit unit number.</mat-error>
        </mat-form-field>
        <!-- <div class="search-btn-wrapper"> -->
          <button mat-raised-button color="primary" class="search-button" (click)="search()">
            Search
          </button>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <mat-divider *ngIf="errorFlag"></mat-divider>

  <div class="p-2" *ngIf="errorFlag">
    <p>
      The unit number entered was not found on any active Orders for this account. Please check the unit number and try again or search for your order using the Orders module.  If you are still unable to find your order, please call us at <span>
        <a href="tel:{{ phoneNumber }}">
          {{ phoneNumber }}
        </a>
      </span>.
   
    </p>
   
  </div>

</section>

