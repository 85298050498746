<div class="custom-card">
    <!-- <div class="bg-primary" style="margin-bottom: 1rem" fxLayout="row" fxLayoutAlign="start center">
            <h3 style="padding: 0.5rem 0.8rem">Confirmation</h3>
        </div> -->
  
    <div class="p-2">
      <div class="p-2 text"  *ngIf="!ReduceData?.message">
        Total quantity for the order cannot exceed 10. Please contact {{phoneNumber}}
      </div>
      <div class="p-2 text" *ngIf="ReduceData?.message">
      
        <span>{{ReduceData?.message}}</span>
          
      </div>
      <!-- <mat-divider class="my-2 mt-3"></mat-divider> -->
  
      <div class="btnDiv w-100 py-2" fxLayoutAlign="center center">
        <button
          class="btnsize"
          mat-raised-button
          color="primary"
          (click)="onClose()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
  