<!-- <mat-divider class="pb-3"></mat-divider> -->
<ng-scrollbar id="vertical-menu" pointerEventsMethod="scrollbar">
  <app-vertical-menu [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu>

</ng-scrollbar>



<div class="button_div">
  <div class="bottom_div">
    <!-- search button -->
    <div *ngIf="showSearch()"  fxLayout="column" fxLayoutAlign="center center" class="menu-item">
      <button *ngIf="settings.menuType != 'default'" mat-button class="menu-button" fxLayout="row"
        [fxLayoutAlign]="settings.menuType == 'default' ? 'start center' : 'center center'" (click)="search()">
        <img class="find-icon" [mtxTooltip]="tooltipTpl"
        mtxTooltipPosition="right" src="../../../../assets/img/icons/find-unit.png" alt="Search icon">
        <span class="menu-title pl-2">Find a Unit</span>
      </button>

      <div *ngIf="settings.menuType == 'default'" class="search-unit-menu-wrapper p-2 mb-1">
        <img class="search-unit-menu-icon" src="../../../../assets/img/icons/find-unit.png" alt="Search icon">
        <p class="search-unit-menu-header pb-1">
          Find a Unit 
        </p>
        <p class="search-unit-menu-description pb-1">
          Search for a unit by the Unit
          Number on the asset tag <span><mat-icon #tooltip="mtxTooltip" color="primary" [mtxTooltip]="tooltipTpl"
            mtxTooltipPosition="right" (click)="tooltip.toggle()">info</mat-icon></span>
        </p>
        <button mat-raised-button color="primary" class="find-unit-btn" (click)="search()">Search</button>

      </div>
    </div>
    <ng-template #tooltipTpl>
      <app-tool-tip-template text="Look for an Asset Tag like this on your unit, likely inside above the door. The Unit Number is below the QR Code." imageUrl="../../../../assets/./img/icons/scan-help-text.svg"  ></app-tool-tip-template>
    </ng-template>
    <!-- collapse button -->
    <div *ngIf="!isMobile">
      <mat-divider></mat-divider>
      <span id="toggleButtonLabel" class="sr-only">{{ getAriaLabel() }}</span>
      <button class="toggle_button" mat-icon-button (click)="collapse()" aria-hidden="false"
        aria-label="Expands the menu to show more options.">
        <img *ngIf="icon" class="reverse" src="../../assets/img/Collapse.svg"
          alt="Expands the menu to show more options." />
        <img *ngIf="!icon" src="../../assets/img/Collapse.svg" alt="Collapses the menu to hide options." />

      </button>
    </div>

  </div>
</div>