import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";

@Component({
  standalone: true,
  selector: "app-login-callback",
  templateUrl: "./login-callback.component.html",
  styleUrls: ["./login-callback.component.scss"],
})
export class LoginCallbackComponent {
  public settings: Settings;

  constructor(
    private appSettings: AppSettings,
    private profileService: ProfileService,
    private auth:AuthenticationService,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.ionViewDidEnter();

    this.auth.authenticationChange$.subscribe(async (auth) => {
      if(auth){
        await this.profileService.getInitialApplicationState(false)
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = true;
    }, 0);
  }

  async ionViewDidEnter() {
    // debugger
    try {
      // Transitioning to logout or login
      if (window.location.hash === '#logout') {
        console.log('Logging out');
        this.auth.login();

      } else {
        await this.auth.handleLogin();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
