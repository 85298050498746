import { inject } from "@angular/core";
import { ActivatedRoute, CanActivateFn, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { ConfigService } from "../shared/services/config/config.service";
import { ProfileService } from "../features/accounts/services/profile/profile-services.service";

export const isRegisteredGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const profileService = inject(ProfileService);
const userIsRegistered=await profileService.userIsRegistered();
console.log("Checking if user is registered", userIsRegistered);
  if (userIsRegistered) {
    return (true);
  } else {
    if (state.url !== "/account/create") {
      return router.navigate(["account/create"]);
    } else {
      return (true);
    }
  }
};
