<!-- site-address-exist-popup.component.html -->

<div class="custom-card">
    <div class="center-content">
        <img src="../../../../../assets/img/icons/warn_icon.svg" alt="" class="alert_triangle">
        <h3 class="title">{{title}}</h3>
        <p class="description">{{message}}</p>
        <div class="button-row">
            <button mat-stroked-button (click)="create()" mat-dialog-close>Create New</button>
            <button mat-raised-button color="primary" (click)="choose()" mat-dialog-close>Choose</button>
        </div>
    </div>
</div>
