<section class="wrapper" id="container-wrapper">
  <div  class="user-profile">
    <app-user-menu></app-user-menu>
  </div>
    <section class="container">
      <img [src]="imgUrl" alt="" />
      <h2 >{{ title }}</h2>
      <p  class="subtitle">
        {{ subtitle }}
       <br>
        <span *ngIf="!isEasyPay">
          <span>
           {{contactString}}
          </span>
          <a href="tel:{{ phoneNumber }}" class="phone-number">
            {{ phoneNumber }}
          </a>
        </span>
      </p>
      <button *ngIf="!isEasyPay && !hideLogout" mat-raised-button color="primary" class="mt-2" (click)="logout()">
        Logout
      </button>
      <div  *ngIf="isEasyPay" >
        <button class="mx-2" (click)="onBack()" mat-raised-button>Back</button>
        <button  mat-raised-button (click)="onContinue()" color="primary">Continue</button>
      </div>
    </section>
  </section>
  