
<mat-divider></mat-divider>
<div class="tab_div mt-2">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Grid View"> </mat-tab>
 
  </mat-tab-group>

  <div></div>

  <button
    mat-raised-button
    color="primary"
    class="add_Location"

    (click)="openAddLocationPopup();"
  >
    Add Location
  </button>
</div>

<div *ngIf="!isMobileView" class="mt-2">
  <div class="note mt-3">
    <mat-icon color="primary" class="error_icon mr-1 cursor-pointer" (click) = "driverJs();" >error</mat-icon>
    <p class="note_text cursor-pointer" (click) = "driverJs();">
      Click here to get step by step guide to create locations.
    </p>
  </div>
</div>

<div class="table_div" tabindex="0">
  <table
    mat-table
    [dataSource]="dataSource"
    id="gridtable"
    class="mat-elevation-z8 mt-3 grid_view_table"
  >

    <!-- bundle Column -->
    <ng-container matColumnDef="bundle" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <div class="p-2 sub_header">
          <span>Quote Summary</span>
        </div>
      </th>
      <td mat-cell *matCellDef="let element,index as i">
        <div class="bundle">

        <div class="py-1 bundle-details">

        <p class="product-name">{{ element.product.name }}</p>
        <p class="product-details">{{ element.assetList[0].product.description }}</p>
        <p class="product-details">{{ element.services[0].product.description }}</p>
        <div class="additional-services">
          <p class="product-details">Additional Services</p>
          <button
            class="my-1 cursor-pointer"
            *ngIf="element.additionalServices.length"
            id="additional-services-count"
            [matMenuTriggerFor]="belowMenu"
          >
            {{ element.additionalServices?.length }}
          </button>
        </div>
        <mat-menu #belowMenu="matMenu" yPosition="below">
          <p
            mat-menu-item
            *ngFor="
              let additionalProduct of getAdditionalServices(
                element.additionalServices
              )
            "
          >
            {{ additionalProduct }}
          </p>
        </mat-menu>
      </div>

      <div class="quantity_div">
        <div class="total_Quantity units">
          <mat-icon class="fiber_icon total_Quantity_icon"
            >fiber_manual_record</mat-icon
          >
          <span class="pr-1">{{ element.quantity }} Total Quantity</span>
        </div>
        <div class="units mt-2" 
        [ngClass]="{
          'available': getAvailableQuantityByIndex(i) > 0,
          'zero-units': getAvailableQuantityByIndex(i) === 0
        }" >
          <mat-icon class="fiber_icon"
          [ngClass]="{
            'available_icon': getAvailableQuantityByIndex(i) > 0,
            'zero-units-icon': getAvailableQuantityByIndex(i) === 0
          }"
            >fiber_manual_record</mat-icon
          >
          <span class="pr-1" >{{ getAvailableQuantityByIndex(i) }} Left to assign</span>
        </div>
      </div>
    </div>

      </td>
    </ng-container>

   

    <ng-container
      *ngFor="let column of additionalColumns; index as i"
      [matColumnDef]="column"
      class="added_column"
    >
      <th class="header-width" mat-header-cell *matHeaderCellDef>
        <div class="p-2">
          <div class="column_header">
            <span class="site_name">{{ getLocations(column)?.address?.siteName }}</span>
            <mat-icon class="more-option" (click)="onView(column)">more_vert</mat-icon>
          </div>
          <div class="mt-2">
            <span>{{ getLocations(column)?.address?.instructions }}</span>
          </div>
          <div></div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element; index as i">
        <div class="tb_input">
          <div class="counter">
            <mat-icon class="counter-icon" (click)="reduceQuantity(i, column)">remove</mat-icon>
          <input
            type="text"
            class="quantity-input"
            min="0"
            [max]="getAvailableQuantityByIndex(i)"
            [id]="column + i"
            (keydown)="onKeydown($event)"
            (focus)="onFocus(i, column)"
            (focusout)="onChange($event, i, column)"
            [value]="element[column]['quantityQuoted']"
            [disabled] = "true" 
          />
          <mat-icon class="counter-icon" #matTool="matTooltip"  matTooltip="All units are assigned" [matTooltipDisabled]="getAvailableQuantityByIndex(i) !== 0" (click)="addQuantity(i, column)" (click)="matTool.toggle()">add</mat-icon>
          </div>       
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="data"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

</div>
<div class="my-2 continue-btn" [appFixedButton]="'70px'" >
  <button fxFlex.xs="0 1 100" mat-raised-button (click)="onContinue()" color="primary">
    Continue
  </button>
</div>
