import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Address } from "./address-model";
import { Contact } from "./site-details-model";

export class ProjectModel {
  id: string;
  name: string | null;
  projectNumber: string | null;
  description: string | null;
  category: string | null;
  projectType: string | null;
  stage: string | null;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  address: Address | null;
  createdDate: string | null;
  lastModifiedDate: string | null;
  accountId: string | null;
  noOfQuotes: number | null;
  noOfOrders: number | null;
  action?: string;
  contact?: Contact;
  isContactExists?: boolean;
  otherTypeOfProject?: string;
  casesCount?:number
  archievedConfirm?:number
}

export class ProjectModelFormGroup {
  id: FormControl<string |undefined >;
  name: FormControl<string>;
  projectNumber: FormControl<string>;
  description: FormControl<string>;
  category: FormControl<string>;
  projectType: FormControl<string | undefined>;
  stage: FormControl<string>;
  status: FormControl<string>;
  startDate: FormControl<string>;
  endDate: FormControl<string>;
  address: FormGroup<AddressFormModel>;
  createdDate: FormControl<string>;
  lastModifiedDate: FormControl<string>;
  accountId: FormControl<string>;
  noOfQuotes: FormControl<number>;
  noOfOrders: FormControl<number>;
  contact: FormGroup<ContactFormModel>;
  isContactExists: FormControl<boolean>;
  otherTypeOfProject: FormControl<string>;
  constructor(project?: ProjectModel) {
    this.id = new FormControl<string>("", { nonNullable: true });
    this.name = new FormControl<string>("", {
      nonNullable: true,
      validators: [Validators.required],
    });
    this.projectNumber = new FormControl<string>("", { nonNullable: true });
    this.description = new FormControl<string>("", { nonNullable: true });
    this.category = new FormControl<string>("", { nonNullable: true });
    this.projectType = new FormControl<string>("", { nonNullable: true ,validators:[Validators.required]});
    this.stage = new FormControl<string>("", { nonNullable: true });
    this.status = new FormControl<string>("", { nonNullable: true });
    this.startDate = new FormControl<string>("", {
      nonNullable: true,
      validators: [Validators.required],
    });
    this.endDate = new FormControl<string>("", {
      nonNullable: true,
    });
    this.address = new FormGroup<AddressFormModel>(new AddressFormModel());
    this.createdDate = new FormControl<string>("", { nonNullable: true });
    this.lastModifiedDate = new FormControl<string>("", { nonNullable: true });
    this.accountId = new FormControl<string>("", { nonNullable: true });
    this.noOfQuotes = new FormControl<number>(0, { nonNullable: true });
    this.noOfOrders = new FormControl<number>(0, { nonNullable: true });
    this.contact = new FormGroup<ContactFormModel>(new ContactFormModel());
    this.isContactExists = new FormControl<boolean>(false, {
      nonNullable: true,
    });
    this.otherTypeOfProject = new FormControl<string>("", {
      nonNullable: true,
    });
  }
}

export class ContactFormModel {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  contactId: FormControl<string>;
  accountId: FormControl<string>;
  title?: FormControl<string>;
  fullName?: FormControl<string>;
  constructor(contact?: ContactFormModel) {
    this.fullName = new FormControl<string>("", { nonNullable: true }); 
    this.firstName = new FormControl<string>("", { nonNullable: true,validators:[Validators.required] });
    this.lastName = new FormControl<string>("", { nonNullable: true,validators:[Validators.required] });
    this.email = new FormControl<string>("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),
      ],
    });
    this.phone = new FormControl<string>("", {
      nonNullable: true,
      validators: [Validators.required,Validators.minLength(10)],
    });
    this.contactId = new FormControl<string>("", { nonNullable: true });
    this.accountId = new FormControl<string>("", { nonNullable: true });
    this.title = new FormControl<string>("", { nonNullable: true });
  }
}

export class AddressFormModel {
  street: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zipcode: FormControl<string>;
  country: FormControl<string>;
  latitude: FormControl<number | undefined>;
  longitude: FormControl<number | undefined>;
  addressId: FormControl<string | undefined>;
  accountId: FormControl<string>;
  constructor(address?: AddressFormModel) {
    this.street = new FormControl<string>("", { nonNullable: true ,validators:[Validators.required]});
    this.city = new FormControl<string>("", { nonNullable: true ,validators:[Validators.required]});
    this.state = new FormControl<string>("", { nonNullable: true ,validators:[Validators.required] });
    this.zipcode = new FormControl<string>("", { nonNullable: true ,validators:[Validators.required]});
    this.country = new FormControl<string>("", { nonNullable: true });
    this.latitude = new FormControl<number>(0, { nonNullable: true });
    this.longitude = new FormControl<number>(0, { nonNullable: true });
    this.addressId = new FormControl<string>("", { nonNullable: true });
    this.accountId = new FormControl<string>("", { nonNullable: true });
  }
}
