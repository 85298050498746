import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tracking-popup',
  templateUrl: './tracking-popup.component.html',
  styleUrls: ['./tracking-popup.component.scss']
})
export class TrackingPopupComponent {
  constructor(private dialog:MatDialogRef<TrackingPopupComponent>){}

  dismiss() {
    this.dialog.close(true);
  }

}
