<div class="tile-wrapper">
    <mat-card class=" tile p-3">
        <h2 class="title">{{title}}</h2>
        <div class="divider_css w-100" *ngIf="divider"></div>
        <h2 *ngIf="countFlag" class="count">{{count}}</h2>
        <p *ngIf="description" class="description">{{description}}</p>
        <ul *ngIf="isbulletPoints" class="p-3">
            <li *ngFor="let item of bulletPoints" >{{item}}</li>
        </ul>
        <img *ngIf="icon" src="../../../../assets/img/icons/{{icon}}" alt="">
    </mat-card>
</div>