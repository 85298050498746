<div class="overlay"></div>
<div class="container">
  <div class="image-container">
    <div class="image-text pt-5">United Site Services</div>
  </div>  


  
  <div class="content">
    <div class="welcome-text">
      <h1>Welcome to United Site Services</h1>
      <p>Sign up to manage your project, create a quote, or pay your invoice.</p>
    </div>


    <div class="button-group">
      <button mat-raised-button class="login-button" (click)="login()">
        Login to United Site Services
      </button>

      <form [formGroup]="form">
        <div class="custom-zip-field">
          <input type="text" placeholder="Search Zip Code" formControlName="postalCode" [maxLength]="5" #input
            (keyup)="onkey(input.value)">

            <div class="divider"></div>
            
          <img src="/assets/img/icons/landing-search.svg" class="pr-3" alt="Search icon"
            (click)="onSubmit(form.value)" />
        </div>
      </form>
      <div *ngIf="error || error2">
        <div class="square"></div>
        <div class="span" fxLayoutAlign="start center">
          <mat-icon>error_outline</mat-icon>
          <span *ngIf="error">Please enter a valid 5-digit ZIP code.</span>
        </div>
      </div>

      <div class="terms-text">
        <p>
          By logging in or registering, you agree with our
          <a href="https://www.unitedsiteservices.com/legal-terms-conditions/">Terms</a> and <a
            href="https://www.unitedsiteservices.com/privacy-policy/">Policy</a>
        </p>
      </div>
    </div>
  </div>
</div>