import { Address } from "./address-model";
import { BillingDetails } from "./order-model";
import { PurchaseOrder } from "./purchase-order.model";
import { Contact } from "./quoteState-model";

export class Account {
  id: string;
  name: string;
  accountNumber: string;
  customerType: string;
  businessType: string;
  primaryPayerEmail: string;
  primaryPayerId: string;
  billingAddressId: string;
  shippingAddressId: string;
  primaryContactId: string;
  outstandingBalance: number;
  contacts: Contact[];
  addresses: Address[];
  purchaseOrders: PurchaseOrder[];
  // TODO: Add quotes model
  // quotes: Quote[];
  defaultBillingDetails: BillingDetails;
  requirements: {
    purchaseOrder: boolean;
    lienRelease: boolean;
    autoPay: boolean;
  }
}