import { Component, effect, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { take } from "rxjs";
import { EnhanceQuoteService } from "src/app/features/enhance-quotes/services/enhance-quote.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { QuoteModelDetailsV2 } from "src/app/models/enhance-quote-model";
import { QuoteState } from "src/app/models/quoteState-model";
interface DialogData {
  isEnhanceQuote: boolean;
  showBackButton: boolean;
  showChangeDateButton: boolean;
}
@Component({
  selector: "app-surpassed-date-popup",
  templateUrl: "./surpassed-date-popup.component.html",
  styleUrls: ["./surpassed-date-popup.component.scss"],
})
export class SurpassedDatePopupComponent {
  currentState: QuoteState;
  status: number;
  enhanceQuote: QuoteModelDetailsV2;

  constructor(
    public router: Router,
    public quoteStateService: QuoteStateService,
    public enhanceQuoteService: EnhanceQuoteService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  createQuote() {
    // Navigate to the '/quotes' route.
    if (this.data.isEnhanceQuote) {
      this.router.navigate(["/quotes/v2"]);
    } else {
      this.router.navigate(["/quotes"]);
    }
   
  }

  back() {
    // Navigate back to the '/home' route.
    this.router.navigate(["/home"]);
  }

  backToStep1() {
    // Update the current step to 1 in the quote state.
    if (this.data.isEnhanceQuote) {
      const quote = Object.assign({}, this.enhanceQuoteService.quoteState(), {
        currentStatus: 0,
      });
      this.enhanceQuoteService.quoteState.set(quote);
    } else {
      this.quoteStateService.updateCommonValues({
        ...this.quoteStateService.getCurrentValue(),
        currentStep: 1,
      });
    }
  }
}
