<!-- Purpose: This file is used to display the add comment dialog box. -->
<div class="add-comment-dialog">
    <h3>Add Comment</h3>
    <mat-divider></mat-divider>
    <form [formGroup]="commentForm">
    <p>Type of comment</p>
    <mat-form-field appearance="outline" class="comment-dropdown" >
        <mat-label>Add comment type</mat-label>
        <mat-select formControlName="commentType" id="addProductId">
          <mat-option *ngFor="let option of commentOptions" [value]="option.value">
            {{ option.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="commentForm.get('commentType')?.hasError('required')">
            Comment type is required
          </mat-error>
      </mat-form-field>
      
        <mat-form-field  class="comment-textarea  textarea" appearance="outline">
          <textarea
            matInput
            placeholder="Add a comment here.."
            formControlName="comment"
            maxlength="4000"
            class="no-resize"
            rows="3"
            
          ></textarea>
          <mat-hint align="end" class="char-count-hint">{{ charCount }}/4000</mat-hint>
          <mat-error *ngIf="commentForm.get('comment')?.hasError('required')">
            Comment is required
          </mat-error>
          <mat-error *ngIf="commentForm.get('comment')?.hasError('maxlength')">
            Comment cannot be more than 4000 characters
          </mat-error>
        </mat-form-field>
    <mat-divider></mat-divider>
    <div class="button-row">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="addComment()">Add</button>
    </div>
</form>
</div>
