<section class="p-3">
  <!-- <p>test</p> -->
  <app-order-summary-header
    [orderName]="assetDetails?.orderNumber"
    [startDate]="assetDetails?.startDate"
    [endDate]="assetDetails?.endDate"
    [siteAddress]="assetDetails?.parentAddress"
    [status]="assetDetails?.orderStatus"
    [showEdit]=isEditOrderEnabled
    [btnConfig]="btnconfig"
  >
  </app-order-summary-header>

  <div class="asset-details-wrapper">
    <div class="asset-details-header p-2">
      <div class="asset-details-icon pl-2">
        <img src="../../../../../assets/img/icons/Location_pin.svg" alt="" />
      </div>
      <div class="asset-details-title">
        <p class="asset-site-location">
          {{ assetDetails?.parentAddress }}
        </p>
        <p class="asset-placement-notes">{{ assetDetails?.placementNote }}</p>
      </div>
    </div>
    <div class="asset-details p-2">
      <p class="p-2 unit-number">
        Unit Number : {{ assetDetails?.unitNumber }}
      </p>
      <div class="asset-details-table">
        <table class="px-2">
        
          <tr>
            <td class="ml-1">Asset</td>
            <td class="ml-1">{{ assetDetails?.assetName }}</td>
          </tr>
          <tr>
            <td class="ml-1">Frequency</td>
            <td class="ml-1">{{ assetDetails?.serviceName }}</td>
          </tr>
          <tr>
            <td class="ml-1">Add ons</td>
            <td class="ml-1"><p *ngFor="let additionalService of assetDetails?.ancillaryServiceList">
              {{additionalService?.ancillaryServiceName || "-"}},
            </p></td>
          </tr>
        </table>
        <table class="px-2">
          <tr>
            <td class="ml-1">Last Service Date</td>
            <td class="ml-1">
              {{
                lastServiceDetails?.startDate
                  ? (lastServiceDetails?.startDate | date : "MM/dd/yyyy")
                  : "-"
              }}
            </td>
          </tr>
          <tr>
            <td class="ml-1">Service Type</td>
            <td class="ml-1">{{ lastServiceDetails?.type || "-" }}</td>
          </tr>
          <tr>
            <td class="ml-1">Next Service Date</td>
            <td class="ml-1">
              {{ nextServiceDetails?.startDate | date : "MM/dd/yyyy" || "-" }}
            </td>
          </tr>
          <tr>
            <td class="ml-1">Service Type</td>
            <td class="ml-1">{{ nextServiceDetails?.type }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="asset-service-details mt-2">
      <mat-expansion-panel class="map_panal" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="headers"
            >View Service Details</mat-panel-title
          >
        </mat-expansion-panel-header>

        <div class="site-Align-table">
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            (selectedTabChange)="onTabChanged($event.index)"
          >
            <mat-tab label="Upcoming"></mat-tab>
            <mat-tab label="Completed"></mat-tab>
          </mat-tab-group>
          <div class="asset-service-details-wrapper py-2">
            <ng-container *ngFor="let service of serviceDetails">
              <table class="p-2">
                <tr>
                  <td class="ml-1">Scheduled Date</td>
                  <td class="ml-1">
                    {{ service?.startDate | date : "MM/dd/yyyy" || "-" }}
                  </td>
                </tr>
                <tr>
                  <td class="ml-1">Type</td>
                  <td class="ml-1">{{ service?.type }}</td>
                </tr>
                <tr>
                  <td class="ml-1">Status</td>
                  <td class="ml-1">{{ service?.status }}</td>
                </tr>
              </table>
            </ng-container>
            <p *ngIf="serviceDetails?.length == 0" class="text-center p-2">{{noDataAvlText}}</p>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</section>
