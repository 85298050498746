import { Injectable } from "@angular/core";
import {
  BiometricSecurityStrength,
  BrowserVault,
  Device,
  DeviceSecurityType,
  IdentityVaultConfig,
  Vault,
  VaultError,
  VaultErrorCodes,
  VaultType,
} from "@ionic-enterprise/identity-vault";
import { Capacitor } from "@capacitor/core";
import { AuthResult } from "@ionic-enterprise/auth";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class VaultService {
  config: IdentityVaultConfig = {
    key: "com.myuss.mysiteservices.auth",
    type: VaultType.SecureStorage,
    deviceSecurityType: DeviceSecurityType.None,
    // lockAfterBackgrounded: 2000,
    // shouldClearVaultAfterTooManyFailedAttempts: false,
    // customPasscodeInvalidUnlockAttempts: 10,
    unlockVaultOnLoad: true,
  };
  vault: Vault | BrowserVault;
  vaultKey = "auth";

  constructor( private platform: Platform) {
    this.vault = platform.is('hybrid') ? new Vault(this.config) : new BrowserVault(this.config);
  }

  /**
   * Init is called by our APP_INITIALIZER at the startup of the application
   */
  public async init() {
    this.vault = this.platform.is('hybrid') ? new Vault(this.config) : new BrowserVault(this.config);
   
  }

  //   public async get(): Promise<AuthResult | undefined> {
  //     const value = await this.vault.getValue(this.vaultKey);
  //     if (value == null) return undefined;
  //     return JSON.parse(value);
  // }

  public clear(): Promise<void> {
    return this.vault.clear();
  }

  public async getSession(): Promise<AuthResult | null> {
    const value = await this.vault.getValue(this.vaultKey);
    if (value == null) return null;
    return value;
  }

  public setSession(value: AuthResult): Promise<void> {
    return this.vault.setValue(this.vaultKey, value);
  }
   set<T>(key: string, value: T): Promise<void> {
    return  this.vault.setValue(key, value);
  }
  async get(key: string){
    return  await this.vault.getValue(key);
    
  }

  private async hasBiometrics(): Promise<boolean> {
    // For this app we only want to use biometrics if the device is capable of strong encryption
    return (
      (await Device.isBiometricsEnabled()) &&
      (await Device.getBiometricStrengthLevel()) ===
        BiometricSecurityStrength.Strong
    );
  }
}
