<div class="custom-card">
  <!-- <div class="bg-primary" style="margin-bottom: 1rem" fxLayout="row" fxLayoutAlign="start center">
        <h3 style="padding: 0.5rem 0.8rem">Confirmation</h3>
    </div> -->

  <div class="p-2">
    <div class="p-2 f-modal-alert" fxLayout="column" fxLayoutAlign="center center">
      <div *ngIf="success" class="f-modal-icon f-modal-success animate">
        <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
        <span class="f-modal-line f-modal-long animateSuccessLong"></span>
        <div class="f-modal-placeholder"></div>
        <div class="f-modal-fix"></div>
      </div>

      <div *ngIf="!success" class="f-modal-icon f-modal-error animate">
        <span class="f-modal-x-mark">
          <span class="f-modal-line f-modal-left animateXLeft"></span>
          <span class="f-modal-line f-modal-right animateXRight"></span>
        </span>
        <div class="f-modal-placeholder"></div>
        <div class="f-modal-fix"></div>
      </div>
    


      <p *ngIf="success && !isInactiveProject" class="confirmText pt-2">Order Confirmed</p>
      <p *ngIf="!success" class="confirmText pt-2">Something Went Wrong</p> 
      <p *ngIf="success && isInactiveProject" class="confirmText pt-2">Project is Activated. Order Confirmed</p>      
    </div>

    <!-- <mat-divider class="my-2 mt-3"></mat-divider> -->

    <div class="btnDiv w-100 py-2" fxLayoutAlign="center center">
      <button
        id="confirmYesBtnTestId"
        class="btnsize"
        mat-raised-button
        color="primary"
        (click)="onClose()"
      >
        Ok
      </button>
    </div>
  </div>
</div>
