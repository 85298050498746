import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-linkcomponent',
  templateUrl: './linkcomponent.component.html',
  styleUrls: ['./linkcomponent.component.scss']
})
export class LinkcomponentComponent {
  @Input() title: string = "";
  @Input() icon: string = "";
}
