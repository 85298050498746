import { Component, effect } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, distinctUntilChanged, filter } from 'rxjs';
import { EnhanceQuoteService } from 'src/app/features/enhance-quotes/services/enhance-quote.service';
import { QuoteStateService } from 'src/app/features/quotes/services/quote-state.service';
import { QuoteModelDetailsV2 } from 'src/app/models/enhance-quote-model';
import { JobsiteModel } from 'src/app/models/jobsite-model';
import { QuoteState } from 'src/app/models/quoteState-model';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss']
})
export class SelectLocationComponent {

  quoteStateSubscription: Subscription = new Subscription();
  currentValue: QuoteState;
  jobSites: JobsiteModel[] = [];
  selectedSite: JobsiteModel;
  quoteState: QuoteModelDetailsV2;
  enhanceQuoteUiFlag : boolean




  constructor(    public quoteStateService: QuoteStateService,
    private enhanceQuoteService : EnhanceQuoteService,
    private dialogref: MatDialogRef<SelectLocationComponent>

  ) { 
    effect(()=>{
      this.enhanceQuoteUiFlag = this.enhanceQuoteService.enhanceQuoteUiSignal()

    })
    
    effect(()=>{
      this.quoteState = this.enhanceQuoteService.quoteState() 
      if( this.enhanceQuoteUiFlag){
        this.filterJobsite(this.quoteState.jobSites)

      }
    })

   
  }


ngOnInit(): void {

  if(!this.enhanceQuoteUiFlag){
    this.quoteStateSubscription = this.quoteStateService.currentQuote$
    .pipe(
      filter((el) => el.currentStep === 3),
      distinctUntilChanged(
        (prev, curr) => prev.currentStep === curr.currentStep
      )
    )
    .subscribe((res) => {
      this.currentValue = res;
      this.filterJobsite(this.currentValue.quoteModel.jobSites)
      
    })
  }
 
}


// function to find unique site
filterJobsite(jobSite : JobsiteModel[]) {
  this.jobSites = Array.from(
    jobSite.reduce((uniqueMap, obj) => {
      uniqueMap.set(obj.address.id, obj);
      return uniqueMap;
    }, new Map()).values()
  ); 
  console.log(this.jobSites);
   this.selectedSite = this.jobSites[0];
}


onSave(){
  const siteAddress =[{
    ...this.selectedSite
  }]
this.dialogref.close(siteAddress);
}

onCancel(){
  this.dialogref.close("false");
}
}
