import { Routes } from "@angular/router";
import { ComingSoonComponent } from "./shared/components/coming-soon/coming-soon.component";

export const appRoutes: Routes = [
  {
    path: "comingsoon",
    component: ComingSoonComponent,
    data: { breadcrumb: "Coming Soon" },
  },
  { path: "**", redirectTo: "home", pathMatch: "full" },
];
