<div class="custom-card">
    <div class="bg-primary" fxLayout="row" fxLayoutAlign="start center">
        <img src="../../../../../assets/img/icons/alert-triangle-white.png" alt="" class="alert_triangle pl-3 ">
        <h3 class="p-2" >Unable to continue</h3>
    </div>

    <div class="p-2">
        <div class="mr-2 ml-2">
            <p class="text_Div">
                Start date for the quote has been surpassed.
                You cannot proceed further to place order.
            </p>
        </div>

        <mat-divider class="my-2 mt-3"></mat-divider>

        <div class="btnDiv" >
            <button  mat-stroked-button *ngIf="data.showBackButton" (click)="back()" mat-dialog-close>Back</button> 
            <!-- data.showBackButton -->
            <button  mat-stroked-button *ngIf="data.showChangeDateButton" (click)="backToStep1()" mat-dialog-close>Change Date</button>
            <!-- data.showChangeDateButton -->


            <button  mat-raised-button color="primary" (click)="createQuote()" mat-dialog-close>Create Quote</button>

        </div>
    </div>
</div>