<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <div fxLayoutAlign="center center" fxLayout="column">


        <div fxLayoutAlign="center center" class="pt-4 pb-2 w-100">
            <p class="headers">Please enter delivery Zip code</p>
        </div>

        <div class="w-100" fxLayoutAlign="center center">
            <mat-form-field class="field" appearance="outline">
                <input matInput #input id="zipcodeTestId" placeholder="Enter Zip code" (keyup)="onkey(input.value)" type="text"
                    [maxLength]="5" formControlName="postalCode">
                <mat-error *ngIf="form.controls.postalCode.errors?.required">Please enter a valid 5-digit ZIP
                    code.</mat-error>
                <mat-error *ngIf="form.controls.postalCode.hasError('minlength')">Please enter a valid 5-digit ZIP
                    code.</mat-error>

            </mat-form-field>
        </div>

        <div class="pb-3 pt-1">
            <button mat-raised-button color="primary" type="submit">Submit</button>
        </div>
    </div>
</form>


<!-- loader -->
<div *ngIf="loader" fxLayout="column" fxLayoutAlign="center center" class="user-spinner">
    <mat-spinner color="primary" [diameter]="50"></mat-spinner>
</div>