import { is } from "date-fns/locale";
import { Component, effect, HostListener, Input } from "@angular/core";
import { OrderService } from "src/app/features/orders/services/order.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { ScanService } from "src/app/features/scan/services/scan.service";
import {
  Bundle,
  ColumnData,
  EditOrderState,
  OrderModel,
} from "src/app/models/order-model";
import {
  QuoteLineGrouped,
  QuoteLineModel,
  QuoteModel,
} from "src/app/models/quote-line-model";
import { ConfigService } from "../../services/config/config.service";
import { Subscription } from "rxjs";

import { RejectpopupComponent } from "../dialogbox/reject-popup/reject-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { serviceFrequencyMap } from "src/assets/config/constants";

@Component({
  selector: "app-location-manager-quote-summary",
  templateUrl: "./location-manager-quote-summary.component.html",
  styleUrls: ["./location-manager-quote-summary.component.scss"],
})
export class LocationManagerQuoteSummaryComponent {
  @Input() screenName: string; // Input property to receive screen name
  bundleData: QuoteLineGrouped[] = [];
  isMobile: boolean;
  EditOrderScreen: string = "editOrderChangeQuantity";
  showQuantityChangeDrawer = false;
  orderData: OrderModel | null;
  createCaseChangeQuantity: boolean;
  subscription: Subscription;
  productDetailsData: EditOrderState[] = [];
  bundle: QuoteLineModel[];
  uniqueBundleArray: Bundle[];
  location: ColumnData;
  bundleDataArr: Bundle;
  isReduceQuantity: boolean = false;
  isEditedOrder: boolean = false;
  public serviceFrequencyMap=serviceFrequencyMap;
 
  isEdited: boolean;

  constructor(
    public quoteStateService: QuoteStateService,
    private orderService: OrderService,
    private scannerService: ScanService,
    private configService: ConfigService,
    public dialog: MatDialog,
    public router: Router,
    private scanService: ScanService,
  ) {
    effect(() => {
      this.bundleData = this.quoteStateService.multiLocationData();
    });
  }

  ngOnInit() {
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    console.log("Is Mobile", this.isMobile);
    console.log("screenName", this.screenName);
//This is for edit order web view - to get the data from order summary (parent component) to update the state for change quantity drawer data
    if(this.screenName === "editOrder"){
      console.log("I am inside location manager quote summary");
      this.createCaseChangeQuantity = this.configService.getConfigProperty(
        "EDIT_ORDER_CHANGE_QUANTITY_CASE"
      );
      this.subscription = this.orderService.orderSummary.subscribe((result) => {
        if (result) {
          this.orderData = result;
          this.bundle = this.quoteStateService.prepareBundleData(
            this.orderData.quoteModel.quoteLines
          );
  
          if (this.orderData.quoteModel && this.orderData?.assetLocations) {
            let filteredJobSites = this.orderData.quoteModel.jobSites.filter(
              (site) =>
                this.orderData?.assetLocations.some(
                  (location) => site.address.id === location.jobsiteId
                )
            );
  
            // Assign the filtered jobsites back to quoteModel
            this.orderData.quoteModel.jobSites = filteredJobSites;
          }
  
          this.quoteStateService.mapJobitesToBundleData(
            this.orderData.quoteModel
          );
          this.editOrderData();
        } else {
          const urlSegments = this.router.url.split("/");
          const lastId = urlSegments[urlSegments.length - 2];
          // console.log("endPoint", lastId);
          this.orderService.getOrderById(lastId);
        }
      });
  
      // }

    }

   
  }

  //When user come from mobile responsive screen (now location assignment is child of this component) - to update state for change quantity drawer data

  toggleQuantityUI() {
    if (this.location.isEdited) {
      this.orderService.setDrawerState(false);
      let drawerCurrentState = this.orderService.drawerState();

      const dialog = this.dialog
        .open(RejectpopupComponent, {
          width: "430px",
          panelClass: "limit-exceed-dialog",
          data: {
            message:
              "Previous request for change quantity is in progress. Do you want to request to new change?",
            drawerStatus: drawerCurrentState,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === "yes") {
            this.orderService.setDrawerState(!this.orderService.drawerState());
          }
        });
    } else {
      this.orderService.setDrawerState(!this.orderService.drawerState());

      // this.showQuantityChangeDrawer = !this.showQuantityChangeDrawer;
    }
  }

  selectUnit(index) {
    this.bundleData.forEach((item: QuoteLineGrouped) => {
      if (item.isSelected) {
        item.isSelected = false;
      }
    });
    this.bundleData[index].isSelected = true;
    this.quoteStateService.multiLocationData.set(this.bundleData);
  }

  getAvailableQuantityByIndex(index: number) {
    const bundle = this.bundleData[index];
    if (bundle == undefined) return 0;
    const jobSiteIds = Object.keys(bundle).filter((key) =>
      key.includes("newLocation")
    );
    const total = jobSiteIds.reduce((totalQuantityQuoted, jobSiteId) => {
      return totalQuantityQuoted + bundle[jobSiteId].quantityQuoted;
    }, 0);
    return bundle.quantity - total;
  }
  //When user come from mobile responsive screen - to update state for change quantity drawer data
  onFocusGetEditOrderData() {
    this.isEditedOrder = this.location.isEdited;
    const contractId = this.orderData?.contractId;
    this.orderService.updateEditOrderValues({
      ...this.orderService.getCurrentEditOrderState(),
      selectedSite: this.location.address,
      contractId: contractId,

      sitewiseSelectedBundle: this.location.productDetails,
      // bundlesData: this.uniqueBundleArray,
      siteDetails: this.location.address,
      orderName: this.orderData?.quoteName,
      siteContact: this.location.contact,
      quantityQuoted: this.location.quantityQuoted,
      startDate: this.orderData?.startDate,
      endDate: this.orderData?.endDate,
      isEdited: this.isEditedOrder,
    });

    this.toggleQuantityUI();
  }

  //When user come from mobile responsive screen - to update state for change quantity drawer data
  handleDrawerDataInLocation(e: ColumnData) {
    this.location = e;
    this.onFocusGetEditOrderData();
  }
  editOrderData() {
    console.log("Location data in location manager quote summary", this.location);
    this.isEdited = this.location?.isEdited;
    const contractId = this.orderData?.contractId;
    this.orderService.updateEditOrderValues({
      ...this.orderService.getCurrentEditOrderState(),
      selectedSite: this.location?.address,
      contractId: contractId,

      sitewiseSelectedBundle: this.location?.productDetails,
      // bundlesData: this.uniqueBundleArray,
      siteDetails: this.location?.address,
      orderName: this.orderData?.quoteName,
      siteContact: this.location?.contact,
      quantityQuoted: this.location?.quantityQuoted,
      startDate: this.orderData?.startDate,
      endDate: this.orderData?.endDate,
      isEdited: this.isEdited,
    });

   
  }

  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
