<div
  class="popup-wrapper p-3"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="13px"
>
  <div class="icon">
    <img src="../../../../assets/img/refresh_icon.svg" alt="refresh icon">
  </div>
  <p>Please wait, your previous request is in process...</p>

  <mat-icon class="close-popup cursor-pointer" (click)="onClose()"
    >close</mat-icon
  >
</div>
