export class UserModel {
  id: string;
  contactId: string;
  contactName: string;
  ussPortalId: string;
  name: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: string;
  action?: string;
  auth0Id: string;
  accountId: string;
  accountName: string;
  myUssUserRole: string;
  myUssModules: string;
  isActive: boolean;
  roles: string;
}
