import { Injectable, effect } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, catchError, map } from "rxjs";
import {
  EasyPayState,
  OnConfirmEasyPayRequest,
} from "src/app/models/easy-pay-model";
import { ProfileService } from "../../accounts/services/profile/profile-services.service";
import { UserProfile } from "src/app/models/profile-model";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";

@Injectable({
  providedIn: "root",
})
export class EasyPayStateService {
  public currentEasyPay$ = new BehaviorSubject<EasyPayState>(
    new EasyPayState()
  );

  constructor(
    public profileService: ProfileService,
    private api: UssApiService,
    public toastr: ToastrService
  ) {

    effect(() => {
      const account = this.profileService.selectedAccount();
      this.subscribeToProfileService(account);

    });
  }

  private subscribeToProfileService(account:Partial<UserProfile>) {
    // set the initial profile state
    this.updateProfileInEasyPayState(account);
  }

  private updateProfileInEasyPayState(account:Partial <UserProfile>) {
    this.updateEasyPayValues({
      userProfile: account,
    });
  }

  /*
   * update values that not nested
   */
  updateEasyPayValues(data: Partial<EasyPayState>) {
    const easyPaycurrentState = this.currentEasyPay$.value;
    this.currentEasyPay$.next({
      ...easyPaycurrentState,
      ...data,
    });
    console.log("easyPay", this.currentEasyPay$.value);
  }

  // get the current easy pay state
  getEasyPayCurrentValue() {
    return this.currentEasyPay$.value;
  }

  // reset the easy pay state
  resetEasyPayState() {
    this.currentEasyPay$.next(new EasyPayState());
    // set the initial profile state
    this.updateProfileInEasyPayState(this.profileService.selectedAccount());
  }

  // api to update payment method
  updatePaymentMethod(data: OnConfirmEasyPayRequest) {
    return this.api.post(`contracts/confirm-easypay`, data).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res;
        }
        return null
      }),
      catchError((err) => {
        console.error(`error getting contracts: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }
}
