import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { perQuoteUnitLimit, phoneNumber } from "src/assets/config/constants";

@Component({
  selector: "app-limit-exceed",
  templateUrl: "./limit-exceed.component.html",
  styleUrls: ["./limit-exceed.component.scss"],
})
export class LimitExceedComponent {
  phoneNumber: string;
  perQuoteUnitLimit : number
  constructor(private dialogref: MatDialogRef<LimitExceedComponent>) {}
  ngOnInit() {
    this.phoneNumber = phoneNumber;
    this.perQuoteUnitLimit = perQuoteUnitLimit
  }

  onClose() {
    this.dialogref.close();
  }
}
