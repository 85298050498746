export class RFQ {
    id: string;
    formStatus: 'incomplete' | 'complete';
    recaptchaToken: string;
    lastClickId: string;
    sentToMarketingCloud: boolean;
    companyName: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    smsOptIn: boolean;
    emailAddress: string;
    startDate: Date;
    endDate: Date;
    deliveryZipCode: string;
    purposeOfRental: 'Business' | 'Personal' | 'Government';
    useType: 'Construction' | 'Event' | 'Other';
    rentalDuration: 'Under 7 Days' | '0 to 2 Months' | '3 to 5 Months' | '6+ Months';
    products: RFQProduct[];
    leadId: string;
    probabilityModel: string;
    winProbability: number;
    myUssEligible: boolean;
    myUssEligibilityDetails: EligibilityRuleResult[];
    myUssEligibilityError: string;
    myUssExistingUser: boolean;
    priorityGroup: string;
}


export class RFQProduct {
    code: string;
    name: string;
    quantity: number;
    category: {
      code: string;
      name: string;
    };
    skus:{
      bundleSKU: string;
      assetSKU: string;
      serviceSKU: string;
    }
    productType: 'standard' | 'specialty';
  }


  export class EligibilityRuleResult {
    public readonly rule: string;
    public passed: boolean;
    public details: string;

  }