import { Component, OnInit, ViewEncapsulation, ViewChild, effect } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MessagesService } from '../../services/messages.service';
import { ProfileService } from 'src/app/features/accounts/services/profile/profile-services.service';
import { Platform } from "@ionic/angular";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MessagesService ]
})
export class MessagesComponent implements OnInit {  
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedTab: number=1;
  public messages: any[];
  public files: any[];
  public meetings: any[]; 
  hide = false
  flags: Record<string, boolean|undefined>;
  userId: string;
  isNative: boolean = false;


  constructor(
    public profileService: ProfileService,
    private platform: Platform,

  
  ) {   this.isNative = this.platform.is("hybrid");

    effect(async () => {
      const account = this.profileService.selectedAccount();

      if (account.accountId != this.userId) {
        this.userId = `${account.accountId}`;
        this.flags = await this.profileService.getAllMyussFlags()
      }
    })


  }

  ngOnInit() {
  }

  

}
