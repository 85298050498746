<div>
    <div class="p-3">
        <h3>Assign to project</h3>
    </div>

    <mat-divider></mat-divider>

    <div class="p-3" [formGroup]="projectIdFormGroup" >
        <div >
            <p class="pb-1" >Project Name</p>
            <mat-form-field class="input_filter w-100" appearance="outline">
              <mat-label>Select project name</mat-label>
              <mat-select (selectionChange)="onSelection()" formControlName="projectId">
                <mat-option *ngFor="let project of projectQuoteList.project" [value]="project.id">{{project.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="projectIdFormGroup.controls.projectId.errors?.required">Please select project name.</mat-error>
            
            </mat-form-field>
        </div>

        <div >
            <p class="pb-1" >Project ID</p>
            <mat-form-field  class="input_filter w-100" appearance="outline">
              <mat-label>Select project Id</mat-label>
              <mat-select (selectionChange)="onSelection()" formControlName="projectId">
                <mat-option *ngFor="let project of projectQuoteList.project" [value]="project.id">{{project.projectNumber}}</mat-option>
              </mat-select>
              <mat-error *ngIf="projectIdFormGroup.controls.projectId.errors?.required">Please select project Id.</mat-error>
             
            </mat-form-field>

        </div>

        <mat-divider></mat-divider>
        <div class="bottom_right pt-2">

          <button mat-raised-button class="mx-2" mat-dialog-close>Cancel</button>
          <button mat-raised-button class="mx-2" (click)="onAssign()" color="primary">Assign</button>
    
        </div>
    </div>

    
</div>