export const phoneNumber = "1-888-320-1861";
export const helpMyUssEmail = "help@myuss.com";
export const customerOnboardingEmail = "customeronboarding@myuss.com";
 export const fixedDateMessage=`If Fixed is selected, a pickup will automatically be added for this date (can be changed later). If Estimated, a pickup will need to be scheduled through MySiteServices or by calling ${phoneNumber}. If you are unsure of the exact date your project will end, select Estimated.`

export const features = {
  home: "myussHomeEnabled",
  myussHomeEnabled: "Home",
  quotes: "myussQuotesEnabled",
  myussQuotesEnabled: "Quotes",
  orders: "myussOrdersEnabled",
  myussOrdersEnabled: "Orders",
  billing: "myussBillingEnabled",
  myussBillingEnabled: "Billing",
  easypay: "myussEasyPayEnabled",
  myussEasyPayEnabled: "Easy Pay",
  cases: "myussCasesEnabled",
  myussCasesEnabled: "Cases",
  users: "myussUsersEnabled",
  projects: "myussProjectsEnabled",
  myussProjectsEnabled: "Projects",
};
export const mapFeaturesToRoutes = [
  { feature: "myussHomeEnabled", route: "home" },
  { feature: "myussQuotesEnabled", route: "quotes" },
  { feature: "myussOrdersEnabled", route: "orders" },
  { feature: "myussBillingEnabled", route: "billing" },
  { feature: "myussEasyPayEnabled", route: "easypay" },
  { feature: "myussCasesEnabled", route: "cases" },
  { feature: "myussProjectsEnabled", route: "projects" },
];

export const enabledModules = {
  quotes: "MyUSS Quotes Enabled",
  orders: "MyUSS Orders Enabled",
  billing: "MyUSS Billing Enabled",
  easyPay: "MyUSS Easy Pay Enabled",
  cases: "MyUSS Cases Enabled",
  projects: "MyUSS Projects Enabled",
  findUnit: "MyUSS Asset Scanning Enabled",
};

export const commentOption = [
  { value: "Response", viewValue: "Response" },
  { value: "Status", viewValue: "Status" },
  { value: "General Question", viewValue: "General Question" },
  { value: "Pricing", viewValue: "Pricing" },
];

export const perQuoteUnitLimit = 10;

export const timeZoneMapper = {
  // central
  "Central Daylight Time": "Central",
  "Central Standard Time": "Central",
  // eastern
  "Eastern Daylight Time": "East",
  "Eastern Standard Time": "East",
  // mountain
  "Mountain Daylight Time": "Mountain",
  "Mountain Standard Time": "Mountain",
  // pacific
  "Pacific Daylight Time": "Pacific",
  "Pacific Standard Time": "Pacific",
};

export const descriptionTableHeader = [
  {
    label: "Product",
    fieldName: "Product",
  },
  {
    label: "Size",
    fieldName: "Size",
  },
  {
    label: "Quantity",
    fieldName: "Quantity",
    cellAttributes: {
      alignment: "left",
    },
  },
  {
    label: "Service Frequency",
    fieldName: "Service Frequency",
  },
  {
    label: "Price",
    fieldName: "Price",
    type: "currency",
    cellAttributes: {
      alignment: "left",
    },
  },
  {
    label: "Action",
    fieldName: "Action",
  },
  {
    label: "Notes",
    fieldName: "Notes",
  },
];

export const roleMapping = {
  "Account Admin": 1,
  "Standard User": 2,
  "Read Only": 3,
};
export const noDataAvlText = "No data available.";

export const serviceFrequency = [
  { serviceName: "1 Service 1 Day per Week" },
  { serviceName: "1 Service 2 Days per Week" },
  { serviceName: "1 Service 3 Days per Week" },
  { serviceName: "1 Service 4 Days per Week" },
  { serviceName: "1 Service 5 Days per Week" },
];

export const fileSizeLimit = 5 * 1024 * 1024; // 5 MB
export const maxNoOfFiles = 5;
export const allowedExtensions = [
  "pdf",
  "doc",
  "docx",
  "png",
  "jpeg",
  "jpg",
  "xls",
  "xlsx",
];
export const environmentMapping = {
  'ci':'Development',
  'qa':'Testing',
  'stage':'Staging'
}

export const serviceFrequencyMap = {
  "1 Svc 1 Day Wk": "1X | Week",
  '1 Svc 2 Days Wk':'2X | Week',
  '1 Svc 3 Days Wk':'3X | Week',
  '1 Svc 4 Days Wk':'4X | Week',
  '1 Svc 5 Days Wk':'5X | Week',

  "1 Service 1 Day per Week":"1X | Week",
  "1 Service 2 Days per Week":'2X | Week',
  "1 Service 3 Days per Week":'3X | Week',
  "1 Service 4 Days per Week":'4X | Week',
  "1 Service 5 Days per Week":'5X | Week',
};

export const prodSubType="Renewable"
export const caseTypes = [
 
  {caseType: "Change Quantity - Reduce Units", caseCount: 0 },
  {caseType: "Change Quantity - Add Units", caseCount: 0},
  {caseType: "Add New Unit Type", caseCount: 0},
  {caseType: "Change Service Frequency" , caseCount: 0 },
  {caseType: "Move", caseCount: 0},
 
 
  
];

export const serviceFrequencyMapV2={
  "1 Service 1 Day per Week":"1X | Week",
  "1 Service 2 Days per Week":'2X | Week',
  "1 Service 3 Days per Week":'3X | Week',
  "1 Service 4 Days per Week":'4X | Week',
  "1 Service 5 Days per Week":'5X | Week',
}

export const notificationDuration:{days:number,show:string}[]=[{days:7,show:"7 Days"},{days:15,show:"15 Days"},{days:30,show:"1 Month"},{days:60,show:"2 Months"},{days:90,show:"3 Months"}];
export const serviceTypes=["All Types","Pickup","Delivery","Service","Install","StakeDown"];






