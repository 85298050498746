import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { EditedData } from "src/app/models/order-model";

@Component({
  selector: "app-reject-popup",
  templateUrl: "./reject-popup.component.html",
  styleUrls: ["./reject-popup.component.scss"],
})
export class RejectpopupComponent {
  constructor(
    private dialogref: MatDialogRef<RejectpopupComponent>,
    public router: Router,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public isEditedData: EditedData
    
  ) {}
  ngOnInit() {
    console.log("isEditedData", this.isEditedData);
  }

  onYes() {
    this.dialogref.close("yes");
  }
  onOk(){
    // this.router.navigateByUrl("/orders/ordersummary/800VA000003tqttYAA");
    this.location.back();
  }
}
