import { DatePipe } from "@angular/common";
import { Component, ElementRef, Input, ViewChild, effect } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { CaseDetailsStateService } from "src/app/features/cases/services/case-details-state.service";
import { OrderService } from "src/app/features/orders/services/order.service";
import { ProjectService } from "src/app/features/projects/services/project.service";
import { Cases } from "src/app/models/case-list-model";
import { EasyPayState } from "src/app/models/easy-pay-model";
import { Contracts } from "src/app/models/order-model";
import { ProjectModel } from "src/app/models/project-model";
import { ConfigService } from "../../services/config/config.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectProjectComponent } from "../dialogbox/select-project/select-project.component";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { ToastrService } from "ngx-toastr";
import { Platform } from "@ionic/angular";
import { caseTypes } from "../../../../assets/config/constants";

@Component({
  selector: "app-card-list-mobile-view",
  templateUrl: "./card-list-mobile-view.component.html",
  styleUrls: ["./card-list-mobile-view.component.scss"],
})
export class CardListMobileViewComponent {
  @Input() contractsFilter;
  @ViewChild("searchInput") searchInput: ElementRef;
  @Input() screen: string;
  // @Input() accountStatus: string | undefined = "";

  contractFlag: boolean = true;
  allStatus: string[];
  contracts: Contracts[];
  allContracts: Contracts[];
  filter: string = "All";
  filterContracts: Contracts[];
  cardIcon: string = "../../../../assets/img/card_icon/";
  userId: string;
  isIos: boolean = false;

  // cases
  allCases: Cases[];
  @Input() orderName: string;
  filterCases: Cases[];
  endPoint: string;
  isCaseClosed: boolean = false;
  showFromToDate: boolean = false;
  selectedOption: string = "30 days";
  isFromDateSelected: boolean = false;
  isEndDateSelected: boolean = false;
  endDate: string;
  maxDate: Date = new Date();
  minEndDate: Date = new Date();
  fromDate: string;
  currentDate: string;
  monthStartDate: string;
  isOrderScreen: boolean = false;
  isOrderCases: boolean = true;
  isCases: boolean = true;

  // easypay
  easyPaySubscription: Subscription;
  currentState: EasyPayState = new EasyPayState();
  paymentType: string;
  contractsWithPaymentMethods: string;
  cardBrand: string;
  title: string;
  titleDescription: boolean = false;
  type: string = "";
  contactId: string = "";
  projectId: string = "";
  projectList: ProjectModel[] = [];
  projectTypeFilter = new FormControl("All");
  projectEnabled: boolean;
  flags: Record<string, boolean | undefined>;
  quoteIds: string[] = [];
  isInActiveAccount: boolean = false;
  mobileView: boolean = false;
  cases: { caseType: string; caseCount: number }[];


  constructor(
    private orderService: OrderService,
    private toastr: ToastrService,
    private router: Router,
    private profileService: ProfileService,
    private datePipe: DatePipe,
    private caseService: CaseDetailsStateService,
    private _Activatedroute: ActivatedRoute,
    public _projectService: ProjectService,
    private configService: ConfigService,
    private box: MatDialog,
    private platform: Platform

  ) {
    this.isIos = this.platform.is("ios");
    this._Activatedroute.queryParams.subscribe((params) => {
      this.projectId = params.projectId;
      this.projectTypeFilter.setValue(this.projectId?this.projectId:"All");
      if(!params.projectId){
        this._projectService.setSelectedProject(new ProjectModel());
      }
    });
    this.projectEnabled =
    this.configService.getConfigProperty("PROJECTS_ENABLED");

    effect(async () => {
      this.cases = caseTypes;
    

      const urlSegments = this.router.url;
      if (urlSegments.includes("cases")) {
        this.endPoint = "cases";
      }
      if (
        urlSegments.includes("orders") &&
        !urlSegments.includes("ordersummary")
      ) {
        this.endPoint = "orders";

      }
      if (
        urlSegments.includes("orders") &&
        urlSegments.includes("ordersummary")
      ) {
        this.endPoint = "isOrderScreen";
      }
      this.userId = this.profileService.selectedAccount().accountId as string;
      // Set maxDate to today's date
      this.maxDate.setDate(this.maxDate.getDate());
      this.isCaseClosed = false;
      if (this.endPoint === "orders") {
        this.projectTypeFilter = new FormControl("All");
        this.Contracts("Activated,Suspended,Draft");
        this.fetchProjects("Active");
        this.getProjectData();
        this.filter = "All";
      }
      if (this.endPoint === "cases" && this.mobileView !== false) {
      
        this.getAllActiveCases();
      }
     
      this.flags = await this.profileService.getAllMyussFlags();
      this.isInActiveAccount = this.profileService?.selectedAccount().myussUserRole === "Read Only" ||
      this.profileService.isAccountInActive();

    });
    if (window.innerWidth <= 600) {
    
      if (!this.mobileView) {
        this.mobileView = true;
        // this.selectedTabIndex=0;
      }
    } else {
     
      if (this.mobileView) {
        this.mobileView = false;
        // this.selectedTabIndex=0;
      }
    }
    
  }

  async ngOnInit(): Promise<void> {
    this.flags = await this.profileService.getAllMyussFlags();
   

    // Get the current URL segments
    const urlSegments = this.router.url;
    if (urlSegments.includes("cases")) {
      this.endPoint = "cases";
    }
    if (urlSegments.includes("orders")) {
      this.endPoint = "orders";
    }
    if (
      urlSegments.includes("orders") &&
      urlSegments.includes("ordersummary")
    ) {
      this.endPoint = "isOrderScreen";
    }

    
    if (this.screen == "technical-support-list" && this.mobileView) {
      this.title = "Technical Support Requests";
      this.type = "MySiteServices";
      this.projectId = "";
      this.contactId = this.profileService.selectedAccount()
        .contactId as string;
      this.getAllActiveCases();
    } else {
      this.title = "Cases";
      this.type = "";
      this.contactId = "";
    }
    this.isInActiveAccount = this.profileService?.selectedAccount().myussUserRole === "Read Only" ||
    this.profileService.isAccountInActive();

    if (window.innerWidth <= 600) {
    
      if (!this.mobileView) {
        this.mobileView = true;
        // this.selectedTabIndex=0;
      }
    } else {
     
      if (this.mobileView) {
        this.mobileView = false;
        // this.selectedTabIndex=0;
      }
    }
  }

  ngOnChanges() {
    this.filterCases = this.allCases;
    if (this.endPoint === "orders" && this.mobileView) {
    
      this.projectTypeFilter.setValue(this.projectId?this.projectId:"All");
      this.Contracts(this.contractsFilter);
      this.fetchProjects("Active");
      this.getProjectData();
      this.filter = "All";
    }
   
    if (
      this.orderName?.length > 0 &&
      this.endPoint !== "orders" &&
      this.endPoint !== "cases" && this.mobileView
    ) {
      this.getAllCases(this.orderName);
    }
    if (window.innerWidth <= 600) {
    
      if (!this.mobileView) {
        this.mobileView = true;
        // this.selectedTabIndex=0;
      }
    } else {
     
      if (this.mobileView) {
        this.mobileView = false;
        // this.selectedTabIndex=0;
      }
    }
  }

  getProjectData() {
    this._projectService.projectData$.subscribe((projects) => {
      if (projects) {
        this.projectList = projects;
      }
    });
  }

  //To fetch projects based on status
  fetchProjects(status: string) {
    this._projectService.fetchProjects(status).subscribe();
  }

  Contracts(filter: string) {
    this.orderService.getAllContract(filter).subscribe((res) => {
      if (res.length > 0) {
        res.map((contract) => {
          const GroupedArray = Object.values(contract?.caseTypeWiseCasesCount);
          contract.caseTypeWiseCasesCount = GroupedArray;
          this.contracts = res;
  
          return GroupedArray;
        });

      
        setTimeout(() => {
         if(this.searchInput){

           this.searchInput.nativeElement.value = "";
         }
        }, 0);
        this.contractFlag = true;

        this.contracts = res.sort((a, b) => b.name.localeCompare(a.name));
        this.allContracts = res;
        this.filterContracts = res;
        this.allStatus = [
          ...new Set(
            this.contracts.map((contract: Contracts) => contract.status)
          ),
        ] as string[];

        this.onFilterSelect("All")
      } else {
        this.contractFlag = false;
      }
    });
  }
  getCaseTypeCount(caseArray) {
    caseTypes.forEach((itemA) => {
      let match = caseArray.find(
        (itemB) => itemB.MyUSS_Case_Type__c === itemA.caseType
      );
      if (match) {
        itemA.caseCount = match.caseCount;
      } else {
        itemA.caseCount = 0;
      }
    });

    // Output the updated listA
   
  }
  onFilterSelect(data: string) {
    this.filter = data;
    if (data === "All") {
      if(this.searchInput){
        this.searchInput.nativeElement.value = "";
      }
      if (this.projectTypeFilter.value === "All") {
        this.contracts = this.allContracts;
        this.filterContracts = this.allContracts;
      }else if (
        this.projectTypeFilter.value === "Orders with no project assign"
      ) {
        this.quoteIds = [];
        
      this.contracts = this.allContracts.filter(
          (contract) => contract.projectDetails === null && contract.quoteId
        )
        this.filterContracts = this.allContracts.filter(
          (contract) =>
            contract.projectDetails === null && contract.quoteId
        );
       
      } else {
        this.contracts = this.allContracts.filter(
          (contract) =>
            contract.projectDetails?.id === this.projectTypeFilter.value
        );
        this.filterContracts = this.allContracts.filter(
          (contract) =>
            contract.projectDetails?.id === this.projectTypeFilter.value
        );
      }
    } else {
      if(this.searchInput){
        this.searchInput.nativeElement.value = "";
      }
      if (this.projectTypeFilter.value === "All") {
        this.contracts = this.allContracts.filter(
          (contract) => contract.status === data
        );
        this.filterContracts = this.allContracts.filter(
          (contract) => contract.status === data
        );
      } else {
        this.contracts = this.allContracts.filter(
          (contract) =>
            contract.status === data &&
            contract.projectDetails?.id === this.projectTypeFilter.value
        );
        this.filterContracts = this.allContracts.filter(
          (contract) =>
            contract.status === data &&
            contract.projectDetails?.id === this.projectTypeFilter.value
        );
      }
    }
  }

  onVisibility(contract:Contracts) {
    if(contract.contractNumber){
      this.router.navigate(["orders/ordersummary", contract.contractId]);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
      .toLowerCase()
      .trim();

    if (filterValue === "") {
      this.onFilterSelect(this.filter);
    }

    this.contracts = this.filterContracts.filter((contract: Contracts) => {
      const contractStr = JSON.stringify(contract).toLowerCase();
      return contractStr.includes(filterValue);
    });
  }
  // cases methods

  getAllCases(id?: string) {
    if (this.endPoint === "cases") {
      this.getAllActiveCases();
    } else {
      this.isOrderScreen = true;
      // this.isCases = false;
      // this.isOrderCases = false;
      this.caseService
        .getOrderCases(this.userId, "open", id!)
        .subscribe((result) => {
          if (result.cases?.length > 0) {
            this.isOrderCases = true;
            this.isCases = true;
            this.allCases = result.cases;
            this.filterCases = result.cases;
          } else {
            this.isOrderCases = false;
            this.isCases = false;
          }
        });
    }
  }

  viewCaseDetails(caseNumber: string) {
    const orderName = this.orderName || "";
    const route: string[] = [];
    // console.log("Screen name:", this.screen);

    switch (true) {
      case this.endPoint === "cases":
        route.push("cases/case-details", caseNumber);
        break;
      case this.screen === "technical-support-list":
        route.push("technical-support/support-details", caseNumber);
        break;
      default:
        route.push("orders/case-details", caseNumber);
        break;
    }
    this.router.navigate(route);
  }

  searchCases(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
      .toLowerCase()
      .trim();
    this.allCases = this.filterCases.filter((contract: Cases) => {
      const contractStr = JSON.stringify(contract).toLowerCase();
      return contractStr.includes(filterValue);
    });
  }

  onToggleChange(event: MatSlideToggleChange) {
    this.isCaseClosed = event.checked;
    this.searchInput.nativeElement.value = "";
    if (this.isCaseClosed) {
      this.selectedValue();
    } else {
      this.selectedOption = "30 days"; // or this.selectedOption = '';
      this.isFromDateSelected = false;
      this.isEndDateSelected = false;

      if (this.isOrderScreen) {
        this.caseService
          .getOrderCases(this.userId, "open", this.orderName)
          .subscribe((result) => {
          
            if (result.cases?.length > 0) {
            
              this.isOrderCases = true;
              this.isCases = true;

              this.allCases = result.cases;
              this.filterCases = result.cases;
            
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.getAllActiveCases();
      }
      this.showFromToDate = false;
    }
  }

  bothDateSelected() {
    if (this.endDate && this.fromDate) {
      return false; // Enable the button
    } else {
      return true; // Disable the button
    }
  }

  getCustomDateClosedCases() {
    if (this.isCaseClosed && this.endDate && this.fromDate) {
      if (this.isOrderScreen) {
        this.caseService
          .getOrderClosedCases(
            this.userId,
            "closed",
            this.orderName,
            this.fromDate,
            this.endDate
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = true;
              this.isEndDateSelected = false;
              this.isFromDateSelected = false;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
              this.isEndDateSelected = false;
              this.isFromDateSelected = false;
            }
          });
      } else {
        this.caseService
          .getClosedCases(
            this.userId,
            "closed",
            this.fromDate,
            this.endDate,
            this.type,
            this.contactId,
            this.projectId
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.isEndDateSelected = false;
              this.isFromDateSelected = false;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
              this.isEndDateSelected = false;
              this.isFromDateSelected = false;
            }
          });
      }
    }
  }

  selectedEndDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.endDate = this.transformDate(event.value) ?? "";
    }

    if (this.endDate) {
      this.isEndDateSelected = true;
    }
  }

  selectedStartDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.fromDate = this.transformDate(event.value) ?? "";
      this.addOneDay(event.value);
      if (this.fromDate) {
        this.isFromDateSelected = true;
      }
    }
  }

  addOneDay(date: Date) {
    const currentDate = new Date();

    if (
      date.getFullYear() !== currentDate.getFullYear() ||
      date.getMonth() !== currentDate.getMonth() ||
      date.getDate() !== currentDate.getDate()
    ) {
      const newDate = new Date(date);

      newDate.setDate(newDate.getDate() + 1);

      this.minEndDate = newDate;
    } else {
      this.minEndDate = new Date();
    }
  }
  transformDate(date: Date) {
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  selectedValue() {
   
    this.currentDate = this.transformDate(new Date()) ?? "";

    if (this.selectedOption === "Custom Date" && this.isCaseClosed) {
      this.showFromToDate = true;
    } else if (this.selectedOption === "30 days" && this.isCaseClosed) {
      this.oneMonth();
    } else if (this.selectedOption === "60 days" && this.isCaseClosed) {
      this.twoMonth();
    } else if (this.selectedOption === "90 days" && this.isCaseClosed) {
      this.threeMonth();
    }
  }

  oneMonth() {
    this.showFromToDate = false;
    this.endDate = "";
    this.monthStartDate =
      this.transformDate(this.calculateCustomDate(30)) ?? "";

    if (this.monthStartDate || this.currentDate) {
      if (this.isOrderScreen) {
        this.caseService
          .getOrderClosedCases(
            this.userId,
            "closed",
            this.orderName,
            this.monthStartDate,
            this.currentDate
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = true;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.caseService
          .getClosedCases(
            this.userId,
            "closed",
            this.monthStartDate,
            this.currentDate,
            this.type,
            this.contactId,
            this.projectId
          )
          .subscribe((result) => {
           
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
            }
          });
      }
    }
  }
  twoMonth() {
    this.endDate = "";
    this.showFromToDate = false;
    this.monthStartDate =
      this.transformDate(this.calculateCustomDate(60)) ?? "";

    if (this.monthStartDate || this.currentDate) {
      if (this.isOrderScreen) {
        this.caseService
          .getOrderClosedCases(
            this.userId,
            "closed",
            this.orderName,
            this.monthStartDate,
            this.currentDate
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = true;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.caseService
          .getClosedCases(
            this.userId,
            "closed",
            this.monthStartDate,
            this.currentDate,
            this.type,
            this.contactId,
            this.projectId
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
            }
          });
      }
    }
  }
  threeMonth() {
    this.endDate = "";
    this.showFromToDate = false;
    this.monthStartDate =
      this.transformDate(this.calculateCustomDate(90)) ?? "";

    if (this.monthStartDate || this.currentDate) {
      if (this.isOrderScreen) {
        this.caseService
          .getOrderClosedCases(
            this.userId,
            "closed",
            this.orderName,
            this.monthStartDate,
            this.currentDate
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isOrderCases = true;
              this.isCases = true;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isOrderCases = false;
              this.isCases = false;
            }
          });
      } else {
        this.caseService
          .getClosedCases(
            this.userId,
            "closed",
            this.monthStartDate,
            this.currentDate,
            this.type,
            this.contactId,
            this.projectId
          )
          .subscribe((result) => {
            if (result.cases?.length > 0) {
              this.isCases = true;
              this.isOrderCases = false;
              this.allCases = result.cases;
              this.filterCases = result.cases;
            } else {
              this.isCases = false;
              this.isOrderCases = false;
            }
          });
      }
    }
  }
  calculateCustomDate(count: number) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - count);
    return currentDate;
  }

  getAllActiveCases() {
    this.isOrderScreen = false;

    this.caseService
      .getCases(this.userId, "open", this.type, this.contactId, this.projectId)
      .subscribe((result) => {
        if (result.cases?.length > 0) {
        
          this.isCases = true;
          this.isOrderCases = false;
          this.allCases = result.cases;
          this.filterCases = result.cases;
          this.router.navigate([], {
            queryParams: {
              projectId: null,
            },
            queryParamsHandling: "merge",
          });
        } else {
          this.isCases = false;
          this.isOrderCases = false;
        }
      });
  }

  onProjectFilter() {
    setTimeout(() => {
      // console.log("project filter", this.projectTypeFilter.value);
    }, 5000);
    if (this.projectTypeFilter.value === "All") {
      this.onFilterSelect((this.filter = "All"));
    } else {
      this.onFilterSelect(this.filter);
    }
    this.router.navigate([], {
      queryParams: {
        projectId: null,
      },
      queryParamsHandling: "merge",
    });
  }

  getOrderNumber(orderNumber: string | null): string {
    return orderNumber && orderNumber !== "O-null" ? orderNumber : "-";
  }

  getCaseType(caseType: string | null): string {
    return caseType ? caseType : "-";
  }
  editOrder(contractId: string) {
    if (this.profileService.selectedAccount().myussModules?.myussCasesEnabled) {
      this.router.navigateByUrl(`/orders/edit-order/${contractId}`);
    } else {
      this.router.navigateByUrl(
        `/orders/edit-order/${contractId}/change-quantity`
      );
    }
  }


  onAssignProject() {
    if(this.quoteIds.length > 0) {
    this.box.open(SelectProjectComponent, {
      minWidth: "30%",
      disableClose: true,
      data: { project: this.projectList, quoteIds: this.quoteIds },
    }).afterClosed().subscribe((res) => { 
      if(res){
        this.quoteIds = [];
        this.projectTypeFilter.setValue("All");
        this.Contracts("Activated,Suspended,Draft");    
      }
    });
  }else{
    this.toastr.error("Please select a order to assign project");

  }
  }

  onChecked(id: string, event: MatCheckboxChange) {
    if (event.checked) {
      this.quoteIds.push(id);
    } else {
      this.quoteIds = this.quoteIds.filter((quoteId) => quoteId !== id);
    }
  }

}
