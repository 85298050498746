import { Injectable, Signal, signal, ViewChild } from "@angular/core";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import {
  catchError,
  map,
  of,
  Observable,
  firstValueFrom,
  BehaviorSubject,
  Subscription,
  Subject,
  interval,
  startWith,
  takeUntil,
  exhaustMap,
} from "rxjs";
import {
  additonalServiceReq,
  PlytixProduct,
  ProdcutOption,
  ProductRequestModel,
} from "src/app/models/product-model";
import {
  ContactFormModel,
  productForm,
  QuoteDetailsFormGroupModel,
  QuoteModelDetailsV2,
  SelectedProduct,
  SiteInformationFormModel,
} from "src/app/models/enhance-quote-model";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ImageSliderService } from "./image-slider.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  Contact,
  DialogResult,
  GetContactModel,
  RequestSiteDetails,
  SubSite,
} from "src/app/models/site-details-model";
import { SiteAddressExistPopupComponent } from "src/app/shared/components/dialogbox/site-address-exist-popup/site-address-exist-popup.component";
import { WeekendDayValidationPopupComponent } from "src/app/shared/components/dialogbox/weekend-day-validation-popup/weekend-day-validation-popup.component";

import { ProfileService } from "../../accounts/services/profile/profile-services.service";
import { perQuoteUnitLimit, prodSubType } from "src/assets/config/constants";
import { ConfigService } from "src/app/shared/services/config/config.service";
import moment from "moment";
import { getDurationCategory } from "src/assets/config/duration-picklist";
import { DatePipe } from "@angular/common";
import { ProjectService } from "../../projects/services/project.service";

import { LimitExceedComponent } from "src/app/shared/components/dialogbox/limit-exceed/limit-exceed.component";
import {
  QuoteLineGrouped,
  QuoteLineModel,
} from "src/app/models/quote-line-model";
import { SurpassedDatePopupComponent } from "src/app/shared/components/dialogbox/surpassed-date-popup/surpassed-date-popup.component";
import { ProjectLocationComponent } from "src/app/shared/components/project-location/project-location.component";
import { AddLocation, Address } from "src/app/models/address-model";
import { AccountService } from "../../accounts/services/account/account.service";
import { QuoteStateService } from "../../quotes/services/quote-state.service";
import { OverlayContainer, ToastrService } from "ngx-toastr";
import { ConfirmQuoteModel } from "src/app/models/quoteState-model";
import { QuoteCreationService } from "../../quotes/services/quote-creation.service";
import { LoadingService } from "src/app/shared/services/loading/loading.service";
import { ConfirmationPopupComponent } from "src/app/shared/components/dialogbox/confirmation-popup/confirmation-popup.component";
import { Router } from "@angular/router";
import {
  AcceptAndRejectResponse,
  PDFAcceptAndReject,
} from "src/app/models/viewandaccept-model";
import { PaymentMethod } from "src/app/models/easy-pay-model";
import { PlacementNotes } from "src/app/models/order-model";
import { JobSiteLocation } from "src/app/models/jobsite-model";
import { ApiRespDTO } from "src/app/models/api.model";
import { QuoteModel } from "src/app/models/quote-model";
import { InprogressRequestPopUpComponent } from "src/app/shared/components/dialogbox/inprogress-request-pop-up/inprogress-request-pop-up.component";
import { RFQ } from "src/app/models/rfq-model";
import { ProjectModel } from "src/app/models/project-model";

@Injectable({
  providedIn: "root",
})
export class EnhanceQuoteService {
  public quoteHasError$ = new BehaviorSubject<boolean | null>(null);
 browserRefresh=false;

  quoteState = signal<QuoteModelDetailsV2>(new QuoteModelDetailsV2());

  productCodeToTabIndex: { [key: string]: number };
  formResetSignal = signal<boolean>(false);
  selectedTabIndex = 0;
  products = signal<PlytixProduct[]>([]);
  pipe = new DatePipe("en-US");
  enhanceQuoteUiSignal = signal<boolean>(false);
  // selectedCard: PaymentMethod | undefined;
  selectedPaymentCard = signal<PaymentMethod | undefined>(new PaymentMethod());

  isDraftSaved: boolean = false;
  rawProducts: PlytixProduct[] = [];
  @ViewChild(ProjectLocationComponent)
  projectLocationComponent!: ProjectLocationComponent;
  progressToastrCalled: boolean = false;
  isQuoteCreationInProgress=false;
  inprogressPopup:MatDialogRef<InprogressRequestPopUpComponent>;
  rfqData:RFQ;

  constructor(
    private api: UssApiService,
    private fb: FormBuilder,
    private sliderService: ImageSliderService,
    private dialog: MatDialog,
    public profileService: ProfileService,
    private configService: ConfigService,
    private _projectService: ProjectService,
    private accountService: AccountService,
    public quoteStateService: QuoteStateService,
    private toastr: ToastrService,
    private box: MatDialog,
    public quoteCreationService: QuoteCreationService,
    private loadingService: LoadingService,
    public router: Router,
    private overlayContainer: OverlayContainer
  ) {}

  // getCurrentValue() {
  //   return this.currentQuote$.value;
  // }
  getPlytixProducts() {
    return this.api.get("products/list", {}, "v2").pipe(
      map((res) => {
        if (res["status"] === 1000) {
          this.rawProducts = res["data"];
          const formattedData = this.formatProductData(res["data"]);
          console.log("formattedData", formattedData);
          this.products.set(formattedData);
          const productCodeToTabIndex: { [key: string]: number } = {};
          this.products().forEach((product, i) => {
            productCodeToTabIndex[product.productCode] = i;
          });
          this.productCodeToTabIndex = productCodeToTabIndex;
          return formattedData;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error getting products: ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }
  formatProductData(products: PlytixProduct[]): PlytixProduct[] {
    const productCodeMapping = products.reduce((acc, product) => {
      acc[product.productCode] = product;
      return acc;
    }, {});
    const formattedProducts = products.reduce((acc, product) => {
      if (product.productType === "Bundle") {
        acc[product.productCode] = product;
        acc[product.productCode].assetList = [];
        acc[product.productCode].serviceList = [];
        acc[product.productCode].ancillaryServiceList = [];
        product.productOptions?.forEach((option) => {
          if (option.product.productType === "Asset") {
            acc[product.productCode].assetList.push(
              productCodeMapping[option.product.productCode]
            );
          }
          if (option.product.productType === "Service") {
            acc[product.productCode].serviceList.push(
              productCodeMapping[option.product.productCode]
            );
          }
          if (
            option.product.productType === "Ancillary Services" ||
            option.product.productType === "Ancillary Asset"
          ) {
            acc[product.productCode].ancillaryServiceList.push(
              productCodeMapping[option.product.productCode]
            );
          }
        });
      }
      return acc;
    }, {});
    return Object.values(formattedProducts);
  }

  setQuoteState(quote: QuoteModelDetailsV2) {
    this.quoteState.set(quote);
  }

  //add the product to the quote
  addProductToQuote(product: SelectedProduct) {
    const selectedProducts = this.quoteState().selectedProducts || [];
    selectedProducts.push(product);
    const quoteState = Object.assign({}, this.quoteState(), {
      selectedProducts: selectedProducts,
    });
    this.setQuoteState(quoteState);
  }
  //delete the product from summary
  deleteProductFormSummary(index: number) {
    const selectedProducts = this.quoteState().selectedProducts || [];
    selectedProducts.splice(index, 1);
    const quoteState = Object.assign({}, this.quoteState(), {
      selectedProducts: selectedProducts,
    });
    this.setQuoteState(quoteState);
    this.isDraftSaved = false;
  }
  //edit the product from summary
  editProductFromSummary(index: number) {
    const quoteState = Object.assign({}, this.quoteState(), {
      editIndex: index,
      isEdit: true,
    });
    this.setQuoteState(quoteState);
  }
  //prefilled
  prefillForm() {
    const form = this.createForm();
    if (this.quoteState().selectedProducts.length > 0) {
      const editProduct =
        this.quoteState().selectedProducts[this.quoteState().editIndex];
      form.patchValue({
        bundle: editProduct.bundle,
        asset: editProduct.asset,
        service: editProduct.service,
        quantity: editProduct.quantity,
      });
      this.sliderService.selectedSlide.set(editProduct.asset);
      const formArray = form.get("additonalServices") as FormArray;
      formArray.clear(); // Clear the array if it was initialized before
      editProduct.additonalServices.forEach((product: PlytixProduct) => {
        formArray.push(new FormControl(product));
      });

      return form;
    }
    return form;
  }

  createForm(): FormGroup<productForm> {
    const product = new productForm();
    const productFormGroup = this.fb.group<productForm>(product);
    return productFormGroup;
  }
  //save the edited product
  saveEditedProduct(product: SelectedProduct, index: number) {
    const selectedProducts = this.quoteState().selectedProducts || [];
    selectedProducts[index] = product;
    const quoteState = Object.assign({}, this.quoteState(), {
      selectedProducts: selectedProducts,
      isEdit: false,
    });
    this.setQuoteState(quoteState);
  }
  //unit and services form reset
  formReset() {
    const bundle = this.products()[0];
    const form = this.createForm();
    this.selectedTabIndex = 0;

    this.sliderService.selectedSlide.set(null);
    form.reset();
    form.get("quantity")?.setValue(1);
    form.get("bundle")?.setValue(bundle);
    form.get("asset")?.setValue(null);
    form.get("service")?.setValue(null);
    const service = bundle.serviceList.find(
      (service: PlytixProduct) => service.name === "1 Svc 2 Days Wk"
    );
    if (service) {
      form.get("service")?.patchValue(service);
    }
    //
    const formArray = form.get("additonalServices") as FormArray;
    formArray.clear(); // Clear the array if it was initialized before
    bundle.ancillaryServiceList.forEach((product) => {
      const data = { ...product, checked: false };
      formArray.push(new FormControl(data));
    });
    const quoteState = Object.assign({}, this.quoteState(), {
      isEdit: false,
    });
    this.setQuoteState(quoteState);
    return form;
  }

  checkForExistingContact(
    formValues,
    savedContacts: GetContactModel[]
  ): Observable<DialogResult | null> {
    const existingContact = savedContacts.find(
      (contact) => contact.email === formValues.toLowerCase()
    );

    if (existingContact) {
      const dialogRef = this.dialog.open(SiteAddressExistPopupComponent, {
        disableClose: true,
        data: "contact",
      });

      return dialogRef.afterClosed().pipe(
        map((result) => ({
          dialogData: result,
          contact: existingContact as GetContactModel,
        }))
      );
    } else {
      return of(null); // Return an observable with null if no existing contact is found
    }
  }

  // calculate the date
  dateCalc = (count: number, date: Date = new Date()) => {
    const ogDate = date;
    const changedDate = ogDate.setDate(ogDate.getDate() + count);

    return new Date(changedDate);
  };

  // check the day of the week
  checkDayOfWeek(selectedDate: Date) {
    const date = new Date(selectedDate);
    const day = date.getDay();
    if (day === 0 || day === 6) {
      this.dialog.open(WeekendDayValidationPopupComponent, {
        panelClass: "limit-exceed-dialog",
        data: {
          isEditedData: "yes",
        },
      });
    }
  }
  //
  saveDraftReqBodyMapper() {
    const productDetails: ProductRequestModel[] = [];
    this.quoteState().selectedProducts?.forEach((product: SelectedProduct) => {
      let sku: Record<string, ProdcutOption> | undefined =
        product.bundle?.productOptions?.reduce((acc, option) => {
          acc[option.product.productCode] = option;
          return acc;
        }, {});
      const productDetail: ProductRequestModel = {
        productIdBundle: product.bundle?.id || "",
        productOptionSkuBundle: "",
        bundleSummery: product.bundle?.name || "",
        bundleQty: product.quantity,
        productIdService: product.service
          ? sku?.[product.service.productCode]?.productId || ""
          : "",
        productOptionSkuService: product.service
          ? sku?.[product.service.productCode]?.id || ""
          : "",
        serviceSummery: product.service?.description || "",
        productIdAsset: product.asset
          ? sku?.[product.asset.productCode]?.productId || ""
          : "",
        productOptionSkuAsset: product.asset
          ? sku?.[product.asset.productCode]?.id || ""
          : "",
        assetSummary: product.asset?.description || "",
        additionalProduct: this.getadditonalServiceReq(product, sku),
      };
      productDetails.push(productDetail);
    });

    const saveDraftReq = {
      quoteId: this.quoteState().quoteId,
      accountId: this.profileService.selectedAccount().accountId,
      contactId: this.profileService.selectedAccount().contactId,
      startDate: this.quoteState().startDate,
      endDate: this.quoteState().endDate,
      zipcode: this.quoteState().shippingAddress?.zipcode,
      customerType: this.profileService.selectedAccount().customerType,
      businessType: this.profileService.selectedAccount().businessType,
      prodSubType: prodSubType,
      addressId: this.quoteState().shippingAddress?.id,
      estimatedEndDate: this.quoteState().estimatedEndDate || "",
      productDetails,
    };

    return saveDraftReq;
  }
  //
  getadditonalServiceReq(
    product: SelectedProduct,
    sku: Record<string, ProdcutOption> | undefined
  ) {
    const add: additonalServiceReq[] = [];
    product.additonalServices?.forEach((addService: PlytixProduct) => {
      if (addService.checked) {
        add.push({
          productIdAS: sku?.[addService.productCode]?.productId || "",
          productOptionSkuAS: sku?.[addService.productCode]?.id || "",
          aSSummery: addService.description,
        });
      }
    });
    return add;
  }
  //save the draft
  saveDraft() {
    const saveDraftReq = this.saveDraftReqBodyMapper();
    return this.api.post("quotes/update-quote", saveDraftReq, {}, "v2").pipe(
      map((res) => {
        if (res["status"] === 1000) {
          this.isDraftSaved = true;
          return res["data"];
        }
        return null;
      }),
      catchError((err) => {
        // this.toastr.error("Something went wrong.");
        console.error(`error saving draft: ${JSON.stringify(err)}`);
        throw err;
        // return of(null);
      })
    );
  }
  //generates Quote document
  generateDocument() {
    const requestId=localStorage.getItem("generateDocumentId")?localStorage.getItem("generateDocumentId"):this.generateUUID();
    localStorage.setItem('generateDocumentId',`${requestId}`)
    const endPoint = this.configService.getConfigProperty("QUOTE_HTML")
      ? "quote-html"
      : "document";
    return this.api
      .post(
        `quotes/${
          this.quoteState().quoteId
        }/${endPoint}?quotehtml=${this.configService.getConfigProperty(
          "QUOTE_HTML"
        )}`,
        {
          requestId
        }
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000 || res["status"] === 1018) {
            return res;
          }
          return null;
        }),
        catchError((err) => {
          // this.toastr.error("Something went wrong.");
          localStorage.removeItem("generateDocumentId");
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
          // return of(null);
        })
      );
  }
  //generates unique id
  generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  // generate the quote request mapper
  createQuoteRequestMapper(
    quoteDetailsFormGroup: FormGroup<QuoteDetailsFormGroupModel>
  ) {
    let quoteRequest = {};
    quoteDetailsFormGroup.patchValue({
      contactExist: quoteDetailsFormGroup.value.contact?.contactId
        ? true
        : false,
      endDate: this.pipe.transform(
        quoteDetailsFormGroup.value.endDate,
        "yyyy-MM-dd"
      ) as string,
    });
    let endDate = moment(quoteDetailsFormGroup.value.endDate);
    let startDate = moment(quoteDetailsFormGroup.value.startDate);
    let diffInMonths = Math.abs(startDate.diff(endDate, "month"));
    let durationInMonths = getDurationCategory(diffInMonths);

    if (this.quoteState().shippingAddress == undefined) {
      const address = this._projectService.addressSignal();
      quoteDetailsFormGroup.patchValue({
        address: { ...address, addressExist: address.addressId ? true : false },
        zipcode: address.zipcode,
        addressExist: address.addressId ? true : false,
        addressId: address.addressId,
      });
    }

    quoteRequest = {
      id: this.quoteState().quoteId
        ? (this.quoteState().quoteId as string)
        : "",
      requestId: this.generateUUID(),
      accountId: this.profileService.selectedAccount().accountId,
      startDate: this.pipe.transform(
        quoteDetailsFormGroup.value.startDate,
        "yyyy-MM-dd"
      ) as string,
      zipcode: quoteDetailsFormGroup.value.zipcode,
      customerType: this.profileService.selectedAccount().customerType,
      businessType: this.profileService.selectedAccount().businessType,
      estimatedEndDate:
        quoteDetailsFormGroup.value.orderType === "Recurring Service"
          ? ""
          : quoteDetailsFormGroup.value.endDate,
      endDate:
        quoteDetailsFormGroup.value.orderType === "Recurring Service"
          ? quoteDetailsFormGroup.value.endDate
          : "2049-12-31",
      quoteName: quoteDetailsFormGroup.value.quoteName,
      projectId: quoteDetailsFormGroup.value.projectId,
      addressId: quoteDetailsFormGroup.value.addressId,
      contactId: quoteDetailsFormGroup.value.contactId,
      orderType: quoteDetailsFormGroup.value.orderType,
      duration:
        quoteDetailsFormGroup.value.orderType === "Recurring Service"
          ? ""
          : durationInMonths,
      addressExist: quoteDetailsFormGroup.value.addressExist,
      contactExist: quoteDetailsFormGroup.value.contactExist,
      contact: quoteDetailsFormGroup.value.contactExist
        ? {}
        : quoteDetailsFormGroup.value.contact,
      address: quoteDetailsFormGroup.value.addressExist
        ? {}
        : quoteDetailsFormGroup.value.address,
    };
    return quoteRequest;
  }

  // create quote function
  createQuote(
    quoteDetailsFormGroup: FormGroup<QuoteDetailsFormGroupModel>,
    isRefreshed: boolean = false
  ) {
    this.isQuoteCreationInProgress=true
    let createQuoteRequest;
    if (isRefreshed) {
      const createQuotePayload = localStorage.getItem("createQuotePayload");
      if (createQuotePayload) {
        createQuoteRequest = JSON.parse(createQuotePayload);
      }
    } else {
      createQuoteRequest = this.createQuoteRequestMapper(quoteDetailsFormGroup);
      localStorage.setItem("requestIdV2", createQuoteRequest.requestId);
      localStorage.setItem(
        "createQuotePayload",
        JSON.stringify(createQuoteRequest)
      );
    }

    return this.api
      .post("quotes/create-quote", createQuoteRequest, {}, "v2")
      .pipe(
        map((res) => {
          if (res["status"] === 1000 || res["status"] === 1018) {
            if(res["status"]===1000)  this.isQuoteCreationInProgress=false
            return res;
          }
         
          return null;
        }),
        catchError((err) => {
          this.isQuoteCreationInProgress=false
          console.error(`error creating quote: ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }
  //check if the limit is exceeded
  islimitExceed() {
    const qty = (this.quoteState().selectedProducts || []).reduce(
      (acc: number, product: SelectedProduct) => (acc += product.quantity),
      0
    );
    if (qty > perQuoteUnitLimit) {
      this.dialog.open(LimitExceedComponent, {
        width: "430px",
        panelClass: "limit-exceed-dialog",
      });
    }
    return qty > perQuoteUnitLimit;
  }
  //* get the document id
  getDocumentID(quoteId: string, requestId: string): Observable<any> {
    return this.api
      .post(
        `quotes/${quoteId}/document?quotehtml=${this.configService.getConfigProperty(
          "QUOTE_HTML"
        )}`,

        { requestId }
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res;
          } else {
            return null;
          }
        }),
        catchError((err) => {
          console.error(`error getting documentID: ${JSON.stringify(err)}`);
          return of(null);
        })
      );
  }

  onAcceptAndReject(data: PDFAcceptAndReject) {
    return this.api.post("quotes/update-status", data).pipe(
      map((res: AcceptAndRejectResponse) => {
        if (res) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error getting: ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }

  getPDF(quoteId: string, documentId: string) {
    return this.api
      .getPdfBlob(`quotes/${quoteId}/documents/${documentId}`)

      .pipe(
        map((res) => {
          if (res) {
            const mediaType = "application/pdf";
            const blob = new Blob([res], { type: mediaType });

            return blob;
          }
          return;
        }),
        catchError((err) => {
          console.error(`error getting quote PDF: ${JSON.stringify(err)}`);
          return of(null);
        })
      );
  }

  //reset the quote state
  resetQuoteState() {
    this.quoteState.set(new QuoteModelDetailsV2());
  }

  //prepare the bundle data
  prepareBundleData(quoteLines: QuoteLineModel[]): QuoteLineModel[] {
    //it will filter out the bundle, asset, service and ancillary data
    const filteredBundleList = quoteLines?.filter(
      (lineItem: QuoteLineModel) =>
        lineItem["productType"] === "Bundle" ||
        lineItem["productType"] === "Asset" ||
        lineItem["productType"] === "Service" ||
        lineItem["productType"].includes("Ancillary")
    );

    const bundleList = this.groupByBundleData(filteredBundleList);

    return bundleList;
  }
  //group by bundle data
  groupByBundleData(bundleList: QuoteLineModel[]): QuoteLineModel[] {
    //get only bundle data from bundleList
    const bundleData = bundleList.filter(
      (lineItem: {}) => lineItem["productType"] === "Bundle"
    );

    const formattedBundleList = bundleData.map((item, index) => {
      return {
        ...item,
        isSelected: index === 0 ? true : false,
        expandFlag: index === 0 ? true : false,
        assetList: bundleList.filter(
          (assetItem) =>
            assetItem["requiredBy"] === item["quoteLineId"] &&
            assetItem["productType"] === "Asset"
        ),
        services: bundleList.filter(
          (service) =>
            service["requiredBy"] === item["quoteLineId"] &&
            service["productType"] === "Service"
        ),
        additionalServices: bundleList.filter(
          (additionalService) =>
            additionalService["requiredBy"] === item["quoteLineId"] &&
            additionalService["productType"].includes("Ancillary")
        ),
      };
    });

    return formattedBundleList;
  }
  //load the current quote from API
  async loadCurrentQuoteFromAPI(quoteId: string) {
    await firstValueFrom(this.getPlytixProducts());

    //this.resetState();
    this.getQuoteData(quoteId).subscribe((result) => {
      if (result) {
        const bundleData = this.prepareBundleData(result.quoteModel.quoteLines);
        console.log("bundleDataGrouped", bundleData);
        let selectedProducts = this.setSelectedProducts(
          bundleData,
          this.rawProducts
        );
        if (selectedProducts.length > 0) {
          this.isDraftSaved = true;
        }else{
          selectedProducts=this.quoteState().selectedProducts
        }
        const quote = Object.assign({}, result.quoteModel, {
          selectedProducts,
          selectedproductsFamily: ["Sanitation"],
        });

        this.quoteState.set(quote);
        if (result.quoteModel?.jobSites?.length > 0) {
          this.jobSitesToBundleData(result.quoteModel, bundleData, true);
        }

        if (
          this.profileService.isAccountInActive() ||
          this.profileService.isReadOnly()
        ) {
          return;
        }
        const currentDate = new Date();
        const newDate = currentDate.setDate(currentDate.getDate() + 1);
        const tomorrowDate = new Date(newDate).toISOString().split("T")[0];
        const startDate = new Date(this.quoteState().startDate)
          .toISOString()
          .split("T")[0];
        if (
          tomorrowDate < startDate === false &&
          quote.currentStatus > 0 &&
          quote.currentStatus < 7
        ) {
          this.dialog.open(SurpassedDatePopupComponent, {
            minWidth: "30%",
            disableClose: true,
            data: {
              isEnhanceQuote: true,
              showBackButton: quote.currentStatus > 2,
              showChangeDateButton: quote.currentStatus < 3,
            },
          });
        }
      }
    });
  }

  getQuoteData(qouteId: string) {
    return this.api.get(`quotes/${qouteId}`, {}, "v2").pipe(
      map((res) => {
        if (res["status"] === 1000) {
          console.log("quote data", res["data"]);
          return res["data"];
        }
      }),
      catchError((err) => {
        console.error(`error requesting quote: ${JSON.stringify(err)}`);
        throw err;
        // return of(null);
      })
    );
  }
  //mapped quote lines to plytixs product and set the selected products in the quote signal
  setSelectedProducts(
    formattedQuoteLines: QuoteLineGrouped[],
    products: PlytixProduct[]
  ) {
    // Create a Map from plytixProduct using the code as the key
    const plytixProductMap = new Map(
      products.map((product) => [product.productCode, product])
    );

    // Find matching items based on the product code
    const matchingItems = formattedQuoteLines.reduce<SelectedProduct[]>(
      (acc, quotelineProduct) => {
        const match = plytixProductMap.get(
          `${quotelineProduct.product.productCode}`
        );
        const getAseet = plytixProductMap.get(
          `${quotelineProduct.assetList?.[0].productCode}`
        );
        const getService = plytixProductMap.get(
          `${quotelineProduct.services?.[0].productCode}`
        );
        if (match) {
          const productCodeOfAdditionalList =
            quotelineProduct.additionalServices?.map(
              (asset) => asset.productCode
            );
          // set checked true to the selected additional services
          const setTrueToSelectedAdditionalServices =
            match.ancillaryServiceList.map((additionalService) => {
              const selectedAdditonalService = Object.assign(
                {},
                additionalService
              );
              if (
                productCodeOfAdditionalList?.includes(
                  additionalService.productCode
                )
              ) {
                selectedAdditonalService.checked = true;
                if (
                  additionalService.productCode === "113-1801" &&
                  match.productCode === "110-0000" &&
                  this.quoteState().containmentTrayRequired
                ) {
                  selectedAdditonalService.disabled = true;
                }
                return selectedAdditonalService;
              }
              return selectedAdditonalService;
            });
          match.ancillaryServiceList = setTrueToSelectedAdditionalServices;
          match.serviceList;
          const selectedProduct: SelectedProduct = {
            bundle: match,
            asset: getAseet || null,
            service: getService || null,
            quantity: quotelineProduct.quantity,
            additonalServices: match.ancillaryServiceList,
          };

          acc.push(selectedProduct);
        }
        return acc;
      },
      []
    );
    return matchingItems;
  }

  //set step one data
  setStepOneData() {
    const QuoteDetails = {
      quoteName: this.quoteState().quoteName,
      projectId: this.quoteState().projectDetails?.id || "",
      startDate: this.quoteState().startDate,
      endDate: this.quoteState().endDate,
      zipcode: this.quoteState().shippingAddress?.zipcode,
      addressExist: true,
      addressId: this.quoteState().shippingAddress?.id,
      accountId: this.profileService.selectedAccount().accountId,
      customerType: this.profileService.selectedAccount().businessType,
      businessType: this.profileService.selectedAccount().businessType,
      quoteType: "",
      orderType: "",
      duration: this.quoteState().duration,
      address: this.quoteState().shippingAddress,
      contactExist: true,
      contactId: this.quoteState().primaryContact?.id,
      contact: {
        firstName: this.quoteState().primaryContact?.firstName,
        lastName: this.quoteState().primaryContact?.lastName,
        email: this.quoteState().primaryContact?.email,
        phone: this.quoteState().primaryContact?.phone,
        contactId: this.quoteState().primaryContact?.id,
      },
      estimatedEndDate: this.quoteState().estimatedEndDate,
    };
    // this.contactDropDown.value= test.contact;
    QuoteDetails.quoteType = "Recurring Service";
    if (this.quoteState().estimatedEndDate) {
      QuoteDetails.orderType = "Recurring without End Date";
      QuoteDetails.endDate = this.quoteState().estimatedEndDate;
    } else {
      QuoteDetails.orderType = "Recurring Service";
    }

    return QuoteDetails;
  }

  //Confirm Quote APi
  confirmQuote(reqBody: ConfirmQuoteModel) {
    // console.log("reqBody--->", reqBody);
    // this.loadingService.setLoader(true);
    const resp = this.api.post(`quotes/confirm-quote`, reqBody).pipe(
      map((res) => {
        console.log("res--->");
        if (res["status"] === 1000 || res["status"] === 1018) {
          if (res["status"] === 1000) {
            const box = this.dialog.open(ConfirmationPopupComponent, {
              minWidth: "30%",
              panelClass: "dialogbox",
              data: {
                success: true,
                isInactiveProject: reqBody.projectStatus === "Inactive",
              },
              disableClose: true,
              // height: "30%",
            });
            box.afterClosed().subscribe((resp) => {
              if (resp) {
                // this.stepperService.stepperBehaviourSubject.next(null)
                // history.pushState(null, document.title, window.location.href);
                this.router.navigateByUrl("/orders");
              }
            });
          }
          this.loadingService.setLoader(false);
          return res;
        }
        return null;
      }),
      catchError((err) => {
        const box = this.dialog.open(ConfirmationPopupComponent, {
          minWidth: "30%",
          panelClass: "dialogbox",
          data: false,
          // height: "30%",
        });
        box.afterClosed().subscribe((resp) => {
          if (!resp) {
            this.quoteHasError$.next(false);
          }
        });
        localStorage.removeItem("confirmQuoteId");
        this.loadingService.setLoader(false);
        throw err;
      })
    );

    return resp;
  }

  /**
   * @description function to create job site
   */
  createJobSite(
    bundleData,
    siteInformation: FormGroup<SiteInformationFormModel>
  ) {
    return bundleData.map((el) => {
      const ancillaryServiceList = this.additionalServices(
        el.additionalServices
      );

      return {
        id: "",
        name: "",
        placedProductName: "",
        address: {
          ...this.quoteState().shippingAddress,
          ...siteInformation.value,
          startTime: siteInformation.value.startTime
            ? siteInformation.value.startTime + ":00.125Z"
            : "",
          endTime: siteInformation.value.endTime
            ? siteInformation.value.endTime + ":00.125Z"
            : "",
          instructions: siteInformation.value.placementNotes,
          placementNotes: siteInformation.value.placementNotes,
        },
        contact: this.quoteState().primaryContact,
        contactExist: this.quoteState().primaryContact.id ? true : false,
        contactRefId: this.quoteState().primaryContact.id,
        quantityQuoted: el.quantity,
        quoteLineId: el.quoteLineId,
        productDetails: {
          bundleId: el.product.id,
          assetId: el.assetList ? el.assetList[0].product.id : "",
          serviceId: el.services ? el.services[0].product.id : "",
          bundleName: el.product.name,
          assetName: el.assetList ? el.assetList[0].product.assetSummary : "",
          serviceName: el.services ? el.services[0].product.description : "",
          ancillaryServiceList: ancillaryServiceList,
          quantity: el.quantity,
        },
      };
    });
  }

  additionalServices(additionalServices) {
    return additionalServices.map((el) => {
      return {
        ancillaryServiceId: el.product.id,
        ancillaryServiceName: el.product.description,
      };
    });
  }

  addUpdateLocation(
    siteInformation: FormGroup<SiteInformationFormModel>,
    addLocationFlag: boolean = true
  ) {
    const data: AddLocation = {
      id: addLocationFlag ? "" : this.quoteState().jobSites[0].address.id + "",
      contactId: this.quoteState().primaryContact.id || "",
      contactExist: this.quoteState().primaryContact.id ? true : false,
      contact: {
        contactId: this.quoteState().primaryContact.id || "",
        accountId: this.profileService.selectedAccount().accountId,
        firstName: this.quoteState().primaryContact.firstName,
        lastName: this.quoteState().primaryContact.lastName,
        email: this.quoteState().primaryContact.email,
        phone: this.quoteState().primaryContact.phone,
      },
      address: {
        id: addLocationFlag
          ? ""
          : this.quoteState().jobSites[0].address.id + "",
        accountId: `${this.profileService.selectedAccount().accountId}`,
        city: this.quoteState().shippingAddress.city,
        country: this.quoteState().shippingAddress.country,
        state: this.quoteState().shippingAddress.state,
        street: this.quoteState().shippingAddress.street,
        zipcode: this.quoteState().shippingAddress.zipcode,
        siteName: this.quoteState().shippingAddress.street,
        instructions: siteInformation.value.placementNotes as string,
        startTime: siteInformation.value.startTime
          ? siteInformation.value.startTime + ":00.125Z"
          : "",
        endTime: siteInformation.value.endTime
          ? siteInformation.value.endTime + ":00.125Z"
          : "",
        shipToAddress: true,
        parentRefId: this.quoteState().shippingAddress.id,
        latitude: this.quoteState().shippingAddress.latitude,
        longitude: this.quoteState().shippingAddress.longitude,
      },
    };
    if (addLocationFlag) {
      return this.accountService.addLocation(data);
    } else {
      return this.accountService.updateLocation(data);
    }
  }

  sitedetailsMapper(
    siteInformation: FormGroup<SiteInformationFormModel>,
    contactFormGroup: FormGroup<ContactFormModel>,
    bundleData,
    jobSite
  ): RequestSiteDetails {
    const address = {
      ...this.quoteState().shippingAddress,
      ...siteInformation.value,
      startTime: siteInformation.value.startTime
        ? siteInformation.value.startTime + ":00.125Z"
        : "",
      endTime: siteInformation.value.endTime
        ? siteInformation.value.endTime + ":00.125Z"
        : "",
      instructions: siteInformation.value.placementNotes,
      addressId: this.quoteState().shippingAddress.id,
      addressExist: this.quoteState().shippingAddress.id ? true : false,
    };
    delete address["selectLocationType"];
    const subSite = this.prepareSubsiteData(bundleData, jobSite);
    const requestId= localStorage.getItem('siteDetailsId')?`${localStorage.getItem('siteDetailsId')}`: this.generateUUID();
    localStorage.setItem('siteDetailsId',requestId);
    const siteDetails: RequestSiteDetails = {
      requestId,
      ussPortalUserId: this.profileService.userProfile$.value
        ?.ussPortalUserId as string,
      startDate: this.pipe.transform(
        this.quoteState().startDate,
        "yyyy-MM-dd"
      ) as string,

      endDate: this.pipe.transform(
        this.quoteState().endDate,
        "yyyy-MM-dd"
      ) as string,
      contactData: contactFormGroup.value as Contact,
      addressData: address as Address,
      quoteId: this.quoteState().quoteId as string,
      contactId: contactFormGroup.value.contactId || "",
      addressId: this.quoteState().shippingAddress.id as string,
      contactExist: !!contactFormGroup.value?.contactId,
      subSites: [subSite],
      contactRefId: "",
      addressRefId: "",
    };

    return siteDetails;
  }

  prepareSubsiteData(bundleData, jobSite) {
    let bundle = bundleData.map((el) => {
      return {
        bundleId: el.product.id,
        bundleName:
          el.product.name +
          ` ${el.services ? el.services[0].product.description : ""}`,
        assetId: el.assetList ? el.assetList[0].product.id : "",
        assetName: el.assetList ? el.assetList[0].product.assetSummary : "",
        serviceId: el.services ? el.services[0].product.id : "",
        serviceName: el.services ? el.services[0].product.description : "",
        quantity: el.quantity,
        quoteLineId: el.quoteLineId,
      };
    });
    return {
      addressId: jobSite.address.id
        ? jobSite.address.id
        : this.quoteState().jobSites[0].address.id,
      siteName: jobSite.address.siteName,
      bundles: bundle,
    };
  }

  jobSitesToBundleData(quoteState: QuoteModelDetailsV2, bundleData, updateQuantityFlag: boolean = false) {
    this.quoteStateService.multiLocationData.set([] as QuoteLineGrouped[]);

    const groupedLocations = quoteState.jobSites.reduce(
      (groupedLocations, jobSite) => {
        const addressId = jobSite.address.id || "";
        if (groupedLocations[addressId]) {
          groupedLocations[addressId].push(jobSite);
        } else {
          groupedLocations[addressId] = [jobSite];
        }
        return groupedLocations;
      },
      {}
    );
    Object.keys(groupedLocations).forEach((locationId: string) => {
      bundleData.forEach((item: JobSiteLocation) => {
        item[`newLocation${locationId}`] = {
          quantityQuoted: 0,
          columnId: `newLocation${locationId}`,
          address: groupedLocations[locationId][0].address,
          contact: groupedLocations[locationId][0].contact,
        };
      });
    });

    if (
      groupedLocations &&
      Object.keys(groupedLocations).length > 0 &&
      updateQuantityFlag
    ) {
      Object.keys(groupedLocations).forEach((locationId: string) => {
        groupedLocations[locationId].forEach((location: JobSiteLocation) => {
          const index =
            bundleData &&
            bundleData.findIndex(
              (bundle) =>
                bundle.product.id === location.productDetails.bundleId &&
                bundle.quoteLineId === location.quoteLineId
            );
          if (index !== -1 && bundleData[index]) {
            bundleData[index][`newLocation${locationId}`] = {
              ...location,
              quantityQuoted: location.quantityQuoted,
              columnId: `newLocation${locationId}`,
            };
          } else {
            console.error(
              "Could not find a matching bundle for locationId: ${locationId}`"
            );
          }
        });
      });
    }

    // return bundleData;
    this.quoteStateService.multiLocationData.set(bundleData);
  }

  async saveSiteDetailMultipleLocation(bundleData): Promise<RequestSiteDetails | null> {
    let productQuantity = 0;
    let quantityQuoted = 0;

    let locations = {};
    bundleData.forEach((jobSiteBundle: QuoteLineGrouped) => {
      productQuantity += jobSiteBundle.quantity;

      Object.keys(jobSiteBundle).forEach((key: string) => {
        if (key.includes("newLocation")) {
          const bundle = {
            quantity: jobSiteBundle[key].quantityQuoted,
            bundleId: jobSiteBundle.product.id,
            bundleName: `${jobSiteBundle?.assetList![0].product.description}  ${
              jobSiteBundle?.services![0].product.description
            }`,
            assetId: jobSiteBundle?.assetList![0].product.id,
            assetName: jobSiteBundle?.assetList![0].product.description,
            serviceId: jobSiteBundle?.services![0].product.id,
            serviceName: jobSiteBundle?.services![0].product.description,
            quoteLineId: jobSiteBundle.quoteLineId,
          };
          quantityQuoted += jobSiteBundle[key].quantityQuoted;
          if (bundle.quantity === 0) {
            return;
          }
          if (locations[key]) {
            locations[key]?.bundles.push(bundle);
          } else {
            locations[key] = {
              addressId: jobSiteBundle[key].address.id,
              siteName: jobSiteBundle[key].address.siteName,
              // ...jobSiteBundle[key],
              bundles: [bundle],
            };
          }
        }
      });
    });

    if (productQuantity !== quantityQuoted) {
      this.toastr.error(
        "All quantities must be assigned to the added locations."
      );
      return null;
    }
    const contact = {
      firstName: this.quoteState().primaryContact.firstName,
      lastName: this.quoteState().primaryContact.lastName,
      phone: this.quoteState().primaryContact.phone,
      email: this.quoteState().primaryContact.email,
      contactId: this.quoteState().primaryContact.contactId
    }
    const address =  Object.assign({},this.quoteState().shippingAddress,{ startTime:this.quoteState().shippingAddress.startTime? this.quoteState().shippingAddress.startTime : "",endTime:this.quoteState().shippingAddress.endTime?this.quoteState().shippingAddress.endTime  : ""  }
      );
      const requestId= localStorage.getItem('siteDetailsId')?`${localStorage.getItem('siteDetailsId')}`: this.generateUUID();
      localStorage.setItem('siteDetailsId',requestId);
    
    const data = {
      requestId,
      ussPortalUserId: this.profileService.userProfile$.value
        ?.ussPortalUserId as string,
      startDate: this.pipe.transform(
        this.quoteState().startDate,
        "yyyy-MM-dd"
      ) as string,

      endDate: this.pipe.transform(
        this.quoteState().endDate,
        "yyyy-MM-dd"
      ) as string,
      contactData: contact as Contact,
      addressData: address,
      quoteId: this.quoteState().quoteId as string,
      contactId: this.quoteState().primaryContact.id as string,
      addressId: this.quoteState().shippingAddress.id as string,
      contactExist: this.quoteState().primaryContact.id ? true : false,
      subSites: Object.values(locations) as SubSite[],
      contactRefId: "",
      addressRefId: "",
    };

    return data;
  }

  setselectedPaymentCard(paymentMethod: PaymentMethod | undefined) {
    if (paymentMethod) {
      this.selectedPaymentCard.set(paymentMethod);
    }
  }

  updateCommonValues(data: Partial<QuoteModelDetailsV2>) {
    const quoteState = Object.assign({}, this.quoteState(), {
      ...data,
    });
    this.setQuoteState(quoteState);
  }

  // refresh
   intervalSubscription$: Subscription | null = null;
   intervalStop$ = new Subject<void>();

  /**
   * Starts an interval that repeatedly makes an API call.
   * @param apiCall - The API call function.
   * @param intervalTime - The time interval between API calls.
   * @param step - The current step of the process.
   * @returns Subscription - The subscription to the interval observable.
   */
  startInterval(
    apiCall: () => Observable<ApiRespDTO|null>,
    intervalTime: number,
    step: string
  ): Subscription {
    this.intervalSubscription$ = interval(intervalTime)
      .pipe(
        startWith(0),
        takeUntil(this.intervalStop$),
        exhaustMap(() => apiCall())
      )
      .subscribe({
        next: (result) => this.handleResult(result, step),
        // error: (err) => this.handleError(err)
      });

    return this.intervalSubscription$;
  }

  /**
   * Handles the result of the API call.
   * @param result - The API call result.
   * @param step - The current step of the process.
   */
   handleResult(result: ApiRespDTO | null, step: string) {
    if (!result) {
      this.handleError();
      return;
    }

    const { status, data, message } = result;

    if (status === 1000) {
      console.log("cleared");
      if(data.quoteModel){
        this.handleSuccess(step,data.quoteModel);
      }else{
        this.handleSuccess(step,data);
      }
      if(this.inprogressPopup){
        this.inprogressPopup.close();
      }
    } else if (status === 1018) {
      this.handleInfoMessage(message);
    }else{
      this.handleError();

    }
  }
  /**
   * Stops the interval and cleans up subscriptions.
   */
   stopInterval() {
    this.intervalStop$.next();
    this.intervalStop$.complete();
    this.intervalSubscription$?.unsubscribe();
    this.intervalSubscription$ = null;
  }

  handleInfoMessage(message: string) {
    if (!this.progressToastrCalled) {
      this.inprogressPopup=this.dialog.open(InprogressRequestPopUpComponent, {
        minWidth:'35%',
        panelClass: 'inprogress-panel-class'
      })
      document.querySelector('.cdk-overlay-container')?.classList.add('custom-overlay-container-class')

      this.inprogressPopup.afterClosed().subscribe(() => {
        // Remove the custom class when the dialog is closed
        document.querySelector('.cdk-overlay-container')?.classList.remove('custom-overlay-container-class')
      });
      this.progressToastrCalled = true;
    }
  }

  handleError() {
    this.stopInterval();
    if(this.inprogressPopup){
      this.inprogressPopup.close();
    }
    localStorage.removeItem("requestIdV2");
    localStorage.removeItem('createQuotePayload');
    localStorage.removeItem('generateDocumentId');
    localStorage.removeItem('siteDetailsId');
    localStorage.removeItem('siteDetailsPayload');
    localStorage.removeItem("billingDetailsId");
    localStorage.removeItem('billingDetailsPayload');
    
  }

  /**
   * Handles successful API call results based on the step.
   * @param step - The current step of the process.
   * @param data - The data from the API response.
   */
   handleSuccess(step: string, data: QuoteModelDetailsV2) {
    switch (step) {
      case "createQuote":
        localStorage.removeItem("requestIdV2");
        localStorage.removeItem('createQuotePayload')
        this.updateInitialQuoteState(data);
        break;
      case "generateDocument":
        localStorage.removeItem('generateDocumentId')
        this.updateGenerateQuoteState(data);
        break;
      case "siteDetails":
        localStorage.removeItem('siteDetailsId');
        localStorage.removeItem('siteDetailsPayload');
        this.updateSiteDetailsState(data)
        break;
      case "billing":
        localStorage.removeItem("billingDetailsId");
        localStorage.removeItem('billingDetailsPayload')
        this.updateBillingState(data);
        break;
      case "orderConfirmation":
        localStorage.removeItem("orderConfirmationId");
        localStorage.removeItem('orderConfirmationPayload')
        this.updateBillingState(data);
        break;
      default:
        break;
    }
    this.stopInterval();
  }

  updateInitialQuoteState(data: QuoteModelDetailsV2) {
    const quote = Object.assign({}, this.quoteState(), data);
    quote.currentStatus = 1;
    this.setQuoteState(quote);
    this.router.navigate([`/quotes/v2/quote-creation/${quote.quoteId}`]);
  }

  updateGenerateQuoteState(data:QuoteModel){
    const quoteState = Object.assign({}, this.quoteState(), {
      ...data,
      currentStatus: 2,
    });
    this.setQuoteState(quoteState);
    this
    .getDocumentID(
      this.quoteState().quoteId,
      this.generateUUID()
    )
    .subscribe((res) => {
      if (res) {
        const quoteState = Object.assign(
          {},
          this.quoteState(),
          {
            quoteDocuments: [
              {
                documentId: res["data"].documentId,

                documentName: res["data"].documentName,
                version: "",
              },
            ],
          }
        );
        this.setQuoteState(quoteState);
      }
    });
  }


  updateSiteDetailsState(data:QuoteModel){
    const quoteState = Object.assign(
      {},
      this.quoteState(),
      data,
      { currentStatus: 4 }
    );
    this.setQuoteState(quoteState);
  }

  updateBillingState(data:QuoteModel){
    const quoteState = Object.assign(
      {},
      this.quoteState(),
      data,
      { currentStatus: 5 }
    );
    this.setQuoteState(quoteState);
  
  }




  sanitizePhoneInput(formGroup: FormGroup, controlName: string, value: string) {
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    formGroup.controls[controlName].patchValue(
      { phone: sanitizedValue },
      { emitEvent: false, onlySelf: true }
    );
  }
}
