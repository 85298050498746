import { Component, Input, ViewChild, effect } from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { Contracts, LatLng } from "src/app/models/order-model";
import { HomeService } from "src/app/features/home/services/home.service";
import { Router } from "@angular/router";
import { OrderService } from "src/app/features/orders/services/order.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

@Component({
  selector: "app-contractslocation",
  templateUrl: "./contractslocation.component.html",
  styleUrls: ["./contractslocation.component.scss"],
})
export class ContractslocationComponent {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  @Input() showLabel: boolean = true;
  latlngbounds = new google.maps.LatLngBounds();
  infoWindowPosition: google.maps.LatLngLiteral | LatLng;
  shippingAddress: String | undefined;
  zipcode: String | undefined;
  data: Contracts[] | undefined;
  orderAndContractIds: { orderId: string; contractId: string }[] = [];
  userId: string;

  constructor(
    private homeService: HomeService,
    private _router: Router,
    private orderService: OrderService,
    private profileService: ProfileService
  ) {
    effect(() => {
      const account = this.profileService.selectedAccount();

      if (account.accountId != this.userId) {
        this.userId = `${account.accountId}`;
        this.getContracts(this.userId, "Activated,Suspended,Draft");
      }
    });
  }

  options: google.maps.MapOptions = {
    center: { lat: 37.0902, lng: -95.7129 }, // Set to the approximate center of the United States
    zoom: 4, // Adjust the zoom level as needed
    maxZoom: 18,
    clickableIcons: true,
  };

  async ngOnInit() {
    // map style from json file
    this.options.styles = await this.homeService.loadMapStyle();

    // this.getContracts("Activated,Suspended,Draft");
  }

  getContracts(userId: string, filter: string) {
    this.orderService.getAllContract(filter).subscribe((res) => {
      if (res) {
        this.data = res;
      }
      if(this.data){
        let bounds = this.getBounds(this.data);
        /* bounds for map (dynamic center as per order location) */
        if (this.map && this.map.googleMap && (bounds.east || bounds.west || bounds.north || bounds.south)) {
          this.map.googleMap.fitBounds(this.getBounds(this.data));
        }
      }
    });
  }

  /* Bounds for map (Determine the center point of the map view based on the locations of pins)*/
  getBounds(markers: Contracts[]) {
    let north;
    let south;
    let east;
    let west;
    if (markers?.length > 0) {
      for (const marker of markers) {
        if(marker.shippingAddressLat || marker.shippingAddressLng){
        north =
          north !== undefined
            ? Math.max(north, marker.shippingAddressLat)
            : marker.shippingAddressLat;
        south =
          south !== undefined
            ? Math.min(south, marker.shippingAddressLat)
            : marker.shippingAddressLat;
        east =
          east !== undefined
            ? Math.max(east, marker.shippingAddressLng)
            : marker.shippingAddressLng;
        west =
          west !== undefined
            ? Math.min(west, marker.shippingAddressLng)
            : marker.shippingAddressLng;
      }
    }
    }
    const bounds = { north, south, east, west };
    return bounds;
  }


  openInfoWindow(marker: MapMarker, markerData: Contracts) {
    const ordersWithSameAddress = this.data?.filter(
      (order) =>
        order.shippingAddressLat === markerData.shippingAddressLat &&
        order.shippingAddressLng === markerData.shippingAddressLng
    );

    if (this.infoWindow != undefined && ordersWithSameAddress) {
      this.infoWindow.open(marker);
      this.shippingAddress = markerData.shippingAddress;

      // Combine order IDs and contract IDs into a single list
      this.orderAndContractIds = ordersWithSameAddress.map((order) => ({
        orderId: order.name,
        contractId: order.contractId,
      }));
    }
  }
  openOrderSummary(contractId: string): void {
    // Find the corresponding contract ID based on the selected order ID
    const selectedOrder = this.orderAndContractIds.find(
      (entry) => entry.contractId === contractId
    );
    if (selectedOrder) {
      // Navigate to the order summary page with the contract ID as a parameter
      this._router.navigate(["orders/ordersummary", selectedOrder.contractId]);
    }
  }
}

