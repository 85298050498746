<!-- <div class="background div_height"></div> -->

<section
  fxLayout="row wrap"
  fxLayout.xs="column-reverse"
  fxLayout.sm="column-reverse"
  class="white_bg"
>
  <div
    fxFlex="65.5"
    fxFlex.sm="100"
    fxFlex.md="100"
    fxFlex.xs="100"
    class="div_height border_radius_10"
    fxLayoutAlign="center center"
  >
  <!-- [ngClass]="{ div_height_2: comingSoonRoute }" -->
    <div class="white_bg div_height div_padding" fxLayoutAlign="center center">
      <div>
        <img
          src="../../../assets/img/coming_soon_2.png"
          alt=""
          class="coming_soon_2"
        />
        <div class="text_width pb-6">
          <p class="py-2 text_1">
            We're dedicated to enhancing our operations to ensure an Easy, Safe
            and Clean experience. As part of this commitment, we're introducing
            new customer-facing technology options in 2024.
          </p>
          <p class="py-2 text_1">
            In the meantime, please reach out to our experts at
            <span>
              <a href="tel:{{ phoneNumber }}" class="phone-number text_color">
                {{ phoneNumber }}.
              </a>
            </span> or visit our
            <a href="https://www.unitedsiteservices.com/" class="text_color"
              >website</a
            >
            to discuss your project needs.
          </p>
          <p class="py-2 text_2">
            Stay tuned for an even better USS experience in 2024!
          </p>
        </div>
        <!-- <button class="round_btn button" mat-raised-button color="primary" (click)="signIn()">Sign In</button> -->
      </div>
    </div>
  </div>

  <!-- <div image > -->
  <div fxFlex="33" fxFlex.md="0" fxLayoutAlign="center center" class="div_img">
    <!-- <img src="../../../assets/img/coming_soon.png" alt="" width="100%"> -->
  </div>
  <!-- </div> -->
</section>
