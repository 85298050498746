<!-- showing quote name -->
<div fxLayoutAlign="start center">
  <div
    *ngIf="showBackButton() && showBackBtn"
    class="back-container"
    (click)="backToPreviousStep()"
  >
    <mat-icon class="back-icon">keyboard_arrow_left</mat-icon>
    <span class="back-text">Back</span>
  </div>
  <div
    class="quote-container"
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <p class="quote_Name">Quote: {{ currentValue?.quoteName }}</p>
    <p *ngIf="projectEnabled && myussModules  ?.myussProjectsEnabled">{{currentValue?.quoteModel?.projectDetails?.name}}</p>
  </div>
</div>
