import { Component, Input } from '@angular/core';
import { phoneNumber } from 'src/assets/config/constants';

@Component({
  selector: 'app-enhance-quote-tool-tip',
  templateUrl: './enhance-quote-tool-tip.component.html',
  styleUrls: ['./enhance-quote-tool-tip.component.scss']
})
export class EnhanceQuoteToolTipComponent {
  @Input() background: string = '#00539B';
  @Input() color: string = '#FFFFFF';
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() fontSize: string = '';
  phoneNumber: string;


  ngOnInit(): void {
    this.phoneNumber = phoneNumber;
  }


}
