import { Platform } from '@ionic/angular';
import { DatePipe } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatRadioChange } from "@angular/material/radio";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Address } from "src/app/models/address-model";
import {
  AncillaryService,
  Asset,
  BundlesForm,
  ProductModel,
  QuoteForm,
  Service,
} from "src/app/models/product-model";
import {
  Products,
  StandardQuotes,
} from "src/app/models/quote-main-screen-model";
import {
  updateQuoteSummaryReq,
  updateQuoteSummaryRes,
} from "src/app/models/unit-and-services-model";

import { LoadingService } from "src/app/shared/services/loading/loading.service";
import { Subscription, distinctUntilChanged, filter, firstValueFrom } from "rxjs";
import { RfqService } from "src/app/features/landing/services/rfq/rfq.service";
import { MatDialog } from "@angular/material/dialog";
import { LimitExceedComponent } from "../dialogbox/limit-exceed/limit-exceed.component";
import { QuoteCreationService } from "src/app/features/quotes/services/quote-creation.service";
import { ZipcodeService } from "src/app/features/myuss/services/zipcode/zipcode.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { QuoteState } from "src/app/models/quoteState-model";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { RFQProduct } from "src/app/models/rfq-model";
import { durationPicklistConstant, getDurationCategory} from "src/assets/config/duration-picklist";
import moment from 'moment'
import { descriptionTableHeader, perQuoteUnitLimit, timeZoneMapper } from "src/assets/config/constants";
import { OrderModel, QuoteSummary } from "src/app/models/order-model";
import { OrderService } from "src/app/features/orders/services/order.service";
import { tr } from "date-fns/locale";
import { CaseProductInfo, CaseModel } from "src/app/models/case-details-model";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { CaseDetailsStateService } from "src/app/features/cases/services/case-details-state.service";
import { GoogleMapsService } from "../../services/google/google-maps.service";
import { CaseSuccessComponent } from "../dialogbox/case-success/case-success.component";
import { QuoteMainScreenService } from "src/app/features/quotes/services/quote-main-screen.service";
import { WeekendDayValidationPopupComponent } from "../dialogbox/weekend-day-validation-popup/weekend-day-validation-popup.component";
import { FixedButtonModel, FixedButtonService } from "src/app/features/quotes/services/fixed-button.service";
import { fixedDateMessage } from 'src/assets/config/constants';


@Component({
  selector: "app-unit-and-services",
  templateUrl: "./unit-and-services.component.html",
  styleUrls: ["./unit-and-services.component.scss"],
})
export class UnitAndServicesComponent implements OnInit {
  @Input() screen: string = "quotes";
  currentState: QuoteState;
  standardQuotesList: StandardQuotes[];

  loader: boolean = false;
  bundleFormGroup: FormGroup;
  quoteForm: FormGroup;
  selectedBundleArray: ProductModel[] = [];
  duration: boolean = true;
  bundles: ProductModel[];
  // for mobile screen
  isMobile: boolean;
  // min value for date
  startDateMin: Date;
  endDateMin: Date;
  //date pipe
  pipe = new DatePipe("en-US");

  label: string;
  subscribe: Subscription;
  isPrefilledProducts: boolean = false;

  // duration picklist
  durationPicklist: string[] = [];

  // Unit limit
  perQuoteUnitLimit: number;

  isLoading: boolean = false;
  radioBtnForDateAndDuration = new FormControl(true);

  //edit screen
  showSideDrawer: boolean = false;
  caseData: {}

  quoteSummary: QuoteForm = {
    startDate: null,
    endDate: null,
    deliveryDate: null,
    duration: "",
    orderType: "",
    bundles: [
      {
        productIdBundle: "",
        additionalProduct: null,
        productIdAsset: null,
        bundleName: "",
        bundleProductCode: "",
        bundleQty: 0,
        productIdService: null,
      },
    ],
  };
  isUpdatedQuoteSummary: boolean = false;

  @Output() nextStep2 = new EventEmitter<any>();

  addProductFormControl = new FormControl();

  address: Address;
  addressExist: boolean;
  addressId: string;
  standardQuotesData: StandardQuotes | null = null;

  qouteStateSubscription: Subscription;
  intervalSubscription$: Subscription = new Subscription();
  orderData: OrderModel;
  // deliveryDate = new FormControl(["",[Validators.required]]);
  deliveryDateValue:string;
  drawerMobileFlag: boolean = false;
  isNative: boolean = false;
  fixedDateMessage:string

  constructor(
    private route: ActivatedRoute,
    public quoteCreationService: QuoteCreationService,
    private zipCodeService: ZipcodeService,
    public fb: FormBuilder,
    public toastr: ToastrService,
    private _router: Router,
    private loadingService: LoadingService,
    private rfqService: RfqService,
    public dialog: MatDialog,
    public quoteStateService: QuoteStateService,
    private configService: ConfigService,
    private orderService: OrderService,
    public profileService: ProfileService,
    public quoteMainScreenService: QuoteMainScreenService,
    private caseService: CaseDetailsStateService,
    private gMapService: GoogleMapsService,
    private fixedButtonService: FixedButtonService,
    private platform: Platform
  ) {
    this.isNative = this.platform.is("hybrid");
    effect(async ()=>{
      let clickedBtn=this.fixedButtonService.clickedSignal();      
      if(clickedBtn?.name==="Save Draft"){
        this.showQuoteSummary();
      }else if(clickedBtn?.name==="Get Quote"){
        this.requestQuote();
      }
    })
    this.durationPicklist = durationPicklistConstant;
    this.quoteForm = fb.group(
      {
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        orderType: ["Recurring without End Date"],
        duration: [""],
        billTiming: "Bill in Advance",
        billingPeriod: "28 Day Bill Period",
        customerType: "", //from profile
        businessType: "", //from profile
        contactId: "",
      },
      {
        validators: [this.endDateMaxValidations],
      }
    );

    // bundle form
    this.bundleFormGroup = fb.group({
      bundlesArray: fb.array([]),
    });

    this.isMobile = window.innerWidth <= 660;
  }

  // screen size change
  @HostListener("window:resize", [])
  private onResize(event: any): void {
    this.isMobile = window.innerWidth <= 800;
    this.drawerMobileFlag = window.innerWidth <= 768;
  }
  /**
   * Date calc of unitandservices component
   */
  dateCalc = (count: number, date: Date = new Date()) => {
    const ogDate = date;
    const changedDate = ogDate.setDate(ogDate.getDate() + count);

    return new Date(changedDate);
  };

  ngOnInit() {
    this.fixedDateMessage=fixedDateMessage
    this.perQuoteUnitLimit = perQuoteUnitLimit;
    this.drawerMobileFlag = window.innerWidth <= 768;
    //after response new quote api call if user refreshes the page, then redirect to the same quote  id
    if (this.screen === "quotes" && !this.isNative) {
      if (localStorage.getItem("quoteId")) {
        this._router.navigate([
          "/quotes/quotecreation",
          localStorage.getItem("quoteId"),
        ]);
        if (localStorage.getItem("generateDocumentId")) {
          this.generateDocument(localStorage.getItem("quoteId") as string);
        }
      }
    }

    this.quoteStateService.currentQuote$
      .pipe(
        filter((quote) => quote.currentStep === 1),
        distinctUntilChanged((prev, curr) => prev.quoteId === curr.quoteId)
      )
      .subscribe((quote) => {
        // if user refreshes the page, retry from here
        if (localStorage.getItem("requestId") && !quote.quoteId && !this.isNative) {
          this.quoteStateService.saveNewQuote();
        }
      });

    // set the loading flag to true
    this.isLoading = true;
    // subscribe to the quote state and set loading as appropriate
    this.qouteStateSubscription =
      this.quoteStateService.currentQuote$.subscribe((quote) => {
        // once the quote has an ID, we can stop loading
        this.isLoading = quote.quoteId != "" ? false : true;
        // set the current state and load the quote form
        this.currentState = quote;
        // don't update the quote form if we're on a new quote
        const routeParam = this.route.snapshot.url[1].path;
        if (routeParam !== "new_quote") {
          this.updateQuoteForm(quote);
          if (this.bundles) {
            this.prefilledProducts(
              this.quoteStateService.getCurrentValue().step1.productDetails
            );
          }
        }
        if (quote.quoteId != null && quote.quoteId != "")
          localStorage.setItem("quoteId", quote.quoteId as string);
      
        //update fixed buttons
        if(quote.currentStep===1){          
          let buttons:FixedButtonModel[]=[
            {
              name:"Save Draft",
              isMainButton:false,
              isIconButton:false,
              icon:"",
              disabled:false,
              loading:false,
              componentName:"unit-and-services"
            },
            {
              name:"Get Quote",
              isMainButton:true,
              isIconButton:false,
              icon:"",
              disabled:false,
              loading:false,
              componentName:"unit-and-services"
            }
          ];
          let buttonsLoading=[{
            name:"Gathering Info",
            isMainButton:false,
            isIconButton:false,
            icon:"",
            disabled:true,
            loading:true,
            componentName:"unit-and-services"
          },
          {
            name:"Get Quote",
            isMainButton:true,
            isIconButton:false,
            icon:"",
            disabled:true,
            loading:false,
            componentName:"unit-and-services"
          }]
          this.fixedButtonService.setButtons(this.isLoading?buttonsLoading:buttons);
        }
      });

    // min value for startdate
    this.startDateMin = this.dateCalc(
      this.configService.getConfigProperty("DATE_GAP")
    );
    // min value for enddate
    this.endDateMin = this.dateCalc(
      this.configService.getConfigProperty("DATE_GAP") + 1
    );

    this.quoteForm.valueChanges.subscribe((res) => {
      this.setUpdateSummary();
      const now = new Date(this.quoteForm.value.endDate);
      const startDate = this.pipe.transform(
        this.quoteForm.value.startDate,
        "shortDate"
      );
     
      const endDate = this.pipe.transform(now, "shortDate");
     
    
      if (startDate == endDate) {
        this.quoteForm.get("endDate")?.setErrors({ invalidDate: true });
      }
      this.quoteSummary.startDate = res.startDate;
      this.quoteSummary.endDate = res.endDate;
      this.quoteSummary.duration = res.duration;
      this.quoteSummary.orderType = res.orderType;
    });
    (
      this.bundleFormGroup.get("bundlesArray") as FormArray
    )?.valueChanges.subscribe((res) => {
     
      this.setUpdateSummary();
      const newvalue =this.bundleFormGroup.get("bundlesArray")?.value.filter((el:BundlesForm)=>el.existing==false);
      if(newvalue.length>0){
        this.quoteSummary.bundles = newvalue;
      }
      else{
        this.quoteSummary.bundles=[
          {
            productIdBundle: "",
            additionalProduct: null,
            productIdAsset: null,
            bundleName: "",
            bundleProductCode: "",
            bundleQty: 0,
            productIdService: null,
          },
        ]
      }
    });

    this.quoteCreationService.getProducts().subscribe((result) => {      
      if (result) {
        this.bundles = result;
        console.log(" get product bundles", this.bundles);
        if (
          this.quoteStateService.getCurrentValue()?.step1?.productDetails
            .length > 0
        ) {
          this.prefilledProducts(
            this.quoteStateService.getCurrentValue()?.step1?.productDetails
          );
        }

        //rfq api call
        if (localStorage.getItem("rfqId")) {
          this.fetchRfqDetails();
        }

       
        if (this.screen === "editOrder") {
          this.orderService.orderSummary.subscribe((result) => {
            if (result) {
              this.orderData = result;
              this.prefilledExistingProducts(result.quoteSummary);
            }
          });
        }

        this.getAllStandardQuotes();
        this.quoteMainScreenService.standardQuotes.subscribe((result) => {
          this.standardQuotesList = result;
          console.log("standard quotes", result);

        });

      } else {
        this.label = "Custom Quote";
      }
    });

    this.quoteCreationService.addressValue$.subscribe((result) => {
      if (result) {
        this.address = result.address as Address;
        this.addressId = result.address.addressId;
        this.addressExist = result.address.addressExist;
        localStorage.setItem(
          "address",
          JSON.stringify({
            ...this.address,
            addressId: this.addressId,
            addressExist: this.addressExist,
          })
        );
      }
    });

    this.subscribe = this.quoteCreationService.standardQuote$.subscribe(
      (result) => {
        if (result) {
          this.standardQuotesData = result;
        }
      }
    );
    this.addressId = localStorage.getItem("addressRefId") as string;
    this.addressExist = Boolean(
      JSON.parse(localStorage.getItem("addressExist") as string)
    );
    this.address = JSON.parse(localStorage.getItem("address") as string);


  }

  checkDayOfWeek(selectedDate:Date) {
    // console.log("selectedDate",selectedDate)
    const date = new Date(selectedDate);
    const day = date.getDay();
    if (day === 0 || day === 6) {
      this.dialog.open(WeekendDayValidationPopupComponent, {
      
        panelClass: "limit-exceed-dialog",
        data: {
          isEditedData: "yes",
        },
      });
      // console.log("weekend day selected",this.quoteForm.value);
      
    }
    
  }

   
    
    


    /**
   * Gets all standard quotes
   */
    getAllStandardQuotes() {
      this.quoteMainScreenService.allStandardQuotes();
    }

  fetchRfqDetails() {
    this.rfqService.getRFQDetails().subscribe((rfq) => {
      if (rfq) {
        this.rfqAutoFill(rfq.products, rfq.startDate);
      }
    });
  }

  /**
   * Gets product
   */
  getProduct() {
    this.quoteCreationService.getProducts();
  }
  /**
   * Radios for date and duration
   * @param e
   */
  radioForDateAndDuration(e: MatRadioChange) {
    this.setUpdateSummary();
    this.duration = e.value;
    const endDateFormControl = this.quoteForm.controls[
      "endDate"
    ] as FormControl;
    const durationFormControl = this.quoteForm.controls[
      "duration"
    ] as FormControl;
    if (this.duration) {
      // true - duration
      this.quoteForm.patchValue({
        orderType: "Recurring without End Date",
        // duration: "",
        // endDate: null,
      });
      //this.durationErrors(endDateFormControl, durationFormControl);
      this.endDateErrors(endDateFormControl, durationFormControl);
    } else {
      // false - end date
      this.quoteForm.patchValue({
        orderType: "Recurring Service",
        //  duration: "",
        //  endDate: null,
      });
      this.endDateErrors(endDateFormControl, durationFormControl);
    }
  }
  /**
   * Durations errors
   * @param endDateFormControl
   * @param durationFormControl
   */
  durationErrors(
    endDateFormControl: FormControl,
    durationFormControl: FormControl
  ) {
    // setting required validation for duration
    durationFormControl.setValidators([Validators.required]);
    durationFormControl.updateValueAndValidity();
    // removed required validation for end date
    endDateFormControl.setValidators([]);
    endDateFormControl.updateValueAndValidity();
  }
  /**
   * Ends date errors
   * @param endDateFormControl
   * @param durationFormControl
   */
  endDateErrors(
    endDateFormControl: FormControl,
    durationFormControl: FormControl
  ) {
    // setting required validation for end Date
    endDateFormControl.setValidators([Validators.required]);
    endDateFormControl.updateValueAndValidity();
    // removed required validation for duration
    durationFormControl.setValidators([]);
    durationFormControl.updateValueAndValidity();
  }

  /**
   * Adds new bundle
   */
  addNewBundle() {
    this.isPrefilledProducts = false;
    const bundle = this.bundleFormGroup.get("bundlesArray") as FormArray;
  
    bundle.insert(0,
      this.bundleForminit(this.addProductFormControl.value as string)
    );
  
  }
  /**
   * Bundles forminit
   * @param bundleProductCode
   * @returns
   */
  bundleForminit(bundleProductCode: string): FormGroup {
    if (!this.bundles) {
      return this.fb.group({});
    }

    const selectedBundle = this.bundles.find(
      (el: ProductModel) => el.bundleProductCode === bundleProductCode
    ) as ProductModel;
 
      this.selectedBundleArray.unshift(selectedBundle);

    // show toast message if the zip code requires containment tray
    if (
      this.getBundleContainmentTrayRequirement(selectedBundle) &&
      !this.isPrefilledProducts
    ) {
      this.toastr.info(
        "Containment trays are required for portable restrooms in your area."
      );
    }
    // if the zip code requires containment tray and this is a restroom bundle
    // then add the containment tray to the additional products
    return this.fb.group({
      productIdBundle: selectedBundle.bundleId,
      bundleName: selectedBundle.bundleName,
      bundleProductCode,
      productIdAsset: ["", [Validators.required]],
      productIdService: ["", [Validators.required]],
      additionalProduct: [this.getDefaultAdditionalProducts(selectedBundle)],
      bundleQty: 1,
      existing: false,
    });
  }
  /** get default additional products */
  getBundleContainmentTrayRequirement(selectedBundle: ProductModel): boolean {
    const zipRequiresContainmentTray = this.zipCodeService
      .zipcodeBehaviorSubject.value
      ? this.zipCodeService.zipcodeBehaviorSubject.value?.data
          ?.containmentTrayRequiredForRestroom
      : JSON.parse(
          localStorage.getItem("containmentTrayRequiredForRestroom") as string
        );
    // only require containment tray for restroom bundles
    const bundleRequiresContainmentTray =
      zipRequiresContainmentTray &&
      selectedBundle.bundleProductCode === "110-0000";
    // return the value
    return bundleRequiresContainmentTray;
  }
  getDefaultAdditionalProducts(
    selectedBundle: ProductModel
  ): AncillaryService[] {
    const resp = new Array<AncillaryService>();
    const requiresContainmentTray =
      this.getBundleContainmentTrayRequirement(selectedBundle);
    if (requiresContainmentTray) {
      const ct = selectedBundle.ancillaryServiceList.find(
        (el: AncillaryService) => el.ancillaryServiceProductCode === "113-1801"
      );
      if (ct) {
        resp.push(ct);
      }
    }
    return resp;
  }
  /** disables options*/
  disableAdditionalService(
    selectedBundle: ProductModel,
    ancillaryService: AncillaryService
  ): boolean {
    // if it's not a containment tray or not a restroom bundle, then don't disable
    if (
      ancillaryService.ancillaryServiceProductCode !== "113-1801" ||
      selectedBundle.bundleProductCode !== "110-0000"
    ) {
      return false;
    }
    // check if the zip code requires containment tray
    let ctRequiredForZIP =
      this.getBundleContainmentTrayRequirement(selectedBundle);
    // double check the product code and bundle code
    const disabled =
      ctRequiredForZIP &&
      selectedBundle.bundleProductCode === "110-0000" &&
      ancillaryService.ancillaryServiceProductCode === "113-1801";
    return disabled;
  }

  /**
   * Adds new product
   */
  addNewProduct() {
    // if selected product then add
    if (this.addProductFormControl.value?.label2) {
      console.log(this.addProductFormControl.value.label2);
      this.standardQuotes(this.addProductFormControl.value);

    }else{
    this.addProductFormControl.value && this.addNewBundle();
    }
    // reset the add product dropdown
    this.addProductFormControl.reset();
  }
  /**
   * Deletes bundle
   * @param i
   */
  deleteBundle(index: number) {
    const bundle = this.bundleFormGroup.get("bundlesArray") as FormArray;
    if (bundle.length === 1) {
      this.quoteSummary.bundles = [
        {
          productIdBundle: "",
          additionalProduct: null,
          productIdAsset: null,
          bundleName: "",
          bundleProductCode: "",
          bundleQty: 0,
          productIdService: null,
        },
      ];
    }
    bundle.removeAt(index);
    this.selectedBundleArray.splice(index, 1);
  }
  /**
   * Gets bundle rows
   */
  get bundleRows() {
    return this.bundleFormGroup.controls["bundlesArray"] as FormArray;
  }
  /**
   * Shows quote summary
   */
  showQuoteSummary() {
    if (this.bundleFormGroup.value.bundlesArray.length === 0) {
      this.toastr.error("Please add product to request a quote.");
    }

    let startDate = moment(this.quoteForm.value.startDate);
    let endDate = moment(this.quoteForm.value.endDate);
    let diffInMonths = Math.abs(startDate.diff(endDate, "month"));
    let durationInMonths = getDurationCategory(diffInMonths);
    if (this.quoteForm.value.orderType == "Recurring without End Date") {
      this.quoteForm.patchValue({
        orderType: "Recurring without End Date",
        duration: durationInMonths,
      });
    } else {
      this.quoteForm.patchValue({
        orderType: "Recurring Service",
        duration: "",
      });
    }

    if (
      this.bundleFormGroup.valid &&
      this.bundleFormGroup.value.bundlesArray.length > 0 &&
      this.quoteForm.valid
    ) {
      const qty = (
        this.bundleFormGroup.get("bundlesArray") as FormArray
      ).value.reduce((acc: number, el: any) => (acc += el.bundleQty), 0);

      if (qty > this.perQuoteUnitLimit) {
        this.dialog.open(LimitExceedComponent, {
          width: "430px",
          panelClass: "limit-exceed-dialog",
        });
        return;
      }
      this.loadingService.startLoading(true, "Saving Quote Details", true);

      this.quoteCreationService
        .updateQuoteSummary(this.reqBodyMapper())
        .subscribe((result: updateQuoteSummaryRes | null) => {
          if (result) {
            this.setUpdateSummary(true);
            this.currentState = this.quoteStateService.getCurrentValue();
            this.quoteStateService.updateCommonValues({
              ...this.currentState,
              step1: {
                ...this.currentState.step1,
                ...this.quoteForm.value,
                duration: this.quoteForm.value.duration,
                endDate:
                  this.quoteForm.value.duration == ""
                    ? this.pipe.transform(
                        this.quoteForm.value.endDate,
                        "yyyy-MM-dd"
                      )
                    : "2049-12-31",
                estimatedEndDate: this.pipe.transform(
                  this.quoteForm.value.endDate,
                  "yyyy-MM-dd"
                ),
                startDate: this.pipe.transform(
                  this.quoteForm.value.startDate,
                  "yyyy-MM-dd"
                ),
                productDetails: this.bundleFormGroup.value.bundlesArray,
                zipcode: this.currentState.address.zipcode + "",
              },
            });
          }
        });
      return;
    }
    this.formMarkAsTouched();

  }
  formMarkAsTouched() {
    this.quoteForm.markAllAsTouched();
    this.bundleFormGroup.markAllAsTouched();
  }

  /**
   * Requests quote
   */
  async requestQuote() {
    let startDate = moment(this.quoteForm.value.startDate);
    let endDate = moment(this.quoteForm.value.endDate);
    let diffInMonths = Math.abs(startDate.diff(endDate, "month"));
    let durationInMonths = getDurationCategory(diffInMonths);
    if (this.quoteForm.value.orderType == "Recurring without End Date") {
      this.quoteForm.patchValue(
        {
          orderType: "Recurring without End Date",
          duration: durationInMonths,
        },
        {
          emitEvent: false,
          onlySelf: true,
        }
      );
    } else {
      this.quoteForm.patchValue(
        {
          orderType: "Recurring Service",
          duration: "",
        },
        {
          emitEvent: false,
          onlySelf: true,
        }
      );
    }

    if (!this.isUpdatedQuoteSummary) {
      if (this.bundleFormGroup.value.bundlesArray.length === 0) {
        this.toastr.error("Please add product to request a quote.");
      }

      if (
        this.bundleFormGroup.valid &&
        this.bundleFormGroup.value.bundlesArray.length > 0 &&
        this.quoteForm.valid
      ) {
        const qty = (
          this.bundleFormGroup.get("bundlesArray") as FormArray
        ).value.reduce((acc: number, el: any) => (acc += el.bundleQty), 0);

        if (qty > this.perQuoteUnitLimit) {
          this.dialog.open(LimitExceedComponent, {
            width: "430px",
            panelClass: "limit-exceed-dialog",
          });
          return;
        }
        this.loadingService.startLoading(true, "Saving Quote Details", true);

        this.quoteCreationService
          .updateQuoteSummary(this.reqBodyMapper())
          .subscribe((result: updateQuoteSummaryRes | null) => {
            if (result) {
              this.setUpdateSummary(true);
              this.currentState = this.quoteStateService.getCurrentValue();
              this.quoteStateService.updateCommonValues({
                ...this.currentState,
                step1: {
                  ...this.currentState.step1,
                  ...this.quoteForm.value,
                  duration: this.quoteForm.value.duration,
                  endDate:
                    this.quoteForm.value.duration == ""
                      ? this.pipe.transform(
                          this.quoteForm.value.endDate,
                          "yyyy-MM-dd"
                        )
                      : "2049-12-31",
                  estimatedEndDate: this.pipe.transform(
                    this.quoteForm.value.endDate,
                    "yyyy-MM-dd"
                  ),
                  startDate: this.pipe.transform(
                    this.quoteForm.value.startDate,
                    "yyyy-MM-dd"
                  ),
                  productDetails: this.bundleFormGroup.value.bundlesArray,
                  zipcode: this.currentState.address.zipcode + "",
                },
              });
              this.generateDocument(this.currentState.quoteId as string);
            }
          });
        return;
      }
      this.formMarkAsTouched();
    } else {
      if (this.bundleFormGroup.valid && this.quoteForm.valid) {
        this.generateDocument(this.currentState.quoteId as string);
      } else {
        console.log("failed");
        this.formMarkAsTouched();
      }
    }  
  }

  async generateDocument(quoteId: string) {
    //set loader to true if not refreshed request
    // this.loadingService.startLoading(true, "Generating Quote PDF", true);
    let requestId: string = "";
    if (!localStorage.getItem("generateDocumentId")) {
      requestId = await this.quoteStateService.generateRequestID();
      localStorage.setItem("generateDocumentId", requestId);
    } else {
      requestId = localStorage.getItem("generateDocumentId") as string;
    }

    this.quoteStateService.updateCommonValues({
      ...this.quoteStateService.getCurrentValue(),
      currentStep: 1,
    });

    const callBack = () =>
      this.quoteCreationService.generateDocument(quoteId, requestId);
    this.intervalSubscription$ = this.quoteStateService.startInterval(
      callBack,
      3000,
      "generateDocument"
    );
  }

  /**
   * Ends date max validations
   * @param formGroup
   * @returns
   */
  endDateMaxValidations(
    formGroup: AbstractControl
  ): { invalidDate: boolean } | { required: boolean } | null {
    const startDate = formGroup.get("startDate")?.value;
    const endDate = formGroup.get("endDate")?.value;
   

    if (
      new Date(startDate) >= new Date(endDate) &&
      endDate
    ) {
      formGroup.get("endDate")?.setErrors({ invalidDate: true });
      return { invalidDate: true };
    } else {
      if (!endDate) {
        formGroup.get("endDate")?.setErrors({ required: true });
        return { required: true };
      }
      formGroup.get("endDate")?.setErrors(null);
      return null;
    }
  }
  /**
   * Reqs body mapper
   * @returns
   */
  reqBodyMapper(): updateQuoteSummaryReq {
    // return;
    this.currentState = this.quoteStateService.currentQuote$.value;
    const reqBody: updateQuoteSummaryReq = {
      quoteId: this.currentState.quoteId,
      ...this.quoteForm.value,
      accountId: this.currentState.accountId,
      startDate: this.pipe.transform(
        this.quoteForm.value.startDate,
        "yyyy-MM-dd"
      ),
      estimatedEndDate:
        this.quoteForm.value.orderType == "Recurring Service"
          ? ""
          : this.pipe.transform(this.quoteForm.value.endDate, "yyyy-MM-dd"),
      customerType: this.currentState.userProfile?.customerType,
      businessType: this.currentState.userProfile?.businessType,
      endDate:
        this.quoteForm.value.orderType == "Recurring Service"
          ? this.pipe.transform(this.quoteForm.value.endDate, "yyyy-MM-dd")
          : "2049-12-31",
      prodSubType: this.currentState.step1.prodSubType,
      contactId:
        this.quoteStateService.getCurrentValue().userProfile?.contactId,
      addressId: this.quoteStateService.getCurrentValue().address.addressId,
      zipcode: this.quoteStateService.getCurrentValue().address.zipcode
        ? this.quoteStateService.getCurrentValue().address.zipcode + ""
        : this.address.zipcode,
      productDetails: this.productDetailsMapper(),
    };

    return reqBody;
  }
  /**
   * Products details mapper
   * @returns
   */
  productDetailsMapper() {
    return this.bundleFormGroup.value.bundlesArray.map(
      (bundleForm: BundlesForm, i: number) => {
        let product = {
          productIdBundle: bundleForm.productIdBundle,
          productOptionSkuBundle: "",
          bundleSummery: bundleForm.bundleName,
          bundleQty: bundleForm.bundleQty,
          productIdService: bundleForm.productIdService?.serviceOptionalId,
          productOptionSkuService: bundleForm.productIdService?.id,
          serviceSummery: bundleForm.productIdService?.serviceName,
          productIdAsset: bundleForm.productIdAsset?.assetOptionalId,
          productOptionSkuAsset: bundleForm.productIdAsset?.id,
          assetSummary: bundleForm.productIdAsset?.assetName,
          additionalProduct: this.additionalServicesMapper(i),
        };
        return product;
      }
    );
  }
  /**
   * Additionals services mapper
   * @returns
   */
  additionalServicesMapper(index: number) {
    return this.bundleFormGroup.value.bundlesArray[index].additionalProduct
      .length > 0
      ? this.bundleFormGroup.value.bundlesArray[index].additionalProduct.map(
          (el: AncillaryService) => {
            let additionalServices = {
              productIdAS: el.ancillaryServiceOptionalId,
              productOptionSkuAS: el.id,
              aSSummery: el.ancillaryServiceName,
            };
            return additionalServices;
          }
        )
      : [];
  }
  /**
   * Sets update summary
   * @param [val]
   */
  setUpdateSummary(val: boolean = false) {
    this.isUpdatedQuoteSummary = val;
  }

  // prfilled standard quote
  standardQuotes(quote: StandardQuotes) {
    this.isPrefilledProducts = false;

    const bundle = this.bundleFormGroup.get("bundlesArray") as FormArray;

    quote.products.forEach(
      (products: Products, selectedBundleIndex: number) => {
        const form = this.bundleForminit(products.bundle.bundleProductCode);
        const index =this.selectedBundleArray.findIndex(el=>el.bundleProductCode==products.bundle.bundleProductCode);
        const asset = this.selectedBundleArray[
          index
        ].assetList.find(
          (assest: Asset) => assest.id === products?.asset?.assetId
        );
        const service = this.selectedBundleArray[
          index
        ].serviceList.find(
          (service: Service) => service.id === products?.service?.serviceId
        );
        const additionalProduct = this.selectedBundleArray[
          index
        ].ancillaryServiceList.filter(
          (ancillaryService: AncillaryService) =>
            ancillaryService.id === products?.ancillary?.ancillaryServiceId
        );

        additionalProduct.push(
          ...this.getDefaultAdditionalProducts(
            this.selectedBundleArray[selectedBundleIndex]
          )
        );

        form.patchValue({
          productIdAsset: asset,
          productIdService: service,
          additionalProduct,
          bundleQty: Number(products.bundle.qty),
        });

        bundle.insert(0,form);
      }
    );
    this.quoteForm.valueChanges.subscribe((res) => {
      this.setUpdateSummary();

      this.quoteSummary.startDate = res.startDate;
      this.quoteSummary.endDate = res.endDate;
      this.quoteSummary.duration = res.duration;
      this.quoteSummary.orderType = res.orderType;
    });
    (
      this.bundleFormGroup.get("bundlesArray") as FormArray
    )?.valueChanges.subscribe((res) => {
      this.setUpdateSummary();
      const newvalue =this.bundleFormGroup.get("bundlesArray")?.value.filter((el:BundlesForm)=>el.existing==false);
      if(newvalue.length>0){

        this.quoteSummary.bundles = newvalue;
      }
      this.label = "Custom Quote";
    });

    this.isPrefilledProducts = true;
    this.standardQuotesData = null;
  }

  rfqAutoFill(product: RFQProduct[], startDate: Date) {
    this.isPrefilledProducts = false;

    const bundle = this.bundleFormGroup.get("bundlesArray") as FormArray;
    product.forEach((product, i) => {
      const form = this.bundleForminit(product.skus.bundleSKU);
      const index =this.selectedBundleArray.findIndex(el=>el.bundleProductCode==product.skus.bundleSKU);

      const asset = this.selectedBundleArray[index].assetList.find(
        (assest: Asset) => assest.assetProductCode === product?.skus?.assetSKU
      );
      const service = this.selectedBundleArray[index].serviceList.find(
        (service: Service) =>
          service.serviceProductCode === product?.skus?.serviceSKU
      );
      const additionalProduct = this.getDefaultAdditionalProducts(
        this.selectedBundleArray[index]
      );
      form.patchValue({
        productIdAsset: asset,
        productIdService: service,
        bundleQty: Number(product.quantity),
        additionalProduct,
      });

      bundle.insert(0,form);
    });

    this.quoteForm.patchValue({
      startDate: startDate,
    });
    localStorage.removeItem("rfqId");
    localStorage.removeItem("myUssUser");
    localStorage.removeItem("rfqToken");
    this.isPrefilledProducts = true;
  }

  // prefilled products from state
  prefilledProducts(products: BundlesForm[]) {

    this.isPrefilledProducts = true;
    this.selectedBundleArray = [];
    (this.bundleFormGroup.get("bundlesArray") as FormArray).clear();
    if (this.quoteForm.value.duration == "") {
      this.radioForDateAndDuration({ value: false } as MatRadioChange);
      this.radioBtnForDateAndDuration.setValue(false);
    } else {
      this.radioForDateAndDuration({ value: true } as MatRadioChange);
      this.radioBtnForDateAndDuration.setValue(true);
    }

    const bundle = this.bundleFormGroup.get("bundlesArray") as FormArray;
    products?.forEach((product: BundlesForm, selectedBundleIndex: number) => {
      const form = this.bundleForminit(product.bundleProductCode);
      const index =this.selectedBundleArray.findIndex(el=>el.bundleProductCode==product.bundleProductCode);

      const asset = this.selectedBundleArray[
        index
      ]?.assetList.find(
        (assest: Asset) => assest.id === product?.productIdAsset?.id
      );
      const service = this.selectedBundleArray[
        index
      ]?.serviceList.find(
        (service: Service) => service.id === product?.productIdService?.id
      );
      const additionalProduct = this.selectedBundleArray[
        index
      ]?.ancillaryServiceList.filter((ancillaryService: AncillaryService) =>
        product.additionalProduct?.some(
          (addProduct) => ancillaryService["id"] === addProduct["id"]
        )
      );

      form.patchValue({
        productIdAsset: asset,
        productIdService: service,
        additionalProduct,
        bundleQty: Number(product.bundleQty),
        existing: false,
      });

      bundle.insert(0,form);
    });
    if (products?.length > 0) {
      this.setUpdateSummary(true);
      this.quoteSummary = {
        ...this.quoteForm.value,
        bundles: this.bundleFormGroup.value.bundlesArray,
      };
    } else {
      this.setUpdateSummary(false);
      this.quoteSummary = {
        startDate: null,
        endDate: null,
        duration: "",
        orderType: "",
        bundles: [
          {
            productIdBundle: "",
            additionalProduct: null,
            productIdAsset: null,
            bundleName: "",
            bundleProductCode: "",
            bundleQty: 0,
            productIdService: null,
          },
        ],
      };
    }
  }

  //update quoteForm
  updateQuoteForm(data: QuoteState) {
    const { step1, customerType, businessType, userProfile } = data;

    if (this.quoteForm) {
      this.quoteForm.patchValue({
        startDate: step1.startDate?step1.startDate+"T00:00:00.000":null,
        endDate: step1.duration == "" ? step1.endDate : step1.estimatedEndDate+"T00:00:00.000",
        orderType: step1.orderType,
        duration: step1.duration,
        billTiming: step1.billTiming,
        billingPeriod: step1.billingPeriod,
        customerType: customerType, //from profile
        businessType: businessType, //from profile
        contactId: userProfile?.contactId,
      });
    }

    // if ((step1.duration || !step1.duration) && step1.endDate == "2049-12-31") {
    //   this.radioForDateAndDuration({ value: true } as MatRadioChange);
    //   this.radioBtnForDateAndDuration.setValue(true);
    //   this.quoteForm.get("duration")?.setValue(step1.duration);
    // } else {
    //   this.radioForDateAndDuration({ value: false } as MatRadioChange);
    //   this.radioBtnForDateAndDuration.setValue(false);
    //   this.quoteForm.get("endDate")?.setValue(step1.endDate);
    // }
  }

  prefilledExistingProducts(products: QuoteSummary[]) {
this.quoteStateService.resetState();
    const bundle = this.bundleFormGroup.get("bundlesArray") as FormArray;
    products?.forEach((product: QuoteSummary, selectedBundleIndex: number) => {
      const bundleSelected=this.bundles.find(
        (el: ProductModel) => el.bundleId === product.bundleOptionalId
      ) as ProductModel
 
      const form = this.bundleForminit(bundleSelected.bundleProductCode);
      const index =this.selectedBundleArray.findIndex(el=>el.bundleProductCode==bundleSelected.bundleProductCode);

      const asset = this.selectedBundleArray[
        index
      ]?.assetList.find(
        (assest: Asset) =>
          assest.assetOptionalId === product?.asset[0].assetOptionalId
      );

      const service = this.selectedBundleArray[
        index
      ]?.serviceList.find(
        (service: Service) =>
          service.serviceOptionalId === product?.service[0]?.serviceOptionalId
      );
      const additionalProduct = this.selectedBundleArray[
        index
      ]?.ancillaryServiceList.filter((ancillaryService: AncillaryService) =>
        product.ancillaryServices?.some(
          (addProduct) =>
            ancillaryService["ancillaryServiceOptionalId"] ===
            addProduct["ancillaryServiceOptionalId"]
        )
      );

      form.patchValue({
        productIdAsset: asset,
        productIdService: service,
        additionalProduct,
        bundleQty: Number(product.bundleQty),
        existing: true,
      });
      form.disable();

      bundle.insert(0,form);
    });
  }

  async addNewUnitType() {

    if (
      this.bundleFormGroup.valid &&
      !this.bundleFormGroup.disabled 
    ) {

      const qty = (this.bundleFormGroup.get("bundlesArray") as FormArray)
        .getRawValue()
        .reduce((acc: number, el: BundlesForm) => (acc += el.bundleQty), 0);

      if (qty > this.perQuoteUnitLimit) {
        this.dialog.open(LimitExceedComponent, {
          width: "430px",
          panelClass: "limit-exceed-dialog",
        });
        return;
      }
    
  
      const jsonData: CaseProductInfo[] = [];
      this.bundleFormGroup.value.bundlesArray.forEach((form: BundlesForm) => {
        if (!form.existing) {
          const data = {
            Product: form.productIdAsset?.assetName,
            Size: "",
            Quantity: form.bundleQty,
            "Service Frequency": form.productIdService?.serviceName,
            AncillaryServices: form.additionalProduct,
            Price: 0,
            Action: "Add unit",
            Notes: "",
          };
          jsonData.push(data);
        }
      });
   

      this.showSideDrawer = true;
      this.caseData = {
        data: jsonData,
        screen: this.screen,
        siteAddress: {locations: this.orderData?.assetLocations},
      };
    } else {
      if (this.bundleFormGroup.disabled) {
        this.toastr.error("Add minimum one unit in order to proceed further.");
      }
      this.formMarkAsTouched();
    }
  }

  
 

  closeDrawer(event) {
    this.showSideDrawer = event;
  }

  shouldShowComponent(): boolean {
    if (this.showSideDrawer && this.drawerMobileFlag) {
          return false;
    } else if (this.showSideDrawer && !this.drawerMobileFlag) {
          return true;
    } else if (!this.showSideDrawer && this.drawerMobileFlag) {
           return true;
    }
    return true;
  }

  ngOnDestroy() {
    this.subscribe?.unsubscribe();
    this.standardQuotesData = null;
    if (this.qouteStateSubscription) {
      this.qouteStateSubscription.unsubscribe();
      this.intervalSubscription$.unsubscribe();
    }
    this.quoteStateService.stopInterval();
    localStorage.removeItem("generateDocumentId");
    localStorage.removeItem("requestId");
  }
}
