import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'app-resetpopup',
  templateUrl: './resetpopup.component.html',
  styleUrls: ['./resetpopup.component.scss']
})
export class ResetpopupComponent {

  constructor(private dialogref: MatDialogRef<ResetpopupComponent>){}

  onYes() {
    this.dialogref.close("yes");
  }

}
