import { Injectable } from "@angular/core";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import {
  BehaviorSubject,
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
} from "rxjs";
import { StandardQuotes } from "src/app/models/quote-main-screen-model";
import { ToastrService } from "ngx-toastr";
import { LoadingService } from "../../../shared/services/loading/loading.service";
import { DraftModel } from "src/app/models/draft-model";

@Injectable({
  providedIn: "root",
})
export class QuoteMainScreenService {
  quoteAcceptReject: any;

  constructor(
    private api: UssApiService,
    private loadingService: LoadingService,
    private toastr: ToastrService
  ) {}

  // behaviorsubject for standard Qoutes
  // public archiveQuotesPdfBehaviorSubject = new BehaviorSubject<any>(null);

  // behaviorsubject for standard Qoutes
  private standardQuoteBehaviorSubject = new BehaviorSubject<StandardQuotes[]>(
    new Array<StandardQuotes>()
  );
  // allproducts as observable
  standardQuotes: Observable<StandardQuotes[]> =
    this.standardQuoteBehaviorSubject.asObservable();
  /**
   * Archived quote behavior subject of quote main screen service
   */
  private archivedQuoteBehaviorSubject = new BehaviorSubject<
    DraftModel[] | null
  >(null);
  /**
   * Archived quote of quote main screen service
   */
  archivedQuote: Observable<DraftModel[] | null> =
    this.archivedQuoteBehaviorSubject.asObservable();

  //Api to fetching all standard quotes
  allStandardQuotes() {
    this.loadingService.setLoader(true);
    firstValueFrom(
      this.api.get("products/standard/template").pipe(
        map((res: StandardQuotes[]) => {
          if (res["status"] === 1000) {
            this.standardQuoteBehaviorSubject.next(res["data"]);
            this.loadingService.setLoader(false);
          }
        }),
        catchError((err) => {
          console.error(`error getting: ${JSON.stringify(err)}`);
          this.loadingService.setLoader(false);
          // throw err; >> use this to rethrow the error if that makes sense
          return of(null);
        })
      )
    );
  }

  //get all archived quotes
  getAllArchivedQuotes(userId: string) {
    this.loadingService.setLoader(true);
    firstValueFrom(
      this.api.get(`accounts/${userId}/archived-drafts`).pipe(
        map((res: any) => {
          if (res["message"] === "Success") {
            this.archivedQuoteBehaviorSubject.next(res["data"] as DraftModel[]);
            // return res;
          } else {
            // this.toastr.error("Something went wrong");
            this.archivedQuoteBehaviorSubject.next([] as DraftModel[]);
          }
          this.loadingService.setLoader(false);
        }),
        catchError((err) => {
          console.error(`error getting: ${JSON.stringify(err)}`);
          this.archivedQuoteBehaviorSubject.next([] as DraftModel[]);
          this.loadingService.setLoader(false);
          return of(null);
        })
      )
    );
  }
}
