<div class="p-2" fxLayout="column">
  <h2 class="p-2 top_heading">Tracking Information</h2>
  <span class="pl-2 pr-2 pb-2 sub_heading">To enhance your experience, we’d like to track:</span>
  <div class="pl-3 pr-2 pb-2">
    <ul class="pl-3 tracking_list">
      <li class="p-2">Data Collected: Purchase History, Name, Email, Phone Number, and more.</li>
      <li class="p-2">Purpose: To provide personalized features and better support.</li>
    </ul>
  </div>
  <button mat-raised-button color="primary" class="m-2" (click)="dismiss()">Got it</button>
</div>