import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";

export const roleGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const profileService = inject(ProfileService);
  const authService = inject(AuthenticationService);

  if (await authService.isAuthenticated()) {
    const user = profileService.selectedAccount();
    if (
      route.data.role &&
      route.data.role.indexOf(user.myussUserRole) === -1 
    ) {
      router.navigate(["/home"]);
      return false;
    }
    return true;
  }

  router.navigate(["/home"]);
  return false;
};
