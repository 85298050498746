import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ConfigService } from "../../../../shared/services/config/config.service";

@Injectable({
  providedIn: "root",
})
export class UssApiService {
  private apiBaseUrl = environment.API_URL;
  private rfqUrl;
  private url: string; // = `${environment.API_URL}/api/`;
  private zipUrl: string; // = environment.API_URL

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    this.apiBaseUrl = `${this.configService.getConfigProperty("API_URL")}`;
    this.url = `${this.apiBaseUrl}`;
    this.zipUrl = `${this.apiBaseUrl}`;
    this.rfqUrl = `${this.apiBaseUrl}`;
  }

  get(
    endPoint: string,
    data: {
      params?: HttpParams;
    } = {},
     version: string= 'v1'
  ) {
    let queryParams = data.params ? data.params : new HttpParams();
    return this.http.get<any>(`${this.url}/${version}/api/${endPoint}`, {
      params: queryParams,
    });
  }

  post(endPoint: string, data: any, params: {
    params?: HttpParams;
  } = {},version: string= 'v1') {
    let queryParams = params.params ? params.params : new HttpParams();

    return this.http.post<any>(`${this.url}/${version}/api/${endPoint}`, data,{params: queryParams});
  }

  put(endPoint: string, data: any,version: string= 'v1') {
    return this.http.put<any>(`${this.url}/${version}/api/${endPoint}`, data);
  }

  zipPost(endPoint: string, data: any,version: string= 'v1') {
    return this.http.post<any>(`${this.zipUrl}/${version}/${endPoint}`, data);
  }
  getPdfBlob(endPoint: string,version: string= 'v1') {
    const headers = new HttpHeaders({
      "Content-Type": "application/pdf",
    });
    const requestOptions = {
      headers: headers,
      responseType: "blob" as "json", // hack to allow blob type
    };
    return this.http.get<Blob>(`${this.url}/${version}/api/${endPoint}`, requestOptions);
  }

  getRfq(endPoint: string, token?: string | null,version: string= 'v1') {
    let rfq_token = token || localStorage.getItem("rfqToken");
    return this.http.get<any>(`${this.rfqUrl}/${version}/rfq/${endPoint}`, {
      headers: {
        Authorization: `Bearer ${rfq_token}`,
        // Autho: `Bearer ${localStorage.getItem('refreshToken')}`,
      },
    });
  }

  postRfq(endPoint: string, data: any,version: string= 'v1') {
    return this.http.post<any>(`${this.rfqUrl}/${version}/rfq/${endPoint}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rfqToken")}`,
        // Autho: `Bearer ${localStorage.getItem('refreshToken')}`,
      },
    });
  }

  delete(endPoint: string, data: any,version: string= 'v1') {
    console.log("delete data", data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data 
    };
    return this.http.delete<any>(`${this.url}/${version}/api/${endPoint}`, options);
  }
}
