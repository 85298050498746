<section class="popup-wrapper">

    <div class="popup-header px-4 py-3">
        <p *ngIf="record.type == 'case'">Case Request Initiated</p>
        <p *ngIf="record.type == 'MySiteServices'">Support Request Initiated</p>
        <span> <mat-icon class="close-icon" (click)="close('close')">close</mat-icon></span>

    </div>
    <div class="upload-content">
    <div class="popup-body px-3 pb-4 pt-3">
        <ul class="px-4">
            <li *ngIf="record.type == 'case'">Your request has been sent to the Order Support team</li>
            <li *ngIf="record.type == 'MySiteServices'">Your request has been sent to the IT Support team</li>
            <li>A representative will coordinate the fulfillment of your request</li>
            <li>They will reach out if any additional information is required</li>
            <li *ngIf="record.type == 'case'">You can track the status and communicate through the Cases screen</li>
            <li *ngIf="record.type == 'MySiteServices'">You can track the status and communicate through the Technical Support screen</li>

        </ul>
    </div>
    <app-upload-dialog  [record]="record"></app-upload-dialog>
    </div>
    <div class="popup-btns px-2 pb-2">
        <!-- <button mat-raised-button class="view-case-btn" (click)="close('viewCase')" ><span *ngIf="record.type == 'case'"> 
            View case
        </span>
    <span  *ngIf="record.type == 'MySiteServices'">
        View request
    </span>
    </button> -->
        <button mat-raised-button color="primary" (click)="close('ok')">OK</button>
    </div>
    
</section>