<div class="quote_header mb-3 p-3 bg-white" ngClass.md="p-1" ngClass.xs="p-1" ngClass.sm="p-1"
  fxLayoutAlign="start center">
  <div class="text pr-2 pl-2" fxLayout="column" fxLayoutAlign="space-between start"
    fxLayoutAlign.xs="space-between start" fxLayoutAlign.sm="space-between start" fxLayout.xs="column"
    fxLayout.sm="column">
    <div class="pl-1 pb-1 mb-1
     breadcrum-bar">
      <p>EDIT ORDER</p>
      <!-- <p>Order Summary > Edit Order </p> -->
    </div>
    <div class="order pl-1">
      <div>

        <h4>
          Order ID #{{ orderName || "-" }}
          |
          {{ siteAddress || "-" }}
        </h4>
        <p class="sub-header">Start Date : {{startDate | date : "MM/dd/yyyy"}} | End Date :
          {{(endDate | date : "MM/dd/yyyy")!="12/31/2049"?(endDate | date : "MM/dd/yyyy"):"-"}} </p>
        <p>{{ status || "-" }}</p>
      </div>
      <ng-container *appHideIfReadOnly >
        <div [appFixedButton]="'50px'" [isActive]="true" >

          <button fxFlex.xs=" 0 1 60"  *ngIf="showEdit && status=='Activated' " mat-raised-button color="primary" (click)="click()">{{btnConfig.name}}</button>
        </div>
    </ng-container>
    </div>
  </div>
</div>