
<section class="screen_bg" [ngClass]="{'screen_bg' : !outstandingFlags }">
    <div class="parent_div" fxLayout="column">
        <div class="p-2 pt-4">
            <img src="../../../../assets/img/check.svg" class="icon_size" alt="">
        </div>
        <div class="p-2">
            <h2>Thank You!</h2>
        </div>
        <div class="child_div pt-3 pl-2 pr-2 text">
            <p>Thank you for choosing MySiteServices Easy Pay to manage your payments.</p>
        </div>
        <div class="child_div pl-2 pr-2 text">
            <p>At United Site Services we are committed to making your site services Easy, Safe, and Clean.</p>
        </div>


        <div class="billing_div mt-4" *ngIf="outstandingFlags">

            <div class="icon_div">
                <img src="../../../../assets/img/icons/red-alert-triangle.svg" alt="" class="red_icon">
            </div>

            <div class="billing_text_div">

                <h3 class="pt-3 px-3">EasyPay updates are effective as of the next invoice of each order.</h3>
                <p class="red_text px-3">Your account indicates that there is a past due balance which will need to be paid in one of the following ways:</p>

<div class="points_button_div pt-3 pb-3">
    <div>
        <div *ngIf="flags?.myussBillingEnabled" class="points ">
            <mat-icon class="fiber_icon available_icon pr-1"
              >fiber_manual_record</mat-icon
            >
            <p class="text">Visit the <a class="visit_billing" routerLink="/billing">Billing</a>  page to make a one-time payment.</p>
          </div>
    
          <div class="points ">
            <mat-icon class="fiber_icon available_icon pr-1"
              >fiber_manual_record</mat-icon
            >
            <p class="text">
                Call
                  <a href="tel:{{ phoneNumber }}" class="phone-number">
                    {{ phoneNumber }}
                  </a>
                  to arrange for payment with a representative.</p>
          </div>
    </div>
   

      <button mat-raised-button *ngIf="flags?.myussBillingEnabled" class="pay_Bill" routerLink="/billing">Pay Bill</button>

      <button mat-raised-button  *ngIf="!flags?.myussBillingEnabled" class="pay_Bill" (click)="onCall()">Call Now</button>
</div>
              
            </div>


        </div>


        
        <div class="line mt-4" *ngIf="!outstandingFlags" ></div>
        <div class="child_div mt-4 pl-2 pr-2 text2">
            <p>Please explore the other self-service options available in the new MySiteServices customer hub!</p>
        </div>
        <div class="child2_div mt-2">
              <div *ngIf="flags?.myussQuotesEnabled" class="m-2  box">
           <img src="../../../../assets/img/icons/Icon - Self Service.png" class="icon_size p-3" alt="">
           <div  class="text_width p-3 ">
            <p class="easy_pay_text">Self service quotes and ordering for simple orders</p>
           </div>
        </div>
        <div  *ngIf="flags?.myussOrdersEnabled" class="m-2  box">
            <img src="../../../../assets/img/icons/Icon - Schedule Pickup Delivery.png" class="icon_size p-3" alt="">
            <div class="text_width p-3 ">
             <p class="easy_pay_text">Schedule pickups or deliveries of additional units</p>
            </div>
         </div>
         <div *ngIf="flags?.myussOrdersEnabled" class="m-2  box">
            <img src="../../../../assets/img/icons/Icon - View Services.png" class="icon_size p-3" alt="">
            <div class="text_width p-3 ">
             <p class="easy_pay_text">View your recent and upcoming services</p>
            </div>
         </div>
         <div *ngIf="flags?.myussBillingEnabled" class="m-2  box">
            <img src="../../../../assets/img/icons/Icon - View Download Document.png" class="icon_size p-3" alt="">
            <div class="text_width p-3 ">
             <p class="easy_pay_text">View and download invoices</p>
            </div>
         </div>
         <div class="m-2  box">
            <img src="../../../../assets/img/icons/Icon - More to Come.png" class="icon_size p-3" alt="">
            <div class="text_width p-3 ">
             <p class="easy_pay_text">More to come!</p>
            </div>
         </div>
        </div>

        <button mat-raised-button color="primary" *ngIf="!outstandingFlags" class="p-3 mt-3 mb-3" routerLink="home" >Go Home</button>

    </div>
     
</section>