import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/features/projects/services/project.service';
import { ProjectModel } from 'src/app/models/project-model';

class ProjectId {
  projectId: FormControl<string | null>;
  constructor() {
    this.projectId = new FormControl<string>("", [Validators.required]);
  }
}

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.scss']
})
export class SelectProjectComponent {
projectIdFormGroup: FormGroup<ProjectId>;


  constructor(
    private fb: FormBuilder,
    private toaster : ToastrService,
    private projectService: ProjectService,
    private dialogref: MatDialogRef<SelectProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public projectQuoteList: { project: ProjectModel[], quoteIds: string[] }
  ) { 
    this.projectIdFormGroup = this.createForm();
    console.log(this.projectQuoteList);
  }

  createForm(): FormGroup<ProjectId> {
    const projectId = new ProjectId();
    const projectIdFormGroup = this.fb.group<ProjectId>(projectId);
    return projectIdFormGroup;
  }
  
  onSelection(){
    this.projectIdFormGroup.patchValue({projectId: this.projectIdFormGroup.value.projectId});
  }

  onAssign(){
    if(this.projectIdFormGroup.valid){
      this.projectService.assignProjectToQuote(this.projectIdFormGroup.value.projectId as string, this.projectQuoteList.quoteIds).subscribe((res) => {
        if(res){
          this.toaster.success("Project Assigned Successfully");
          this.dialogref.close(this.projectIdFormGroup.value.projectId);
        }else{
          this.toaster.error("Failed to assign project");
        }
      })

    }
  }

}
