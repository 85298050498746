<div>
<div class="dialog-header">
    <h3>Attachments</h3>
    <mat-icon (click)="onCancel()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
<div class="file-list">
  <div *ngFor="let doc of documents"  (click)="downloadFile(doc.docId,doc.title,doc.extension)"  class="list-iteam">
    <img
      class="document-icon"
      [src]="getImageByExtension(doc.extension)"
      alt="file icon"
    />
    <span
    [matTooltip]="doc.title"
    matTooltipPosition="above"
    [matTooltipDisabled]="doc.title.length <= 20"
  >
    {{ truncateFileName(doc.title, 20) }}
  </span>
  <img  class="pt-1"
  src="../../../../../assets/img/download.svg"
    alt="order "
    class="download-icon"
    height="15px"
  />
  </div>
</div>
<div class="no-attachments" *ngIf="documents.length == 0">
    <p>No attachments added to the case.</p>
</div>
</div>
