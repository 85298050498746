import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ProfileService } from "../../accounts/services/profile/profile-services.service";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import { BehaviorSubject, Observable, catchError, map, of, shareReplay } from "rxjs";
import { CaseProductInfo, CaseDetailsState, CaseModel} from "src/app/models/case-details-model";
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { ColumnData } from "src/app/models/order-model";

@Injectable({
  providedIn: "root",
})
export class CaseDetailsStateService {
  public caseDetailsSubject = new BehaviorSubject<CaseDetailsState>(
    new CaseDetailsState()
  );
  caseDetails$ = this.caseDetailsSubject.asObservable();
  constructor(
    public profileService: ProfileService,
    private api: UssApiService,
    public toastr: ToastrService,
    private location: Location,
    private http: HttpClient
  ) {}

  getCaseDetails(
    accountId: string,
    caseNumber: string
  ): Observable<CaseDetailsState> {
    
    console.log("Case details in service:", accountId, caseNumber);
    let url = `accounts/${accountId}/cases/${caseNumber}`;
    return this.api.get(url).pipe(
      shareReplay(1),
      map((res) => {
        if (res["status"] === 1000) {
          const data = res["data"] as CaseDetailsState;
          console.log("Case details in service:", data);
          this.caseDetailsSubject.next(data);
        }
        return res["data"] as CaseDetailsState;
      }),
      catchError((error) => {
        console.error("Error fetching case details:", error);
        throw error;
      })
    );

  }

  getClosedCases(
    accountId: string,
    status: string,
    startTime: string,
    endTime: string,
    type?: string,
    contactId?: string,
    projectId?: string

  ) {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("type", type??'');
    queryParams = queryParams.append("contactId", contactId??'');
    if(projectId){
      queryParams = queryParams.append("projectId", projectId);
    }
  


    return this.api
      .get(
        `accounts/${accountId}/cases?status=${status}&startTime=${startTime}&endTime=${endTime}`,
        {params: queryParams}
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
        }),
        catchError((err) => {
          // this.toastr.error("Something went wrong.");
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
          // return of(null);
        })
      );
  }

  getOrderCases(accountId: string, status: string, contractId: string) {
    //http://localhost:8090/v1/api/accounts/0018I00000IyMLlQAN/contracts/1233456/cases?status=open
    return this.api
      .get(
        `accounts/${accountId}/contracts/${contractId}/cases?status=${status}`,
        {}
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
        }),
        catchError((err) => {
          // this.toastr.error("Something went wrong.");
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
          // return of(null);
        })
      );
  }

  getOrderClosedCases(
    accountId: string,
    status: string,
    contractId: string,
    startTime: string,
    endTime: string,

  ) {

   
    
    return this.api
      .get(
        `accounts/${accountId}/contracts/${contractId}/cases?status=${status}&startTime=${startTime}&endTime=${endTime}`,
        {}
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
        }),
        catchError((err) => {
          // this.toastr.error("Something went wrong.");
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
          // return of(null);
        })
      );
  }

  getCases(accountId: string, status: string,type?: string,contactId?: string,projectId?: string) {
    let queryParams = new HttpParams();
      queryParams = queryParams.append("type", type??'');
      queryParams = queryParams.append("contactId", contactId??'');
      if(projectId){
        queryParams = queryParams.append("projectId", projectId);
      }
    

    return this.api
      .get(`accounts/${accountId}/cases?status=${status}`, {
        params: queryParams,
      })
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
        }),
        catchError((err) => {
          // this.toastr.error("Something went wrong.");
          console.error(`error requesting quote: ${JSON.stringify(err)}`);
          throw err;
          // return of(null);
        })
      );
  }

  navigateBack() {
    this.location.back();
  }

  createCase(caseData: CaseModel, accountId: string,type?: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("type", type??'');
    
   return this.api.post(`accounts/${accountId}/cases/create`,caseData,{
    params: queryParams,
  }).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res;
        }
        return null
      }),
      catchError((err) => {
        console.error(`error getting in create case: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  addComment(commentData, accountId: string, caseId: string){
    return this.api.post(`accounts/${accountId}/cases/${caseId}/comment`, commentData).pipe(
      map((res) => {
        if (res.status === 1000) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`Error adding comment: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }


  downloadFile(accountId: string, documentId: string) {
    return this.api
      .getPdfBlob(`accounts/${accountId}/documents/${documentId}`)
      .pipe(
        map((res) => {
          if (res) {
            const mediaType = "application/pdf";
            const blob = new Blob([res], { type: mediaType });
            return blob;
          }
          return;
        }),
        catchError((err) => {
          console.error(`error getting quote PDF: ${JSON.stringify(err)}`);
          return of(null);
        })
      );
  }
  uploadAttchment(accountId, caseId, attachmentData){
    return this.api.post(`accounts/${accountId}/cases/${caseId}/upload`, attachmentData).pipe(
      map((res) => {
        if (res.status === 1000) {
          return res.status;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`Error adding attachment: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }
}
