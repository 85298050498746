import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SearchUnitNumberComponent } from "../dialogbox/search-unit-number/search-unit-number.component";
import { ScanService } from "src/app/features/scan/services/scan.service";
import { Router } from "@angular/router";
import { CapacitorBarcodeScanner } from "@capacitor/barcode-scanner";
import { ToastrService } from "ngx-toastr";
import { phoneNumber } from "src/assets/config/constants";

@Component({
  selector: "app-find-units-options",
  templateUrl: "./find-units-options.component.html",
  styleUrls: ["./find-units-options.component.scss"],
})
export class FindUnitsOptionsComponent {
  phoneNumber: string;
  constructor(
    private box: MatDialog,
    private router: Router,
    public scanService: ScanService,
    private toastr: ToastrService
  ) {
    this.phoneNumber = phoneNumber;
  }

  manual() {
    this.box.open(SearchUnitNumberComponent, {
      minWidth: "280px",
      maxWidth: "400px",
      panelClass: "search-unit-number",
    });
  }

  async startScanner() {
    try {
      const result = await CapacitorBarcodeScanner.scanBarcode({
        hint: 17,
        // scanButton: true,
        // scanText: "Scan",
        scanOrientation: 1,
      }); // Start scanning
      if (result.ScanResult) {
        const content = result.ScanResult;
        if (/^\d{8,10}$/.test(content)) {
          this.scanService
            .getAssetDetailsByUnitNumber(result.ScanResult)
            .subscribe((res) => {
              if (res) {
                this.scanService.setAssetDetails(res);
                this.router.navigate([
                  "/scan/assest-details",
                  result.ScanResult,
                ]);
              } else {
                this.startScanner();
                alert(`The unit number entered was not found on any active Orders for this account. Please check the unit number and try again or search for your order using the Orders module.  If you are still unable to find your order, please call us at ${this.phoneNumber}`);
              }
            });
        } else {
          this.startScanner();
          alert("Scanned QR code is invalid.");
        }
      } else {
        this.startScanner();
        alert("Scanned QR code is invalid.");
      }
    } catch (error) {
      console.log("Error in scanning QR code", error);
    }
  }
}
