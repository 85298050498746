import { Component, Input } from "@angular/core";
import { te } from "date-fns/locale";

@Component({
  selector: "app-tool-tip-template",
  templateUrl: "./tool-tip-template.component.html",
  styleUrls: ["./tool-tip-template.component.scss"],
})
export class ToolTipTemplateComponent {
  @Input() background: string = "#334155";
  @Input() color: string = "#FFFFFF";
  @Input() text: string = "";
  @Input() imageUrl: string = "";
  @Input() fontSize: string = "";
  constructor() {
    setTimeout(() => {
      console.log("ToolTipTemplateComponent", this.text);
    }, 100);
  }
}
