import { Injectable, signal } from "@angular/core";
import { PlytixProduct } from "src/app/models/product-model";

@Injectable({
  providedIn: "root",
})
export class ImageSliderService {
  slides = signal<PlytixProduct[]>([]);
  selectedSlide = signal<PlytixProduct | null>(null);

  constructor() {}

  setSlides(slides: PlytixProduct[]) {
    this.slides.set(slides);
  }
  setSelectedSlide(slide: PlytixProduct|null) {
    this.selectedSlide.set(slide);
  }
}
