import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, of } from "rxjs";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import { ProjectModel } from "src/app/models/project-model";
import { HttpParams } from "@angular/common/http";
import { AddressFormatPipe } from "src/app/theme/pipes/address-format.pipe";
import { Address } from "src/app/models/address-model";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  // address :Address = signal<Address>(new Address());
  public currentProject$ = new BehaviorSubject<ProjectModel>(
    new ProjectModel()
  );
  public projectData$ = new BehaviorSubject<ProjectModel[]>([]);
  addressFormatPipe = new AddressFormatPipe();

  selectedProject = signal<ProjectModel>(new ProjectModel());
  addressSignal = signal<Address>(new Address());

  constructor(
    private _api: UssApiService,
    private breakpointObserver: BreakpointObserver
  ) {}

  // Update Project Values
  updateProjectValues(data: Partial<ProjectModel>) {
    const currentState = this.currentProject$.value;

    this.currentProject$.next({
      ...currentState,
      ...data,
    });
  }

  // Get All Projects API Integration
  fetchProjects(status: string, isRecent: boolean = false) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("status", status);
    queryParams = queryParams.append("isRecent", isRecent);
    return this._api.get(`projects/list`, { params: queryParams }).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          this.projectData$.next(res["data"] as ProjectModel[]);
          return res["data"];
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error getting project data: ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }

  // format the address removes undefined values (null, undefined, empty string, double comma etc.)
  getAddress(project: ProjectModel) {
    return (
      this.addressFormatPipe.transform(
        `${project.address?.street},${project.address?.city},${project.address?.state},${project.address?.zipcode}`
      ) || "-"
    );
  }
  // sets the selected project
  setSelectedProject(project: ProjectModel) {
    this.selectedProject.set(project);
  }

  // Get Project Details Form Api
  fetchProjectDetails(projectId: string) {
    return this._api.get(`projects/${projectId}/details`).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res["data"];
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error getting project data: ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }
  /**
   * set recently viewed projects in local storage
   * @param project
   */
  setRecentlyViewedProjects(project: ProjectModel) {
    if (localStorage.getItem("recentlyViewedProjects")) {
      const recentlyViewedProjects = JSON.parse(
        localStorage.getItem("recentlyViewedProjects") as string
      );
      recentlyViewedProjects.length >= 3 ? recentlyViewedProjects.pop() : null;
      recentlyViewedProjects.unshift(project);
      localStorage.setItem(
        "recentlyViewedProjects",
        JSON.stringify(recentlyViewedProjects)
      );
    } else {
      localStorage.setItem("recentlyViewedProjects", JSON.stringify([project]));
    }
  }
  /**
   * get recently viewed projects in local storage
   * @returns recently viewed projects
   */
  getRecentlyViewedProjects(): ProjectModel[] {
    if (localStorage.getItem("recentlyViewedProjects")) {
      const recentlyViewedProjects = JSON.parse(
        localStorage.getItem("recentlyViewedProjects") as string
      );
      let uniqueValues: ProjectModel[] = Array.from(
        new Map(
          recentlyViewedProjects.map((item: ProjectModel) => [item.id, item])
        ).values() as Iterable<ProjectModel>
      );
      return uniqueValues.slice(0, 3);
    }
    return [];
  }

  getQuotesByProjectId(
    accountId: string,
    projectId: string,
    status: string = ""
  ) {
    let queryParams = new HttpParams();
    if (status) {
      queryParams = queryParams.append("status", status);
    }
    return this._api
      .get(`accounts/${accountId}/projects/${projectId}/quotes`, {
        params: queryParams,
      })
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            
            return res["data"];
          } else {
            return null;
          }
        }),
        catchError((err) => {
          console.error(`error getting project data: ${JSON.stringify(err)}`);
          return of(null);
        })
      );
  }

  // projectMapper(project: ProjectModel) {
  //   const mappedProject = new ProjectModel();
  //   mappedProject.id = project.id;
  //   mappedProject.projectNumber = project.projectNumber;
  //   mappedProject.name = project.name;
  //   mappedProject.category = project.category;
  //   mappedProject.startDate = project.startDate;
  //   mappedProject.endDate = project.endDate;
  //   mappedProject.description = project.description;
  //   mappedProject.category = project.category;
  //   mappedProject.projectType = project.projectType;
  //   mappedProject.stage = project.stage;
  //   mappedProject.status = project.status;
  //   mappedProject.address = project.address;
  //   mappedProject.createdDate = project.createdDate;
  //   mappedProject.lastModifiedDate = project.createdDate;
  //   mappedProject.accountId = project.accountId;
  //   mappedProject.noOfQuotes = project.noOfQuotes;
  //   mappedProject.noOfOrders = project.noOfOrders;
  //   contact: project.contact;
  // }

  setAddress(address: Address) {
    this.addressSignal.set(address);
  }

  createProject(project) {
    return this._api.post(`projects/add-update-project`, project).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error creating project: ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }



  // Assign project to quote and order API

  assignProjectToQuote(projectId: string, quoteIds: string[]) {
    return this._api.post(`projects/assign-project`,{projectId:projectId,quoteIds:quoteIds}).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error creating project: ${JSON.stringify(err)}`);
        return of(null);
      })
    );
  }

  // For archieved day alert.
  checkArchived(quotes){
    const currentDate=new Date();
    return quotes.map(object => {
      const datePart = object.createdDate?.split('T')[0];
      const expiryDate = new Date(object.expiryDate);
      const daysAfter25 = new Date(datePart);
      daysAfter25.setDate(daysAfter25.getDate() + 25); 
    
      const isBetween = currentDate >= daysAfter25 && currentDate <= expiryDate;
      const dayDiff = Math.floor((expiryDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
      return {
          ...object,
          archievedConfirm: isBetween ? dayDiff : 0,
      };
    })


  }

   // General method to detect if the viewport matches the custom media query
   isViewportMatch(query: string): Observable<BreakpointState> {
    return this.breakpointObserver.observe([query]);
  }



}
