<div class="project-location-theme project-location">
  <div class="project-loation-header" fxLayout.md="row wrap" >
    <p class="label">{{screen == "quoteDetails_V2"?"Site":"Project"}} Location<span *ngIf="screen !='quoteDetails_V2'" class="required">*</span> </p>
    <span class="site-location-header">
      Enter a new location or chose from an existing one
    </span>

  </div>
    <div>
      <form [formGroup]="shippingAddressForm" >
      <mat-form-field
        appearance="outline"
        class="pt-2 w-100 prefix-icon-position"
      >
        <mat-icon matIconPrefix  color="primary" (click)="getCurrentLocation()">my_location</mat-icon>
        <input
          matInput
          #input
          placeholder="Enter address / area / landmark"
          type="text"
          #search
          formControlName="currentLocation"
        />
        <mat-error
        *ngIf="shippingAddressForm.controls.currentLocation.errors?.required"
        >Address Required.</mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="w-100 error-display-none">
        <mat-label>Select address from previous location</mat-label>
        <mat-select id="addProductId" class="custom-mat-select" [disabled]="!isProjectActive">
          <mat-option class="custom-mat-option" *ngFor="let address of allAddresses" (click)="selectedAddress(address)"> {{address.street}}, {{address.city}}, {{address.state}}, {{address.zipcode}} </mat-option>
        </mat-select>
      </mat-form-field>
      <p class="location-ins p-3">
        OR drop a pin on the map.
        <span>
          <img src="../../../assets/img/icons/location-pin.svg" alt="location-pin-icon"
        /></span>
      </p>
  
      <div class="project-map">
        <google-map
          [zoom]="zoom"
          [center]="center"
          (zoomChange)="onZoomChange()"
          [options]="options"
          (mapClick)="onMapClick($event)"
        >
          <map-marker
            [position]="markerPosition"
            [options]="markerOptions"
            (mapDragend)="onDragend($event)"
          ></map-marker>
        </google-map>
      </div>
      </form>
    </div>
  </div>
  