<div class="custom-card">
    <div *ngIf="!isEditedData?.message && !isEditedData?.reduceQuantityMessage" class="bg-primary" style="margin-bottom: 1rem" fxLayout="row" fxLayoutAlign="start center">
        <h3 style="padding: 0.5rem 0.8rem">Reject Quote</h3>
    </div>

    <div class="p-2" *ngIf="!isEditedData?.message && !isEditedData?.reduceQuantityMessage">
        <div style="margin-bottom: 0.8rem" class="mr-2 ml-2">
            <p>
                Are you sure you want to reject quote?
                <!-- <span style="font-weight: bold">{{ type }}</span> -->
            </p>
        </div>

        <mat-divider class="my-2 mt-3"></mat-divider>

        <div class="btnDiv">
            <button class="btnsize" mat-button mat-dialog-close>No</button>
            <button class="btnsize" id="yesBtnTestId" mat-raised-button color="primary" (click)="onYes()">Yes</button>
        </div>
    </div>
    <div *ngIf="isEditedData?.message && !isEditedData?.reduceQuantityMessage">
    <div class ="icon_div" >
        <img src="../../../../assets/img/icons/edit-alert.svg" alt="Previous Request of change quantity popup">
    </div>
    <div class="p-1" *ngIf="isEditedData?.message && !isEditedData?.reduceQuantityMessage">
        
        <div  class="centered-text">
            <h3>
               Previous request for change quantity is in progress. 
                <!-- <span style="font-weight: bold">{{ type }}</span> -->
            </h3>

        </div>
        <h3 class="pr-5 pl-5 centered-text">Do you want to request to new change?</h3>

        <mat-divider class="my-2 mt-3"></mat-divider>

        <div class="yesNoDiv">
            <button class="btnsize" mat-button mat-dialog-close>No</button>
            <button class="btnsize" id="yesBtnTestId" mat-raised-button color="primary" (click)="onYes()">Yes</button>
        </div>
    </div>
    </div>
    <div class="p-3" *ngIf="isEditedData?.reduceQuantityMessage">
        
        
        <h3 class="center">{{isEditedData?.reduceQuantityMessage}}</h3>

        <mat-divider class="my-2 mt-3"></mat-divider>
        <div class="center">
            <button  mat-button mat-dialog-close color="primary" (click)="onOk()">Ok</button>

        </div>
        

       
    </div>
</div>