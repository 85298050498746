<div class="custom-card">
  <div class="bg-primary" fxLayout="row" fxLayoutAlign="start center">
    <img
      src="../../../../../assets/img/icons/alert-triangle-white.png"
      alt=""
      class="alert_triangle pl-3"
    />
    <h3 class="p-2">Unsaved changes</h3>
  </div>

  <div class="p-2">
    <div class="mr-2 ml-2">
      <p >
        Added products in quote are not saved as draft.
      </p>
    </div>

    <mat-divider class="my-2 mt-3"></mat-divider>

    <div class="btnDiv">
      <button mat-stroked-button mat-dialog-close>Discard</button>

      <button mat-raised-button color="primary" (click)="continue()">
        Save as Draft
      </button>
    </div>
  </div>
</div>
