<div fxLayout="column" fxLayoutAlign="center center" class="h-100 background">
  <mat-card  class="password-reset-card">
    <mat-card-header>
      <div fxLayoutAlign="center center" style="width: 100%;">
        <img src="../../../../assets/img/USS-Logo-primary.png" [width]="150" alt="United Site Services Logo" />
      </div>
    </mat-card-header>
    <!-- If the email hasn't been sent, show the main content -->
    <mat-card-content *ngIf="!emailSent">
      <div fxLayout="column" fxLayoutAlign="center center" class="h-100">
        <div *ngIf="showMyUSS()" fxLayout="column" fxLayoutAlign="center center">
          <p class="title">Welcome to MySiteServices!</p>
          <p class="pb-3" style="text-align: center;">MySiteServices is the secure United Site Services platform for you to manage your account information, track orders, make payments and
            more.
          </p>
          <p class="title2 pb-3">Enter your email address below and click "Reset Password" to set a new password. You will receive an email with a link to set
            your
            new password and log in to MySiteServices.
          </p>
        </div>
        <div *ngIf="showSidetrade()" fxLayout="column" fxLayoutAlign="center center" class="mt-3">
          <p class="title2 pb-3">Confirm your email address below and click "Set Password". You will receive an email with a link to set your
            new password and log in to {{portalName()}}.
          </p>
        </div>
        <div class="input" fxLayout="row" fxLayoutAlign="center">
          <input matInput placeholder="Email" type="email" [(ngModel)]="email" />
          <button matButton color="primary" (click)="initiatePasswordReset()">Set Password</button>
        </div>
        <div class="mt-3" class="subtext" fxLayout="column" fxLayoutAlign="center">
          <p>
            Why do we need to send you another email? After you click "Set Password" we will send you a link to set your password that is valid for 30
            minutes from when you request it. By completing this extra step, you are ensuring that your data stays safe.
          </p>
        </div>
      </div>
    </mat-card-content>
    <!-- If the email has been sent, show the thank you page -->
    <mat-card-content *ngIf="emailSent">
      <div fxLayout="column" fxLayoutAlign="center center" class="h-100 mt-3">
        <p class="title2 pb-3">
          Thank you! We've sent you an email with a link to set your password. You may close this window.
        </p>
        <div class="mt-3" class="subtext" fxLayout="column" fxLayoutAlign="center">
          <p>
            If you do not receive an email within 5 minutes, please check your spam filter. If the email address entered does not exist for a user in our system, you will not receive an email. 
          </p>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer fxLayout="column" fxLayoutAlign="center center" class="h-100">
      <p class="mt-3 mb-0" style="font-size: medium;">
        Questions? Please call us at <a href="tel:{{ phoneNumber }}" style="cursor: pointer;">{{phoneNumber}}</a>
      </p>
    </mat-card-footer>
  </mat-card>
</div>