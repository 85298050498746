import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-unsaved-changes",
  templateUrl: "./unsaved-changes.component.html",
  styleUrls: ["./unsaved-changes.component.scss"],
})
export class UnsavedChangesComponent {
  constructor(public dialogRef: MatDialogRef<UnsavedChangesComponent>) {}

  continue() {
    this.dialogRef.close(true);
  }
  discard() {
    this.dialogRef.close(false);
  }
}
