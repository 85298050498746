import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { Platform } from "@ionic/angular";

@Directive({
  selector: "[appFixedButton]",
})
export class FixedButtonDirective {
  @Input() public appFixedButton: string;
  @Input() public isActive: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private platform: Platform
  ) { }

  ngOnInit() {
    console.log("paddingBottom", this.appFixedButton);
    this.applyStyles();
    this.applyPadding(this.appFixedButton);
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["isActive"] &&
      this.appFixedButton != "" &&
      this.isActive == true
    ) {
      this.applyPadding(this.appFixedButton);
      console.log("paddingBottom changes", this.appFixedButton);
    }
  }

  @HostListener("window:resize")
  onResize() {
    this.applyStyles();
    this.addBottomPadding();
    // this.applyPadding('50px')
  }

  private applyStyles() {    
    const width = window.innerWidth;

    if (width <= 600) {
      // Adjust the width as needed for your mobile breakpoint

      this.renderer?.setStyle(this.el.nativeElement, "position", "fixed");
      this.renderer?.setStyle(this.el.nativeElement, "width", "100%");
      this.renderer?.setStyle(this.el.nativeElement, "bottom", "46px");
      this.renderer?.setStyle(this.el.nativeElement, "left", "47.5%");
      this.renderer?.setStyle(this.el.nativeElement,"transform","translateX(-50%)");

      this.el.nativeElement?.style?.setProperty("margin", "10px", "important");
      this.renderer?.setStyle(this.el.nativeElement, "z-index", "300");
      this.renderer?.setStyle(this.el.nativeElement,"background-color","white");
      this.renderer?.setStyle(this.el.nativeElement, "padding", "10px 0.5rem");
      this.renderer?.setStyle(this.el.nativeElement, "display", "flex");
      this.renderer?.setStyle(this.el.nativeElement,"justify-content","center");
      this.renderer.addClass(this.el.nativeElement, "pl-2");
      this.renderer.addClass(this.el.nativeElement, "pr-2");

      if (this.platform.is("hybrid")) {
        this.renderer?.setStyle(this.el.nativeElement, "bottom", "0px");
        this.el.nativeElement?.style.setProperty(  "margin-bottom",  "0px",  "important");
        if(this.platform.is("ios")){
          this.renderer.setStyle(this.el.nativeElement, "padding-bottom", "1.5rem");
        }
      }
  } else {
    this.renderer?.removeStyle(this.el.nativeElement, "position");
    this.renderer?.removeStyle(this.el.nativeElement, "bottom");
    this.renderer?.removeStyle(this.el.nativeElement, "left");
    this.renderer?.removeStyle(this.el.nativeElement, "transform");
    this.renderer?.removeStyle(this.el.nativeElement, "margin");
    this.renderer?.removeStyle(this.el.nativeElement, "z-index");
    this.renderer?.removeStyle(this.el.nativeElement, "background-color");
    this.renderer?.removeStyle(this.el.nativeElement, "padding");
  }
}

  private addBottomPadding() {
    const width = window.innerWidth;
    if (width <= 600) {
      this.applyPadding(this.appFixedButton);
    } else {
      this.applyPadding("0px");
    }
  }

  private applyPadding(padding: string) {
    const width = window.innerWidth;
    const parentElement = document.querySelector(
      ".main-content-inner"
    ) as HTMLElement | null;
    if (parentElement) {
      if (width <= 600 && !this.platform.is("hybrid")) {
        this.renderer?.setStyle(parentElement, "padding-bottom", padding);
      }else if(this.platform.is("hybrid") && width <= 600){
        if(this.platform.is("ios")){
          this.renderer?.setStyle(parentElement, "padding-bottom", "90px");
        }else{
          this.renderer?.setStyle(parentElement, "padding-bottom", "30px");
        }
      }
    }
    if(!this.platform.is('hybrid') && width>600 && parentElement){
      this.renderer?.removeStyle(parentElement, "padding-bottom");
    }
  }
  ngOnDestroy() {    
    const parentElement
     = document.querySelector(
      ".main-content-inner"
    ) as HTMLElement | null;

    if (parentElement) {
      this.renderer?.setStyle(parentElement, "padding-bottom", '15px');
    }
  }
}
