<!-- <app-basicinfo></app-basicinfo>


 -->
<section class="wrapper" fxLayout="row" fxLayoutAlign="center center">
  <!-- Side background div -->
  <div fxFlex="0 1 30" fxFlex.sm="0 1 0" fxFlex.xs="0 1 0" class="bg_img"></div>
  <!-- form wrapper -->
  <div
    fxFlex="0 1 70"
    fxFlex.sm="0 1 100"
    fxFlex.xs="0 1 100"
    class="field_wrapper vh-100"
    ngClass.xs="p-0"
    ngClass.lg="py-5 px-6 "
    ngClass.md="py-3 px-6"
    ngClass.sm="p-0"
    fxLayoutAlign="center center"
    fxLayout="column"
    fxLayoutGap="20px"
  >
    <div
      *ngIf="instructionsFlag"
      class="py-4 card_wrapper"
      ngClass.xs="py-1"
      ngClass.sm="py-2"
    >
      <div fxLayoutAlign="center center" fxLayout="column" class="px-4 mx-3">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="logo_header"
          ngClass.xs="mobile"
        >
          <img src="../../../../assets/img/USS-Logo-primary.png" alt="" />
        </div>
        <h2 class="p-2">Create an account</h2>
        <!-- <span> Already have an account? <a href="">Log in</a> </span> -->
      </div>
      <!-- create account form  -->
      <form
        [formGroup]="basicInfoForm"
        class="info_form p-4 mx-3"
        fxLayout="row wrap"
        fxLayoutAlign="center center"
        fxLayoutAlign.sm="center center"
        fxLayoutAlign.xs="center center"
      >
        <!-- First Name -->
        <mat-form-field
          fxFlex="1 0 50"
          fxFlex.xs="1 0 100"
          appearance="outline"
          class="px-2 py-1"
        >
          <mat-label>First Name</mat-label>
          <input
            formControlName="firstName"
            matInput
            (keydown)="helperFun.space($event)"
            oninput="this. value = this. value? this.value[0]?.toUpperCase()+ this.value?.slice(1):this.value"
          />
          <mat-error>First Name is required.</mat-error>
        </mat-form-field>

        <!-- Last Name -->
        <mat-form-field
          fxFlex="1 0 50"
          fxFlex.xs="1 0 100"
          appearance="outline"
          class="px-2 py-1"
        >
          <mat-label>Last Name</mat-label>
          <input
            formControlName="lastName"
            matInput
            (keydown)="helperFun.space($event)"
            oninput="this. value = this. value? this.value[0]?.toUpperCase()+ this.value?.slice(1):this.value"
          />
          <mat-error>Last Name is required.</mat-error>
        </mat-form-field>

        <!-- email -->
        <mat-form-field
          fxFlex="1 0 50"
          fxFlex.xs="1 0 100"
          appearance="outline"
          class="px-2 py-1"
        >
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput />
        </mat-form-field>

        <!-- Phone Number -->
        <mat-form-field
          fxFlex="1 0 100"
          fxFlex.xs="1 0 100"
          class="px-2"
          appearance="outline"
        >
          <mat-label>Phone Number</mat-label>

          <input
            matInput
            #phoneInput
            id="phone"
            (keyup)="onkey(phoneInput.value)"
            [maxLength]="10"
            type="text"
            placeholder="Phone Number"
            formControlName="phone"
          />
          <mat-error *ngIf="basicInfoForm.controls.phone.errors?.required"
            >Phone Number is required.</mat-error
          >
          <mat-error
            *ngIf="basicInfoForm.controls.phone.errors?.validatePhoneNumber"
            >Please enter a valid Phone Number.</mat-error
          >
        </mat-form-field>

        <!-- Customer Type -->
        <mat-form-field
          fxFlex="1 0 50"
          fxFlex.xs="1 0 100"
          appearance="outline"
          class="px-2 py-1"
        >
          <mat-label>Customer Type</mat-label>
          <mat-select
            (selectionChange)="getSelectedCustomerType($event.value)"
            formControlName="customerSegment"
          >
            <mat-option
              *ngFor="let customerType of customerTypeList"
              [value]="customerType"
              >{{ customerType }}</mat-option
            >
          </mat-select>
          <mat-error>Please select the Customer Type.</mat-error>
        </mat-form-field>

        <!-- Business Type -->
        <mat-form-field
          fxFlex="1 0 50"
          fxFlex.xs="1 0 100"
          appearance="outline"
          class="px-2 py-1"
        >
          <mat-label>Business Type</mat-label>
          <mat-select formControlName="businessType">
            <mat-option
              *ngFor="let businessTypeObj of businessTypeBindList"
              [value]="businessTypeObj.businessType"
              >{{ businessTypeObj.businessType }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="basicInfoForm.controls.businessType.errors?.required"
            >Please select the Business Type.</mat-error
          >
          <!-- <mat-error
              *ngIf="
                basicInfoForm.controls.Business_Type.touched &&
                businessTypeBindList.length === 0 &&
                !basicInfoForm.value.Customer_Segment
              "
              >Please select Customer Type first
            </mat-error> -->
        </mat-form-field>

        <!-- Company Name -->
        <mat-form-field fxFlex="1 0 100" appearance="outline" class="px-2 py-1">
          <mat-label>Company Name</mat-label>
          <input
            formControlName="accountName"
            matInput
            (keydown)="helperFun.space($event)"
            oninput="this. value = this. value? this.value[0]?.toUpperCase()+ this.value?.slice(1):this.value"
          />
          <mat-error>Company Name is required.</mat-error>
        </mat-form-field>

        <div fxLayout="column" fxLayoutAlign="center start" class="px-2 p-1">
          <mat-checkbox color="primary" formControlName="privacy"
            >By creating an account, you agree to the
            <a
              href="https://www.unitedsiteservices.com/legal-terms-conditions"
              target="_blank"
              id="termsLink"
              >Terms of use</a
            >
            and
            <a
              href="https://www.unitedsiteservices.com/privacy-policy"
              target="_blank"
              id="privacyLink"
              >Privacy Policy.</a
            >
          </mat-checkbox>
          <mat-error
            class="px-2 error_privacy"
            *ngIf="
              basicInfoForm.value.privacy === false &&
              basicInfoForm.controls.privacy.touched
            "
          >
            Please accept the Terms of Use & Privacy Policy.
          </mat-error>
        </div>

        <button
          class="round_btn button"
          fxFlex="0 1 90"
          mat-raised-button
          color="primary"
          (click)="createAccount()"
        >
          Create Account
        </button>
      </form>
    </div>

    <div *ngIf="!instructionsFlag" class="messasge_card">
      <!-- <div fxLayoutAlign="center center" fxLayout="column" class="px-4 mx-3"> -->
      <div
        fxLayout="column"
        fxLayoutAlign="start"
        class="logo_header"
        ngClass.xs="mobile"
      >
        <img src="../../../../assets/img/USS-Logo-primary.png" alt="" />

        <p class="headers pt-4">Completing Account Setup</p>
      </div>
      <div class="pb-4">
        <h5 class="sub_header">
          Follow below instructions to set your password.
        </h5>
      </div>
      <mat-divider class="divider_width pb-4"></mat-divider>
      <p class="text pb-4">
        1. Please check your registered email to set your password.
      </p>
      <p class="text pb-4">2. Click on the set password link.</p>
      <p class="text pb-4">3. Enter new password and confirm password.</p>
      <p class="text pb-5">
        4. Login using registered email and password and continue to quote
        creation process.
      </p>
      <div class="text pb-5" fxLayoutAlign="start">
        <p>
          For any issues and queries
          <span class="contact_text pl-1">
            contact support-

            <a href="tel:{{ phoneNumber }}" class="phone-number">
              {{ phoneNumber }}.
            </a>
          </span>
        </p>
      </div>

      <button
        class="round_btn button"
        mat-raised-button
        color="primary"
        (click)="signIn()"
      >
        Sign In
      </button>
    </div>

    <!-- </div> -->
  </div>
</section>
<div
  *ngIf="loader"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="user-spinner"
>
  <mat-spinner color="primary" [diameter]="50"></mat-spinner>
</div>
