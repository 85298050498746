import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
// import { AuthService } from '@auth0/auth0-angular';
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { Platform } from "@ionic/angular";

export const authGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const platform = inject(Platform);
  const authService = inject(AuthenticationService);
  const isNative = platform.is("hybrid");
const isAuthenticated=await authService.isAuthenticated()

  if (!isAuthenticated) {
    if (isNative) {
      router.navigate(["/landing-screen"]);
    } else {
      authService.login();
    }
  }
  return isAuthenticated;
};
