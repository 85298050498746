import { Account } from "./account-model";
import { Address, GetAddressModel } from "./address-model";
import { JobsiteModel } from "./jobsite-model";
import { BundlesForm } from "./product-model";
import { UserProfile } from "./profile-model";
import { ProjectModel } from "./project-model";
import { QuoteLineModel } from "./quote-line-model";
import { QuoteModel } from "./quote-model";

export class QuoteState {
  accountId: string;
  currentStep: number;
  quoteId: string | null;
  quoteName : string | null;
  userProfile: Partial<UserProfile> | null;
  address: Address;
  customerType: string;
  businessType: string;
  stripeCustomerId: string | null;
  siteDetailFlag?: boolean;
  paymentMethodId: string;
  isAutoPay: boolean;
  isPdfGenerated: boolean;


  quoteModel: QuoteModel;

  step1: Step1; //unit and services
  step2: {
    status: string;
  };
  step3: {
    contactData: Contact | null;
    SiteDetails: SiteDetails | null;
  };
  step4: Step4;
  step5: {
    status: string;
  };

  constructor() {
    this.currentStep = 0;
    this.isPdfGenerated= false
    this.quoteId = "";
    this.quoteName = "";
    this.userProfile = null;
    this.accountId = "";
    this.stripeCustomerId = "";
    this.paymentMethodId = "";
    this.isAutoPay = true;
    this.address = {
      accountId: "",
      addressId: "",
      city: "",
      country: "",
      shipToAddress: true,
      state: "",
      street: "",
      zipcode: "",
      siteName: "",
      latitude: 0,
      longitude: 0,
      addressExist: false,
    };
    this.businessType = "";
    this.customerType = "";
    this.step1 = {
      startDate: "",
      endDate: "2049-12-31",
      estimatedEndDate:"",
      orderType: "Recurring without End Date",
      zipcode: "",
      duration: "",
      billTiming: "Bill in Advance",
      billingPeriod: "28 Day Bill Period",
      prodSubType: "Renewable",
      productDetails: [],
      documentId: "",
      pdfName: "",
    };

    this.step2 = {
      status: "",
    };
    this.step3 = {
      contactData: {
        contactId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      SiteDetails: {
        startTime: null,
        endTime: null,
        gateCode: null,
        instructions: null,
        information: null,
        idRequired: false,
        clearanceRequired: false,
        accountId: "",
        addressId: "",
        city: "",
        country: "",
        shipToAddress: true,
        state: "",
        street: "",
        zipcode: "0",
        siteName: "",
        latitude: 0,
        longitude: 0,
        addressExist: false,
      },
    };
    this.step4 = {
      // billingContact
      contactRefId: "",
      billingAddress: null,
      poNumber: "",
      secondaryContactData: {
        contactId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      secondaryContactId: "",
    };
    this.step5 = {
      status: "",
    };

    this.quoteModel = {
      quoteId: "",
      quoteName: "",
      quoteNumber: "",
      quoteDate: "",
      account: null,
      startDate: "",
      endDate: "",
      //opportunity=Opportunity;
      priceBookId: "",
      primaryContact: {
        contactId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      billToContact: {
        contactId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      primarySiteContact: {
        contactId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      shipToContact: {
        contactId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      shippingAddress: {
        accountId: "",
        addressId: "",
        city: "",
        country: "",
        shipToAddress: true,
        state: "",
        street: "",
        zipcode: "",
        siteName: "",
        latitude: 0,
        longitude: 0,
        addressExist: false,
      },
      billTiming: "",
      billingPeriod: "",
      declineDamageWaiver: false,
      orderType: "",
      autoPay: false,
      billToAddress: {
        accountId: "",
        addressId: "",
        city: "",
        country: "",
        shipToAddress: true,
        state: "",
        street: "",
        zipcode: "",
        siteName: "",
        latitude: 0,
        longitude: 0,
        addressExist: false,
      },
      billingComplete: false,
      orderedinSalesforce: false,
      invoiceDeliveryMethod: "",
      paymentMode: "",
      preOrderFlowComplete: false,
      siteComplete: false,
      billingApprovalStatus: "",
      facilityName: "",
      subdivisionName: "",
      status: "",
      ordered: false,
      billCycleDay: "",
      chargeType: "",
      eECPercent: 0,
      eSFPercent: 0,
      fuelSurchargePercent: 0,
      lastBillingDate: "",
      legalEntity: "",
      legalEntityCode: "",
      branchCode: "",
      BusinessType: "",
      jobSites: [],
      creditCardPaymentStatus: "",
      billingSystem: "",
      cPQTemplate: "",
      enableServerSidePricingCalculation: false,
      taxEntityUseCode: "",
      avaTaxCompanyCode: "",
      aVASFCPQIsSellerImporterOfRecord: false,
      avaSalesTaxAmount: 0,
      avaSalesTaxMessage: "",
      duration: "",
      createdById: "",
      createdDate: "",
      expirationDate: "",
      sourceSystem: "",
      quoteReferenceNumber: "",
      ussContact: {
        contactId: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        id: "",
        accountId: "",
        title: "",
        fullName: "",
      },
      oneTimeSubtotal: 0,
      oneTimeTax: 0,
      oneTimeTotal: 0,
      recurringSubtotal: 0,
      recurringTax: 0,
      recurringTotal: 0,
      quoteLines: [],
      quoteDocuments: [],
      projectDetails: new ProjectModel()
    };
  }
}

export class Contact {
  contactId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  id?: string;
  accountId?: string;
  title?: string;
  fullName?: string;
}

// export class Address {
//   accountId: string;
//   addressId: string;
//   city: string;
//   country: string;
//   shipToAddress?: boolean;
//   state: string;
//   street: string;
//   zipcode: number;
//   siteName?: string;
//   latitude: number;
//   longitude: number;
//   addressExist?: boolean;
// }

export class SiteDetails extends Address {
  override startTime?: string | null;
  override endTime?: string | null;
  override gateCode?: string | null;
  instructions?: string | null;
  information?: string | null;
  override idRequired?: boolean;
  clearanceRequired?: boolean;
}

export class Step1 {
  startDate: string;
  endDate: string;
  estimatedEndDate: string;
  orderType: string;
  zipcode: string;
  duration: string;
  billTiming: string;
  billingPeriod: string;
  prodSubType: string;
  productDetails: BundlesForm[] | [];
  documentId: string | null;
  pdfName: string | null;
}

export class Step3 {
  contactData: Contact | null;
  SiteDetails: SiteDetails | null;
}

export class Step4 {
  contactRefId: string;
  billingAddress: BillingAddress | null;
  secondaryContactData: Contact | null;
  secondaryContactId: string;
  //secondaryContactExist: boolean;
  poNumber?: string;
}
export class BillingAddress {
  addressRefId?: string;
  addressExist?: boolean;
  address: string;
  street?: string;
  city: string;
  state: string;
  zipcode: string;
  country?:string;
}

export class ConfirmQuoteModel {
  requestId: string;
  quoteId: string;
  isAutoPay: boolean;
  paymentMethodId: string;
  projectId: string;
  projectStatus: string;
}
