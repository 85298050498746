<div
  id="app"
  class="app"
  [dir]="settings.rtl ? 'rtl' : 'ltr'"
  [ngClass]="settings.theme"
  [class.fixed-header]="settings.fixedHeader"
  [class.fixed-sidenav]="settings.fixedSidenav"
  [class.fixed-footer]="settings.fixedFooter"
  [class.horizontal-menu]="settings.menu == 'horizontal'"
  [class.compact]="settings.menuType == 'compact'"
  [class.mini]="settings.menuType == 'mini'"
>
  <router-outlet></router-outlet>
  <div id="app-spinner" [class.hide]="!settings.loadingSpinner">
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>

  <!-- loader -->
  <div
    *ngIf="loader"
    fxLayout="column"
    fxLayoutAlign="center center"
    class="user-spinner"
  >
    <div class="p-3" [ngClass]="isMessage ? 'bg_tile' : ''">
      <mat-spinner color="primary" [diameter]="50"></mat-spinner>
      <!-- <h3 class="black pt-2" *ngIf="isMessage">Please wait</h3> -->
      <div *ngIf="isMessage" fxLayout="column" fxLayoutAlign="start center">
        <h3 class="py-1 black">{{ message }}</h3>
        <p class="refresh-mesage">
          This may take up to a minute. Please do not refresh this page.
        </p>
        <!-- <div class="col-3 px-4 pt-2">
        <div class="snippet" data-title="dot-pulse">
          <div class="stage">
            <div class="dot-pulse"></div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</div>
