import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ConfigService } from "../shared/services/config/config.service";

export const rfqGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const configService = inject(ConfigService)
  const comingFlag =   configService.getConfigProperty("COMING_SOON")

if(comingFlag) {
  return router.navigate(["comingsoon"]);
}else {
  return  true;
}
    
     
   
};
