import { Injectable, signal } from "@angular/core";
import { HttpBackend, HttpClient, HttpParams } from "@angular/common/http";
import { catchError, firstValueFrom, map, of } from "rxjs";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import { ToastrService } from "ngx-toastr";
import { LoadingService } from "../../../shared/services/loading/loading.service";
import { Drafts } from "../../../models/draft-model";
import { Notification } from "src/app/models/notification";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  private readonly mapJSON = "assets/config/mapStyle.json";
  private readonly steps = "assets/config/steps.json";
  public notificationClickSignal= signal<boolean>(false)
  handler: HttpBackend;
  constructor(
    handler: HttpBackend,
    private api: UssApiService,
    private toastr: ToastrService,
    private loadingService: LoadingService
  ) {
    this.handler = handler;
  }

  async loadMapStyle() {
    const client = new HttpClient(this.handler);
    const styles = await firstValueFrom(
      client.get<google.maps.MapTypeStyle[]>(this.mapJSON)
    );
    return styles;
  }
  async getSteps() {
    const client = new HttpClient(this.handler);
    const steps = await firstValueFrom(client.get<string[]>(this.steps));
    return steps;
  }

  /**
   * Gets all quotes
   * @param id
   */
  getAllQuotes(id: string,projectId?: string) {
    // this.loadingService.setLoader(true);
    let queryParams = new HttpParams();
    if(projectId){
      queryParams=queryParams.append("projectId", projectId)
    }

    return this.api.get(`accounts/${id}/drafts`,{
      params: queryParams,
    }).pipe(
      map((res) => {
        if (res["status"] === 200 || res["status"] === 1000) {
          if (res["data"]?.length == 0) {
            return null;
          }
          return res["data"] as Drafts;
        } 
        return null;
      }),
      catchError((err) => {
        console.error(`error getting all quotes : ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        throw err;
      })
    );
  }
  /**
   * Gets notification
   * @param id
   * @returns
   */
  getNotification(accountId: string,daysRange:number) {
    
    return this.api.post("notifications", {accountId: accountId,daysRange: daysRange} ).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          if(res["data"]["notifications"].length > 0){
            return res["data"]["notifications"] as Notification[];
          }
          return null;
        }
        this.loadingService.setLoader(false);
        return null;
      }),
      catchError((err) => {
        console.error(`error getting all quotes : ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        return of(null);
      })
    );
  }

  getDashboardDetails(id: string) {
    return this.api.get(`accounts/${id}/dashboard-details`).pipe(
      map((res) => {
        if (res["status"] === 1000) {
          
          return res["data"] ;
        }
        this.loadingService.setLoader(false);
        return null;
      }),
      catchError((err) => {
        console.error(`error getting all quotes : ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        return of(null);
      })
    );
  }

    /**
   * Gets all quotes
   * @param id
   */
    getAllQuotesForV2(id: string,projectId?: string,quoteStatus="") {
      // this.loadingService.setLoader(true);
      let queryParams = new HttpParams();
      if(projectId){
        queryParams=queryParams.append("projectId", projectId)
      }
  
      return this.api.get(`accounts/${id}/drafts?status=${quoteStatus}`,{
        params: queryParams,
      },'v2').pipe(
        map((res) => {
          if (res["status"] === 200 || res["status"] === 1000) {
            if (res["data"]?.length == 0) {
              return null;
            }
            return res["data"] as Drafts;
          } 
          return null;
        }),
        catchError((err) => {
          console.error(`error getting all quotes : ${JSON.stringify(err)}`);
          this.loadingService.setLoader(false);
          throw err;
        })
      );
    }

    updateNotificationClickSignal(val:boolean){
      this.notificationClickSignal.set(val)
    }
}
