import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { SignupModel, businessType } from "src/app/models/signup";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { RfqService } from "src/app/features/landing/services/rfq/rfq.service";
import { UssApiService } from "src/app/features/myuss/services/uss-api/uss-api.service";
import { HelperFunctionService } from "src/app/theme/utils/helper-function.service";
import { phoneNumber } from "src/assets/config/constants";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";


@Component({
  selector: 'app-create-account-without-auth',
  templateUrl: './create-account-without-auth.component.html',
  styleUrls: ['./create-account-without-auth.component.scss']
})
export class CreateAccountWithoutAuthComponent {

  basicInfoForm: FormGroup;
  public settings: Settings;
  loader: boolean = false;
  instructionsFlag: boolean = true;
  phoneNumber: string;

  separateDialCode = true;
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  // PhoneNumberFormat = PhoneNumberFormat;
  // preferredCountries: CountryISO[] = [
  //   CountryISO.UnitedStates,
  //   CountryISO.UnitedKingdom,
  // ];
  /**
   * Customer type list
   */
  customerTypeList: string[] = [
    "Business",
    "Charity",
    "Consumer",
    "Government",
  ];
  /**
   * Business type list
   */
  businessTypeList: businessType[];
  businessTypeBindList: businessType[] = [];
 

  constructor(
    private fb: FormBuilder,
    public appSettings: AppSettings,
    public helperFun: HelperFunctionService,
    private profileService: ProfileService,
    private api: UssApiService,
    private auth: AuthenticationService,
    public router: Router,
    public toastr: ToastrService,
    private rfqService: RfqService
  ) {
    this.settings = this.appSettings.settings;
    // form group
    this.basicInfoForm = fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: [""],
      phone: ["", [Validators.required]],
      customerSegment: ["", [Validators.required]],
      businessType: ["", [Validators.required]],
      accountName: ["", [Validators.required]],
      privacy: [false, [Validators.required]],
    });

    this.phoneNumber = phoneNumber;
   
  }

  async ngOnInit(): Promise<void> { 
    const picklist = await this.profileService.getBusinessAndCustomerPicklist();
    this.customerTypeList = picklist["customerPicklist"] as string[];
    this.businessTypeList = picklist["businessPicklist"] as businessType[];

    if(localStorage.getItem('rfqId')){
     
      this.rfqService.getRFQDetails().subscribe((rfq) => {
        console.log(rfq);
        if (rfq) {
          this.getSelectedCustomerType(rfq.purposeOfRental);
          let businessType = this.businessTypeBindList.filter(
            (el: businessType) => el.businessType === rfq.useType
          );
          this.basicInfoForm.patchValue({
            firstName: rfq.firstName,
            lastName: rfq.lastName,
            phone: rfq.phoneNumber,
            customerSegment: rfq.purposeOfRental,
            businessType: businessType,
            accountName: rfq.companyName,
            email: rfq.emailAddress,
          });
          this.basicInfoForm.controls["email"].disable();
        }
       
      })
}



   }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }
  /**
   * Gets selected customer type
   * @param customerType
   */
  getSelectedCustomerType(customerType: string) {
    // console.log(customerType);
    this.businessTypeBindList = this.businessTypeList.filter(
      (el: businessType) => el.customerType === customerType
    );
    // if business type has only one prefilled it
    this.basicInfoForm.patchValue({
      businessType: "",
    });

    if (this.businessTypeBindList.length === 1) {
      this.basicInfoForm.patchValue({
        businessType: this.businessTypeBindList[0].businessType,
      });
    }

    // for update validation for company name

    const consumer = this.basicInfoForm.controls["accountName"];
    consumer.setValidators([Validators.required]);
    consumer.updateValueAndValidity();
    if (customerType === "Consumer") {
      consumer.setValidators([]);
      consumer.updateValueAndValidity();
    }
  }

  /**
   * Creates account
   */
  createAccount() {
    if (this.basicInfoForm.valid && this.basicInfoForm.value.privacy) {
      // this.instructionsFlag = false;
      this.createAccountHandler();
    } else {
      this.basicInfoForm.markAllAsTouched();
    }
  }
  /**
   * Phones number
   * @param event
   */

  onkey(e) {
    this.basicInfoForm.patchValue({ phone: e.replace(/[^0-9]/g, "") });
  }


  // phoneNumber(event: KeyboardEvent) {
  //   const input = event.target as HTMLInputElement;
  //   const invalidChars = ["-", "+", "e", ".", "E"];
  //   if (
  //     invalidChars.includes(event.key) ||
  //     (input.value.length >= 15 && !isNaN(Number(event.key)))
  //   ) {
  //     event.preventDefault();
  //   }
  // }

  /**
   * Creates account handler that calls api
   */
  async createAccountHandler() {
    if (!window.navigator.onLine) {
      this.toastr.error("Check internet connectivity");
      return;
    }
    // this.loader = true;
    let reqBody: SignupModel = {
      ...this.basicInfoForm.getRawValue(),
    };
    const data = await this.auth.idTokenClaims();
    // reqBody.email = data?.email;
    // reqBody.auth0Id = data?.sub;
    reqBody.accountName = reqBody.accountName;
    reqBody.name = reqBody.email;

    delete reqBody.privacy;
    if (reqBody.businessType === "Consumer") {
      reqBody.accountName = reqBody.firstName;
    }
    
    // user signup api call
    this.rfqService.createUser(reqBody).subscribe((res)=>{
      console.log(res)
      if(res.status == 409){
        this.toastr.error("User already exists.");
      }
      if(res.message == "Success"){
        this.instructionsFlag = false;
        console.log("success")
        this.toastr.success("User created successfully.");
      }
      if(res.message == "Fail"){
          this.toastr.error("User creation failed.");
      }
    })
  }

  signIn(){
    this.router.navigate(['loading']); 
  }

  //  fetchUserDetails() {
  //   this.loader = true;

  //   // const data = await firstValueFrom(this.auth.idTokenClaims$);
  //   this.api.post(`user`, { Id: localStorage.getItem('authID') }).subscribe({
  //     next: (res) => {
  //       if (res["status"] === 200) {
  //         // navigates to home
  //         localStorage.setItem("registred", "true");
  //         localStorage.setItem("firstName", res.data.firstName);
  //         localStorage.setItem("userId", res.data.accountId);
  //         localStorage.setItem("accountNumber", res.data.accountNumber);
  //         if(localStorage.getItem("rfqId")){
  //           this.rfqService.getRFQDetails().subscribe((res) => {
  //             console.log(res.emailAddress)
  //             if(res.emailAddress === localStorage.getItem("email")){
  //               console.log("rfqId",localStorage.getItem("rfqId"))
  //               this.router.navigateByUrl('/quotes/siteaddressvalidation');
  //             }else{
  //               this.router.navigateByUrl("/home");
  //             }
  //           })
           
    
  //         }else{

  //           this.router.navigateByUrl("/home");
  //         }

  //         // this.router.navigateByUrl("/home");
  //       } else {
  //         // console.log("User:", res["data"] as UserProfile);
  //         console.error(res["message"]);
  //       }
  //       this.loader = false;
  //     },
  //     error: (err) => {
  //       console.error(err["message"]);
  //       this.loader = false;
  //     },
  //   });
  // }
}
