import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from "src/app/app.settings";
import { Settings } from "src/app/app.settings.model";
import { phoneNumber } from "src/assets/config/constants";

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent {

  public settings: Settings;
  comingSoonRoute: boolean = false;
  phoneNumber: string;

  constructor(public appSettings: AppSettings,private _Activatedroute: ActivatedRoute,private router: Router ) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.phoneNumber = phoneNumber;
this._Activatedroute.url.subscribe(url => {
  console.log("path",url);
  if(url[0].path == 'comingsoon'){
    this.comingSoonRoute = true;
  }
})
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }



  signIn(){
    this.router.navigate(['loading']); 
  }

}
