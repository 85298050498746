import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "addressFormat",
})
export class AddressFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // If the string is just a comma or only contains "null", return an empty string
    const cleanedValue = value
      .replace(/null/g, "")
      .replace(/,,/g, ",")
      .replace(/,\s*,/g, ", ")
      .trim();
    return cleanedValue === "," || cleanedValue === "" ? "" : cleanedValue;
  }
}
