import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { CaseModel } from "src/app/models/case-details-model";
import {
  descriptionTableHeader,
  phoneNumber,
  serviceFrequency,
  timeZoneMapper,
} from "src/assets/config/constants";
import { ConfigService } from "../../services/config/config.service";
import { ToastrService } from "ngx-toastr";
import { CaseDetailsStateService } from "src/app/features/cases/services/case-details-state.service";
import { CaseSuccessComponent } from "../dialogbox/case-success/case-success.component";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { debounceTime, firstValueFrom } from "rxjs";
import { GoogleMapsService } from "../../services/google/google-maps.service";
import { DatePipe } from "@angular/common";
import { WeekendDayValidationPopupComponent } from "../dialogbox/weekend-day-validation-popup/weekend-day-validation-popup.component";
import { OrderChangeService } from "src/app/features/orders/services/order-change.service";
import { MatOptionSelectionChange } from "@angular/material/core";

@Component({
  selector: "app-select-location-to-move",
  templateUrl: "./select-location-to-move.component.html",
  styleUrls: ["./select-location-to-move.component.scss"],
})
export class SelectLocationToMoveComponent {
  @Input() caseData;
  @Input() orderData;
  @Input() changeFrequencyFlag: boolean = false;
  pipe = new DatePipe("en-US");

  selectedLocation: FormGroup;
  address: FormGroup;
  caseDetails: FormGroup;
  siteName: string[] = [];
  @Output() addDetailsDrawer = new EventEmitter<boolean>();
  startDateMin: Date = new Date();
  serviceFrequency = serviceFrequency;
  caseId: string;

  constructor(
    private configService: ConfigService,
    private dialog: MatDialog,
    private _router: Router,
    private toastr: ToastrService,
    public gMapService: GoogleMapsService,
    private profileService: ProfileService,
    private caseService: CaseDetailsStateService,
    private orderChangeService: OrderChangeService,
    private fb: FormBuilder
  ) {
    this.selectedLocation = this.fb.group({
      select: ["Choose from existing location"],
    });

    this.address = this.fb.group({
      siteAddress: [""],
    });

    this.caseDetails = this.fb.group({
      estimatedDate: ["", [Validators.required]],
      additionalInstrcutions: ["", Validators.required],
      unitNumber: [""],
      changeFrequency: [""],
    });
  }

  numberCommaValidator(): ValidatorFn {
    const numberCommaPattern = /^[0-9]{8,10}(,[0-9]{8,10})*$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = numberCommaPattern.test(control.value);
      return valid ? null : { numberComma: { value: control.value } };
    };
  }

  ngOnInit(): void {
    this.caseDetails
      .get("unitNumber")
      ?.valueChanges.pipe(debounceTime(300))
      .subscribe((value) => {
        if (this.caseDetails.value.unitNumber) {
          this.caseDetails.controls["unitNumber"].setValidators([
            this.numberCommaValidator(),
          ]);
          this.caseDetails.controls["unitNumber"].updateValueAndValidity();
        } else {
          this.caseDetails.controls["unitNumber"].setValidators([]);
          this.caseDetails.controls["unitNumber"].updateValueAndValidity();
        }
      });
  }

  ngOnChanges() {
    this.caseDetails.patchValue({
      unitNumber: this.caseData?.unitNumber?.join(","),
    });
    // this.changeFrequencyFlag = false;
    this.caseData?.siteAddress.locations?.forEach((site) => {
      if (
        this.caseData?.screen == "Move" ||
        this.caseData?.screen == "Change Service Frequency"
      ) {
        if (site.bundleArray.every((bundle) => !bundle.bundleChecked)) {
          this.siteName.push(site.siteName);
        }
        // if (site.siteChecked) {
        //   // this.changeFrequencyFlag = true;
        // }
      }
      if (this.caseData?.screen == "editOrder") {
        if (!this.siteName.includes(site.siteAddress)) {
          this.siteName.push(site.siteAddress);
        }
      }
    });

    this.selectedLocation.valueChanges.subscribe((value) => {
      this.address.reset();
    });

    if (this.siteName.length === 0) {
      this.selectedLocation.patchValue({
        select: "Add new location",
      });
    }

    if (
      this.caseData?.screen == "editOrder" ||
      this.caseData?.screen == "Move"
    ) {
      this.address.controls["siteAddress"].setValidators([Validators.required]);
      this.address.controls["siteAddress"].updateValueAndValidity();
    }
    if (
      this.caseData?.screen == "Change Service Frequency" &&
      this.changeFrequencyFlag
    ) {
      this.caseDetails.controls["changeFrequency"].setValidators([
        Validators.required,
      ]);
      this.caseDetails.controls["changeFrequency"].updateValueAndValidity();
    }
  }

  async onNext() {
    if (this.caseDetails.valid && this.address.valid) {
      const data: CaseModel = new CaseModel();
      let changeType:
        | "Move"
        | "Add New Unit Type"
        | "Change Service Frequency"
        | "Change Quantity - Increase"
        | "Change Quantity - Decrease" = "Move";
      // First handle all the common fields
      data.accessNotes = "";
      data.contactId = this.profileService.selectedAccount()
        .contactId as string;
      data.accountId = this.profileService.selectedAccount()
        .accountId as string;
      data.selectedDate =
        this.pipe.transform(
          `${this.caseDetails.value.estimatedDate}`,
          "yyyy-MM-dd"
        ) || "";

      data.siteCityName = `${this.orderData?.siteDetails.city}`;
      data.siteContactName = `${this.orderData?.siteDetails.firstName} ${this.orderData?.siteDetails.lastName}`;
      data.siteContactPhone = `${this.orderData?.siteDetails.phone}`;
      data.siteStateName = `${this.orderData?.siteDetails.state}`;
      data.orderName = `${this.orderData?.quoteName?.slice(2)}`;
      data.zipcode = `${this.orderData?.siteDetails.zipcode}`;
      data.unitNumbers = this.caseDetails.value.unitNumber
        ? this.caseDetails.value.unitNumber.split(",")
        : [];
      data.placementNote = this.caseDetails.value.additionalInstrcutions;

      if(this.orderChangeService.allUnitsChecked && this.caseData?.screen === "Change Service Frequency") {
       const caseDataCopy = this.caseData?.data.map((item)=> {
        const formattedItem = item
        formattedItem["Service Frequency"] = formattedItem["Service Frequency"] + ' > ' + this.caseDetails.value.changeFrequency;
          return formattedItem
        });
        this.caseData.data = caseDataCopy
      }
      
      const description = {
        Details: descriptionTableHeader,
        Data: this.caseData?.data,
      };
      data.productInfo = JSON.stringify(description);

      const timeZoneResponse = await firstValueFrom(
        this.gMapService.getTimeZone(
          this.orderData?.siteDetails.latitude,
          this.orderData?.siteDetails.longitude
        )
      );
      data.caseRegion = timeZoneMapper[timeZoneResponse.timeZoneName];
      const effectiveDateFormattedForNotes =
        this.pipe.transform(
          `${this.caseDetails.value.estimatedDate}`,
          "MM/dd/yyyy"
        ) || "";
      // Handle the change service frequency case
      if (this.caseData?.screen === "Change Service Frequency") {
        changeType = "Change Service Frequency";
        data.caseSubType = "Quantity/Frequency Change";
        data.siteAddress = `${this.orderData?.siteDetails.address}`;
        data.summary = this.orderChangeService.formatFrequencyChangeSummary(
          data,
          effectiveDateFormattedForNotes
        );
        if (this.changeFrequencyFlag) {
          this.caseData?.data.forEach((site) => {
            site["Service Frequency"] = this.caseDetails.value.changeFrequency;
          });
        }
      }
      // Add New Unit Type
      if (this.caseData?.screen === "editOrder") {
        changeType = "Add New Unit Type";
        data.caseSubType = "Quantity/Frequency Change";
        data.siteAddress = this.address.value.siteAddress;
        data.summary = this.orderChangeService.formatCaseSummary(
          changeType,
          data,
          effectiveDateFormattedForNotes
        );
      }
      // Move
      if (this.caseData?.screen === "Move") {
        changeType = "Move";
        data.caseSubType = "Move";
        data.siteAddress = this.address.value.siteAddress;
        data.summary = this.orderChangeService.formatCaseSummary(
          changeType,
          data,
          effectiveDateFormattedForNotes
        );
      }
      data.myussCaseType = changeType;
      console.log(data);

      this.createCase(data);
    } else {
      this.caseDetails.markAllAsTouched();
      this.address.markAllAsTouched();
      return;
    }
  }

  // if the frequency is selected in the top level menu, set the frequency for all selected bundles
  setServiceFrequencyForAllSelected($event: MatOptionSelectionChange<string>) {
    const newFrequency = $event.source.value;
    this.orderChangeService.selectedBundlesBySite.forEach((site) => {
      site.bundleArray.forEach((bundle) => {
        if (bundle.bundleChecked) {
          bundle.changeFrequency = newFrequency;
        }
      });
    });
  }

  onCancel() {
    this.addDetailsDrawer.emit(false);
  }

  onDateSelect(event) {
    this.checkDayOfWeek(event);
    const ogDate = new Date(new Date().setHours(0, 0, 0, 0));
    const changedDate = ogDate.setDate(
      ogDate.getDate() + this.configService.getConfigProperty("DATE_GAP")
    );
    if (event.value < new Date(changedDate)) {
      this.toastr.error(
        `You need to contact ${phoneNumber} for urgent request schedule`
      );
      this.caseDetails.patchValue({
        estimatedDate: "",
      });
    }
  }

  checkDayOfWeek(event) {
    const selecteddate = new Date(event.value);
    const dayOfWeek = selecteddate.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      this.dialog.open(WeekendDayValidationPopupComponent, {
        panelClass: "limit-exceed-dialog",
      });
      this.caseDetails.patchValue({
        changeDate: selecteddate,
      });
    }
  }

  createCase(data: CaseModel) {
    this.caseService
      .createCase(data, `${this.profileService.selectedAccount().accountId}`)
      .subscribe((res) => {
        if (res) {
          this.caseId = res.data?.caseId;
          const box = this.dialog
            .open(CaseSuccessComponent, {
              //minWidth: "50%",
              disableClose: true,
              data: {
                id: res.data?.caseId,
                type: "case",
              },
              panelClass: "case-success-dialog",
            })
            .afterClosed()
            .subscribe((res) => {
              if (res === "ok") {
                this._router.navigate(["orders/case-details", this.caseId]);
              } else {
                this._router.navigate(["/cases"]);
              }
            });
        }
      });
  }
}
