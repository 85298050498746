   <div fxLayout="row" class="breadcrumb p-2">
    <a routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" (click)="closeSubMenus()">
        <span class="breadcrumb-title">Home</span>
    </a>
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item" >                 
        <ng-container *ngIf="!last">
            
            <a class="breadcrumb-item"  [routerLink]="breadcrumb.url" (click)="removeBreadcrumbByIndex(breadcrumb)">{{ breadcrumb.name?breadcrumb.name:breadcrumb.breadcrumbData }}</a>
          </ng-container>
      
          <ng-container *ngIf="last">
          
            <a class="breadcrumb-item">{{ breadcrumb.name?breadcrumb.name:breadcrumb.breadcrumbData }}</a>
    
            </ng-container>
    </ng-container> 
</div>
  
  
  
  
  
  