import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RfqComponent } from './components/rfq/rfq.component';
import { BaseComponent } from './components/base/base.component';
import { UserInviteComponent } from './components/user-invite/user-invite.component';
import { ZipSearchComponent } from './components/zip-search/zip-search.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { RouterModule } from '@angular/router';
import { routes } from './landing.routes';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { EasyPayComponent } from './components/easy-pay/easy-pay.component';
import { LandingScreenComponent } from './components/landing-screen/landing-screen.component';





@NgModule({
  declarations: [
    RfqComponent,
    BaseComponent,
    UserInviteComponent,
    ZipSearchComponent,
    PasswordResetComponent,
    EasyPayComponent,
    LandingScreenComponent,
    
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    FormsModule, 
    ReactiveFormsModule,
  ]
})
export class LandingModule { }
