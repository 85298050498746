
<section
  fxLayout="row wrap"
  class="landing_screen "
>
  <div
    fxFlex="50"
    fxFlex.md="50"
    fxFlex.sm="100"
    fxFlex.xs="100"


    class="border_radius_10"
    
  >
  <img src="../../../../../assets/img/USS-Logo-primary.png" class="logo_size"  alt="">

  <div class="padding_div" >
      <div>
    
        <div class="width_text pb-6">

            <h2>Thank You for choosing MySiteServices Easy Pay!</h2>
            <div class="line mt-2"></div>
            <ul class="pt-4 text1">
                <li>Convenience </li>
                <li>Peace of Mind </li>
                <li>Consistency </li>
                <li>Efficiency </li>
                <li>
                  Less Paperwork                </li>
                  <li>Avoid Late Fees </li>
                  <li>Flexibility </li>
                  <li>Build Credit </li>
              </ul>
       

<div class="pt-4">
  <button class="button" mat-raised-button color="primary" (click)="getStarted()" >Get Started</button>
</div>
        </div>
      </div>
      <div class="note mt-3 ">
        <mat-icon color="primary" class="error_icon mr-1">error</mat-icon> <p class="note_text">Log in or sign up to get started. If this is your first time logging in, click “forgot password” to set your password.</p></div>
    </div>
  </div>

  <!-- <div image > -->
  <div fxFlex="45"  fxFlex.md="45" fxFlex.sm="0"
  fxFlex.xs="0" fxLayoutAlign="center center">
    <img src="../../../../../assets/img/icons/Icon - Thank You for Choosing.png" alt="" width="100%">
  </div>
  <!-- </div> -->
</section>
