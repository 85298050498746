<div [style.background-color]="background" class="p-2 tool-wrapper">
    <p [style.color]="color" class="px-2 pb-1">{{title}}</p>
    <p [style.color]="color" class="px-2 ">{{ text }}</p>
    <!-- <div>
        <a
        href="tel:{{ phoneNumber }}"
          class="contact"
          fxLayout.xs="row"
          fxLayoutAlign="center center"
        >
          <span>
            <a href="tel:{{ phoneNumber }}" class="phone-number">
              {{ phoneNumber }}
            </a>
          </span>
        </a>
    </div> -->
</div>