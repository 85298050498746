<button
  mat-icon-button
  aria-label="Scan. Click to navigate to scanning."
  aria-hidden="false"
  [matMenuTriggerFor]="findUnit"
  #findUnitTrigger="matMenuTrigger"
>
  <img
  class="scan-icon"
    src="../../../../../assets/img/icons/scan_icon_without_shadow.svg"
    alt="Scan Icon.Click to navigate to scanning."
  />
</button>

<mat-menu
  #findUnit="matMenu"
  [overlapTrigger]="false"
  class="toolbar-dropdown-menu user-menu p-2"
>
  <div (click)="startScanner()" class="menu_items p-1">
    <div  class="icon_text p-2">
      <div >
        <p class="text_blue text_css">Scan to Find Unit</p>
        <p class="text_blue text_description">Scan QR code to find unit</p>
      </div>
    </div>

    <div>
      <mat-icon color="primary">arrow_forward</mat-icon>
    </div>
  </div>

  <div (click)="manual()" class="menu_items p-1">
    <div  class="icon_text p-2">
      <div >
        <p class="text_blue text_css">Enter to Find Unit</p>
        <p class="text_blue text_description">Enter unit number to find unit</p>
      </div>
    </div>

    <div>
      <mat-icon color="primary">arrow_forward</mat-icon>
    </div>
  </div>
</mat-menu>
