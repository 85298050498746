import { Injectable } from '@angular/core';
import LogRocket from 'logrocket';
import { ConfigService } from '../config/config.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { IdToken } from '@auth0/auth0-angular';
// import { AuthService, IdToken } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class LogRocketService {
  private logRocketAppID: string;

  constructor(
    private configService: ConfigService,
    private auth:AuthenticationService
    ) { 
    this.logRocketAppID = this.configService.getConfigProperty("LOG_ROCKET_APP_ID");
  }

  // dummy to initialize LogRocket
  init() {
    this.initLogRocket();
    this.subscribeToAuthState();
  }

  initLogRocket() {
    LogRocket.init(this.logRocketAppID, {
      network: {
        requestSanitizer: request => {
          // redact the Authorization header for all requests
          if (request.headers['Authorization']) {
            request.headers['Authorization'] = 'redacted';
          }
          // sanitize all calls to the auth0 domain
          if (request.url.toLowerCase().startsWith(`https://${this.configService.config.auth0.domain}`)) {
            // ignore the request response pair
            return null;
          }
          return request;
        },
      }
    });
  }

  identifyUser(user: IdToken) {
    // console.log("LogRocket identify user", user);
    LogRocket.identify(user.sub, {
      name: user.name || '',
      email: user.email || '',
      // Add your own custom user variables here, ie:
      // subscriptionType: user.subscriptionType
    });
  }

  // subscribe to the auth state and identify the user when it changes
  async subscribeToAuthState() {
   const user= await this.auth.idTokenClaims();
      if (user) {
        this.identifyUser(user);
      }

  }
}
