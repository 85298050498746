<section class="add_details_wrapper">
    <section class="add_details_drawer ">
        <div class="mobile-back-button-header pt-2">
            <div (click)="onCancel()" class="back-button">
                <mat-icon color="primary">keyboard_arrow_left</mat-icon>
                <span class="back-color">Back</span>
            </div>
            <h4 class="pl-1">| {{caseData.screen == 'editOrder'? 'Add New Unit Type' : caseData.screen}}</h4>
        </div>
        <div class="order-site p-1 m-3">
            Order ID #{{ orderData?.quoteName || "-" }} | {{ orderData?.siteDetails?.address || "-" }}
        </div>
        <mat-divider></mat-divider>
        <div class="p-3">
            <form [formGroup]="caseDetails">
                <div class="py-1">
                    <h3>Enter Details</h3>
                </div>
                <div class="px-2">
                    <div class="py-1" *ngIf="caseData.screen == 'Change Service Frequency' && changeFrequencyFlag ">
                        <p>Change Service Frequency</p>
                    </div>
                    <div *ngIf="caseData.screen == 'Change Service Frequency' && changeFrequencyFlag ">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Change Service Frequency</mat-label>
                            <mat-select formControlName="changeFrequency" name="Change Service Frequency">
                                <mat-option *ngFor="let frequency of serviceFrequency" [value]="frequency.serviceName"
                                    (onSelectionChange)="setServiceFrequencyForAllSelected($event)">{{frequency.serviceName}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="caseDetails.controls.changeFrequency.errors?.required">Required.</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="py-1">
                        <p>
                            Effective Date
                        </p>
                    </div>
                    <div>
                        <mat-form-field class="w-100" color="primary" appearance="outline">
                            <mat-label>Effective Date</mat-label>
                            <input [min]="startDateMin" matInput (dateChange)="onDateSelect($event)" [matDatepicker]="picker1"
                                formControlName="estimatedDate" />
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 color="primary"></mat-datepicker>
                            <mat-error
                                *ngIf="caseDetails.controls.estimatedDate.errors?.required && !caseDetails.controls.estimatedDate.errors?.matDatepickerParse">
                                Required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Don't show unit number if adding new unit type-->
                    <div class="py-1" *ngIf="caseData.screen != 'editOrder'">
                        <p>Unit Number</p>
                    </div>
                    <div *ngIf="caseData.screen != 'editOrder'">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label> Unit Number</mat-label>
                            <input matInput formControlName="unitNumber" maxlength="256" placeholder="Unit Number">
                            <mat-error *ngIf="caseDetails.controls.unitNumber.errors?.numberComma">
                                Please enter comma separated valid unit numbers.
                            </mat-error>
                        </mat-form-field>
                    </div>
                   
                </div>
            </form>
            <div *ngIf="caseData.screen != 'Change Service Frequency'">
                <mat-divider></mat-divider>
                <div class="move-options">
                    <h3 *ngIf="caseData.screen == 'Move'">Move To</h3>
                    <h3 *ngIf="caseData.screen == 'editOrder'">Add Location</h3>
                    <span>
                        <mat-icon class="tooltip-icon" #matTool="matTooltip" color="primary"
                            matTooltip="New locations should be near the main project site (i.e. a new placement at 
                            the same address or new lot in the same subdivision).  To request units at a new job site, 
                            please create a new Order."
                            matTooltipPosition="right" (click)="matTool.toggle()">
                            info
                        </mat-icon>
                    </span>
                </div>
                <div class="px-2">
                    <div>
                        <form [formGroup]="selectedLocation">
                            <mat-radio-group color="primary" class="move-radio-group" formControlName="select">
                                <mat-radio-button value="Choose from existing location" *ngIf="siteName.length > 0">
                                    Choose from existing location
                                </mat-radio-button>
                                <mat-radio-button value="Add new location">Add new location</mat-radio-button>
                            </mat-radio-group>
                        </form>
                    </div>
                    <div>
                        <form [formGroup]="address">
                            <mat-form-field class="multi_select w-100" appearance="outline"
                                *ngIf="selectedLocation.value.select == 'Choose from existing location' ">
                                <mat-label>Choose location</mat-label>
                                <mat-select formControlName="siteAddress" name="Choose location">
                                    <mat-option *ngFor="let site of siteName" [value]="site">{{site}}</mat-option>
                                    <mat-option *ngIf="siteName.length == 0">Add New Location.</mat-option>
                                </mat-select>
                                <mat-error *ngIf="address.controls.siteAddress.errors?.required">Required.</mat-error>
                            </mat-form-field>
                          <div class="px-1">
                            <mat-form-field class="w-100 comment_textarea  textarea" *ngIf="selectedLocation.value.select == 'Add new location' "
                                appearance="outline">
                                <textarea matInput #siteAddress class="no_resize" rows="3" formControlName="siteAddress" maxlength="256"
                                    placeholder="Enter Location"></textarea>
                                <mat-hint align="end">{{siteAddress.value.length}} / 256</mat-hint>
                                <mat-error *ngIf="address.controls.siteAddress.errors?.required">Required.</mat-error>
                            </mat-form-field>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <form [formGroup]="caseDetails">
            <div class="additional-instruction">
                <p>Additional Instructions<span class="required-asterisk">*</span></p> 
                <span>
                    <mat-icon class="additional-tooltip" #matTool="matTooltip" color="primary"
                        matTooltip="Additional instruction may include information such as who to contact on-site, scheduling requirement, specific unit placement, or any other information you would like to tell the Order Support Team."
                        matTooltipPosition="right" (click)="matTool.toggle()">
                        info
                    </mat-icon>
                </span>
            </div>
            <div class="px-2">
                <mat-form-field class="w-100 comment_textarea  textarea" appearance="outline">
                    <textarea matInput class="no_resize" maxlength="256" placeholder=" Additional Instructions" formControlName="additionalInstrcutions"
                        rows="3" id="description"></textarea>
                    <mat-error *ngIf="caseDetails.controls.additionalInstrcutions.errors?.required">
                        Required.
                    </mat-error>
                </mat-form-field>
            </div>
            </form>
            <div class="div_Cancel_Move">
                <button mat-raised-button class="mx-2" (click)="onCancel()">Cancel</button>
                <button mat-raised-button color="primary" (click)="onNext()">Next</button>
            </div>
        </div>
    </section>
</section>