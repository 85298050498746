import { Component, effect, Input } from '@angular/core';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../../../theme/services/menu.service'; 
import { Breadcrumb } from 'src/app/models/breadcrumb';
import { BreadcrumbService } from '../../services/breadcrumbs/breadcrumb.service';
import { ProfileService } from 'src/app/features/accounts/services/profile/profile-services.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [ MenuService ]
})
export class BreadcrumbComponent {
  public settings: Settings;
  userId: string;



    breadcrumbs: Breadcrumb[] | undefined;

    constructor(private  breadcrumbService: BreadcrumbService,
      private menuService: MenuService,
      private profileService: ProfileService,
      private router : Router

    ) {
      effect(()=>{
        const userId = this.profileService.selectedAccount().accountId as string;
        if (this.userId != userId) {
          this.userId = userId
          localStorage.removeItem('breadcrumbStack');
        }

      })
      
       this.breadcrumbService.breadcrumbs$.subscribe((breadcrumb)=>{
        this.breadcrumbs=breadcrumb

      });


  
    }
  
    removeBreadcrumbByIndex(breadcrumb: Breadcrumb){
      this.breadcrumbService.removeBreadcrumbByIndex(breadcrumb);
    }
  

    public closeSubMenus(){
      this.breadcrumbService._breadcrumbs$.next([]);
      if(this.settings?.menu == "vertical")
          this.menuService.closeAllSubMenus();
  }

}
