import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
} from "rxjs";
import { UssApiService } from "../uss-api/uss-api.service";
import { ToastrService } from "ngx-toastr";
import { StandardQuotes } from "src/app/models/quote-main-screen-model";
import { LoadingService } from "../../../../shared/services/loading/loading.service";
import { PostalCode, Zipcode } from "src/app/models/zipcode";

@Injectable({
  providedIn: "root",
})
export class ZipcodeService {
  constructor(
    private api: UssApiService,
    private loadingService: LoadingService,
    private toastr: ToastrService
  ) {}

  // behaviorsubject for zipcode validation
  public zipcodeBehaviorSubject = new BehaviorSubject<Zipcode | null>(null);

  // zipcode validation as observable
  zipcodeResult: Observable<Zipcode | null> =
    this.zipcodeBehaviorSubject.asObservable();

  //Api to validate zipcode
  zipcodeValidation(data: PostalCode): Observable<Zipcode | null> {
    return this.api.zipPost("zipcodes", data).pipe(
      map((res: Zipcode) => {
        if (res) {
          if (res.data["isServiceable"]) {
            this.toastr.success(res.message);
            this.zipcodeBehaviorSubject.next(res);
            return res;
          } else {
            // this.toastr.error(
            //   "This ZIP code is not currently eligible for web orders.  Please call 1-800-TOILETS to speak with a representative."
            // );
            this.zipcodeBehaviorSubject.next(null);
            return null;
          }
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error getting: ${JSON.stringify(err)}`);
        this.loadingService.setLoader(false);
        // throw err; >> use this to rethrow the error if that makes sense
        return of(null);
      })
    );
  }
}
