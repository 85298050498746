import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AssetDetailsComponent } from "./components/asset-details/asset-details.component";
import { RouterModule, Routes } from "@angular/router";
export const routes: Routes = [
  { path: "assest-details/:unitNumber", component: AssetDetailsComponent },
];

@NgModule({
  declarations: [
    AssetDetailsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [],
})
export class ScanModule {}
