import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
export interface ICardContent {
  cardHeader: string;
  cardId: string;
  cardValues: Record<string, string>;
  status?: string;
  showStatus?: boolean;
  showEditIcon?: boolean;
  showBillingIcon?: boolean;
  statusStyle?: Record<string, string>;
  navigateTo?: string;
  editUrl?: string;
  billingUrl?: string;
}
@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent {
  // @Input({ required: true }) cardHeader: string = "";
  // @Input({ required: true }) cardId: string = "";
  @Input({ required: true }) cardContent: ICardContent = {
    cardId: "",
    cardHeader: "",
    cardValues: {},
    status: "",
    showStatus: false,
    statusStyle: {
      border: "2px solid #6EE7B7",
      "background-color": "#D1FAE5",
      color: "#065F46",
    },
  };
  // @Input() status: string = "";
  // @Input() showStatus: boolean = false;
  // @Input() statusStyle: Record<string, string> = {
  //   border: "2px solid #6EE7B7",
  //   "background-color": "#D1FAE5",
  //   color: "#065F46",
  // };
  @Input() navigateTo: string = "";
  @Output() cardClick = new EventEmitter<string>();
  cardBody: Record<string, string>[] = [];
  constructor(private router: Router) {}

  ngOnInit() {
    // console.log("cardID", this.cardId);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["cardContent"]) {
      this.cardBody = Object.keys(this.cardContent.cardValues).map((key) => {
        return { key, value: this.cardContent.cardValues[key] };
      });
    }
  }

  onCardClick() {
    if (this.navigateTo) {
      this.router.navigateByUrl(this.navigateTo);
    }
    this.cardClick.emit(this.cardContent.cardId);
  }

  onClick(url: string) {
    if (url === "edit") {
      if (this.cardContent.editUrl) {
        this.router.navigate([this.cardContent.editUrl]);
      }
    } else {
      if (this.cardContent.billingUrl) {
        this.router.navigateByUrl(this.cardContent.billingUrl);
      }
    }
  }
}
