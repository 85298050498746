// site-address-exist-popup.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetContactModel } from 'src/app/models/site-details-model';

@Component({
  selector: 'app-site-address-exist-popup',
  templateUrl: './site-address-exist-popup.component.html',
  styleUrls: ['./site-address-exist-popup.component.scss']
})
export class SiteAddressExistPopupComponent {
  title:string = 'Site address already exists';
  message:string = 'You can choose an existing address or create a new.';

  constructor(
    public dialogRef: MatDialogRef<SiteAddressExistPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public addressId: string,
  ) { }

  ngOnInit() {
    if(this.addressId == 'contact'){
      this.title = 'Contact already exists';
      this.message = 'You can choose an existing contact or create a new.';
    }
  }

  create() {
    this.dialogRef.close({ action: 'create' });
  }

  choose() {
    this.dialogRef.close({ action: 'choose', addressId:this.addressId });
  }
}
