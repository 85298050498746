import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
class Reason {
  reason: FormControl<string | null>;
  feedback: FormControl<string | null>;
  constructor() {
    this.reason = new FormControl<string>("", [Validators.required]);
    this.feedback = new FormControl<string>("");
  }
}

@Component({
  selector: "app-reason-pop",
  templateUrl: "./reason-pop.component.html",
  styleUrls: ["./reason-pop.component.scss"],
})
export class ReasonPopComponent {
  reasonFormGroup: FormGroup<Reason>;
  constructor(
    private dialogref: MatDialogRef<ReasonPopComponent>,
    private fb: FormBuilder,
    
  ) {
    this.reasonFormGroup = this.createForm();
  }
  createForm(): FormGroup<Reason> {
    const reason = new Reason();
    const reasonFormGroup = this.fb.group<Reason>(reason);
    return reasonFormGroup;
  }
  close() {
    this.dialogref.close();
  }
  confirm() {
    if (this.reasonFormGroup.valid) {
      this.dialogref.close(this.reasonFormGroup.value);
    }else{
      this.reasonFormGroup.markAllAsTouched();
    }
  }
}
