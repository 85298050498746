import { Contracts } from "./order-model";
import { UserProfile } from "./profile-model";

export class EasyPayState {
  currentStep: number;
  userProfile: Partial<UserProfile> | null;
  stripeCustomerId: string | null;
  paymentMethodId: string;
  cardBrand?: string;
  contractsWithPaymentMethods: string;
  paymentType: string;
  isAutoPay: boolean;
  contractIds: string[] ;
  contracts: Contracts[] ;
  constructor(){
    this.currentStep = 0;
    this.userProfile = null;
    this.stripeCustomerId = null;
    this.paymentMethodId = '';
    this.isAutoPay = true;
    this.contractsWithPaymentMethods = '';
    this.paymentType = '';
    this.contractIds= [];
    this.contracts = [];
  }
}

export class OnConfirmEasyPayRequest {
  contractIds: string[];
  paymentMethodId: string;
}




// PaymentMethod
export class PaymentMethod {
  paymentMethodId: string;
  type: string;
  card: Card | null ;
  bank: BankAccount | null;
}

// Card
export class Card {
  cardBrand: string ;
  cardNo: string;
  cardExpMonth: number;
  cardExpYear: number;
  country: string;
  displayBrand: string;
  expired: boolean ;
}

// BankAccount
export class BankAccount {
  accountHolderType: string ;
  accountType: string ;
  bankName: string ;
  email: string ;
  cardNo: string;
}


// {
//   "contractIds": [
//     "8008I000000JM0IQAW"
//   ],
//   "paymentMethodId": "pm_1OKEhpKX1Bgoxru0UkR2ZcT",
//   "accountId": "8008I000000JN6NQAW",
//   "contactId": "8008I000000JN6NQAW"
// }