import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetpopupComponent } from '../shared/components/dialogbox/resetpopup/resetpopup.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [    
    SidenavComponent,
    VerticalMenuComponent,
    ResetpopupComponent,
    MessagesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    SidenavComponent,
    VerticalMenuComponent,
    ResetpopupComponent,
    MessagesComponent,
  ]
})
export class ThemeModule { }
