import { Component } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PostalCode, Zipcode } from "src/app/models/zipcode";
import { UssApiService } from "src/app/features/myuss/services/uss-api/uss-api.service";
import { MatDialogRef } from "@angular/material/dialog";
import { ZipcodeService } from "src/app/features/myuss/services/zipcode/zipcode.service";

@Component({
  selector: "app-zippopup",
  templateUrl: "./zippopup.component.html",
  styleUrls: ["./zippopup.component.scss"],
})
export class ZippopupComponent {
  public form: UntypedFormGroup;
  loader = false;

  constructor(
    public fb: UntypedFormBuilder,
    public router: Router,
    private zipcodeservice: ZipcodeService,
    public toastr: ToastrService,
    private dialogRef: MatDialogRef<ZippopupComponent>
  ) {
    this.form = this.fb.group({
      postalCode: [
        null,
        Validators.compose([Validators.required, Validators.minLength(5)]),
      ],
    });
  }

  onkey(e) {
    this.form.patchValue({ postalCode: e.replace(/[^0-9]/g, "") });
  }

  // on submit button click
  async onSubmit(form) {
    if (this.form.valid) {
      // this.loader = true;
      const code = this.form.value as PostalCode;
      this.zipcodeservice.zipcodeValidation(code).subscribe((res) => {
        if (res?.data["isServiceable"]) {
          this.router.navigateByUrl("quotes/quotecreation");
          this.dialogRef.close();
        }
      });
      // this.zipcodeservice.zipcodeResult.subscribe((res)=>{
      //   if (res?.data["isServiceable"]) {
      //      this.router.navigateByUrl("quotes/quotecreation");
      //     this.dialogRef.close();
      //   }
      //  })
    }
  }
}
