import { Injectable } from "@angular/core";
import { ConfigService } from "../config/config.service";

import { AuthService, IdToken } from "@auth0/auth0-angular";
import { IPendo } from "ngx-pendo";
import { AuthenticationService } from "../authentication/authentication.service";

declare const pendo: IPendo;

@Injectable({
  providedIn: "root",
})
export class PendoService {
  private pendoApiKey: string;

  constructor(
    private authService: AuthenticationService,
    private configService: ConfigService
  ) {
    this.pendoApiKey = this.configService.getConfigProperty("PENDO_API_KEY");
  }

  init() {
    this.initPendo();
    this.subscribeToAuthState();
  }

  //initialize Pendo
  initPendo() {
    pendo.initialize({
      apiKey: this.pendoApiKey,
    });
  }

  //identify the user in Pendo
  identifyUser(user: IdToken) {
    pendo.identify({
      visitor: {
        id: user?.email!,
      },
    });
  }

  //subscribe to the auth state and identify the user when it changes
  async subscribeToAuthState() {
    const user= await this.authService.idTokenClaims();
      if (user) {
        this.identifyUser(user);
      }
  
  }
}
