import { Component, effect, Input } from "@angular/core";
import { Subscription, distinctUntilChanged, filter } from "rxjs";
import { QuoteState } from "src/app/models/quoteState-model";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { ConfigService } from "../../services/config/config.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

@Component({
  selector: "app-quote-name",
  templateUrl: "./quote-name.component.html",
  styleUrls: ["./quote-name.component.scss"],
})
export class QuoteNameComponent {
  currentValue: QuoteState;
  subscription: Subscription;
  @Input() showBackBtn: boolean = true;
  projectEnabled: boolean = false;
  myussModules: Record<string, boolean | undefined> = {};

  constructor(
    public quoteStateService: QuoteStateService,
    private configService: ConfigService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.getMyussModule();

    this.projectEnabled =
      this.configService.getConfigProperty("PROJECTS_ENABLED");

    this.subscription = this.quoteStateService.currentQuote$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => prev.quoteName === curr.quoteName && prev.step2.status === curr.step2.status
        )
      )
      .subscribe((res) => {
        this.currentValue = res;
      });
  }
  /**
   * Get all the myuss flags
   */
  async getMyussModule() {
    this.myussModules = await this.profileService.getAllMyussFlags();
  }

  // to determine whether to enable or disable the back button.
  showBackButton(): boolean {
    return this.quoteStateService.showBackButton();
  }

  backToPreviousStep() {
    this.quoteStateService.updateCommonValues({
      ...this.quoteStateService.getCurrentValue(),
      currentStep: this.quoteStateService.getCurrentValue().currentStep - 1,
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
