import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { RfqService } from "../../services/rfq/rfq.service";
import { firstValueFrom } from "rxjs";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";

@Component({
  selector: "app-rfq",
  templateUrl: "./rfq.component.html",
  styleUrls: ["./rfq.component.scss"],
})
export class RfqComponent {
  constructor(
    private _Activatedroute: ActivatedRoute,
    private auth: AuthenticationService,
    private configService: ConfigService,
    private rfqService: RfqService
  ) {}

  ngOnInit() {
    this._Activatedroute.queryParams.subscribe((params) => {
      console.log(params.rfq_id, params.existing_user);
      // store the RFQ ID and token in local storage so we can use it after we get back from signup/login
      localStorage.setItem("rfqId", params.rfq_id);
      localStorage.setItem("rfqToken", params.token);
      let userEmail = '';
      // load the RFQ so we can get the user's email address
      this.rfqService.rfqId = params.rfq_id;
      firstValueFrom(this.rfqService.getRFQDetails())
        .then((rfq) => {
          if (rfq) {
            userEmail = rfq.emailAddress;
          }
        })
        .catch((err) => {
          console.error("error retrieving RFQ", err);
        })
        .finally(() => {
          const existingUser = params.existing_user == "true";
          // redirect to login/signup page based on user status
          const authParams = {
            audience: this.configService.config.auth0.audience,
            screen_hint: existingUser ? "login" : "signup",
            redirect_uri: `${window.location.origin}/login-callback`,
            login_hint: userEmail,
            scope: this.configService.config.auth0.scope,
          };
        
          this.auth.loginWithRedirect(authParams);
        });
    });
  }
}
