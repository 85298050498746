import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  public _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this._breadcrumbs$.asObservable();
  breadCrumbsHistory:Array<Breadcrumb>=[];
  breadcrumbStack: Array<Breadcrumb> = [];
  isMenuNavigation = false; 
  uniqueId:string="";
name:string="";
 
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    
    const storedBreadcrumbs = localStorage.getItem('breadcrumbStack');
    if (storedBreadcrumbs) {
      this.breadcrumbStack = JSON.parse(storedBreadcrumbs);
      this._breadcrumbs$.next([...this.breadcrumbStack]);
    }
    
    this.router.events?.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateBreadcrumbs();
    });
}


private updateBreadcrumbs() {
  const currentBreadcrumbs = this.createBreadcrumbs(this.activatedRoute);
  const currentCrumb = currentBreadcrumbs.find((breadcrumb) => breadcrumb.url === this.router.url);


  if (this.breadcrumbStack.length === 0 || this.breadcrumbStack[this.breadcrumbStack.length - 1].url !== this.router.url) {
    if (currentCrumb) {
      if (!this.breadcrumbStack.some((breadcrumb) => breadcrumb.url === currentCrumb.url)) {
        this.breadcrumbStack = this.isMenuNavigation ? [...currentBreadcrumbs] : [
                        ...this.breadcrumbStack,
                        ...currentBreadcrumbs.filter(
                            (current) => !this.breadcrumbStack.some(existing => existing.url === current.url)
                        ) 
                      ];
  
      } else {
        this.removeBreadcrumbByIndex(currentCrumb);
      }
    }
  }
  localStorage.setItem('breadcrumbStack', JSON.stringify(this.breadcrumbStack));
  this._breadcrumbs$.next([...this.breadcrumbStack]);
  console.log("Breadcrumb stack", this.breadcrumbStack);
  this.isMenuNavigation = false;  
  this.setId("", "");

}



  private createBreadcrumbs(route: ActivatedRoute,url: string = '',breadcrumbs: Array<Breadcrumb> = []): Breadcrumb[] {
    console.log("breadcrumbStack",this.breadcrumbStack)
    const children: Array<ActivatedRoute> = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment: { path: string }) => segment.path).join('/');


      if (routeURL !== '') {
        url += `/${routeURL}`;
        let label = routeURL.toLowerCase();

        const breadcrumbData = child.snapshot.data?.Breadcrumb || '';


          breadcrumbs.push({ id: this.uniqueId, name: this.name, label: label, url ,breadcrumbData});
      
  }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs; 
  }

  removeBreadcrumbByIndex(ClickedBreadcrumb:Breadcrumb) {
    const index = this.breadcrumbStack.findIndex((breadcrumb) => breadcrumb.url === ClickedBreadcrumb.url);
    if (index !== -1) {
      this.breadcrumbStack = this.breadcrumbStack.slice(0, index + 1); 
      this._breadcrumbs$.next([...this.breadcrumbStack]); 
      localStorage.setItem('breadcrumbStack', JSON.stringify(this.breadcrumbStack));

    }
  }



  setMenuNavigation(isMenu: boolean) {
    this.isMenuNavigation = isMenu;
  }

  setId(name:string, id:string){
    this.uniqueId=id;
    this.name=name;
  }



  


}





