<div class="custom-card">
  <div class="container">
    <div class="icon_div">
      <img
        src="../../../../assets/img/icons/edit-alert.svg"
        alt="Alert message"
        class="alert"
      />
    </div>
    <div class="p-2">
      <div class="centered-text" *ngIf="!isEditedData?.message">
        <h3>
          You have selected a weekend day, availability may be limited. We will
          strive to accommodate your requested date and will be in contact if we
          are unable to do so on the date indicated. Would you like to select
          another day?
        </h3>
      </div>
      <div class="centered-text" *ngIf="isEditedData?.message">
        <h4>
          The unit number entered was not found on any active Orders for this
          account. Please check the unit number and try again or search for your
          order using the Orders module. If you are still unable to find your
          order, <span class="please-call"> Please call us at {{phoneNumber}}.</span>
        </h4>
       
      </div>
     
    </div>
  </div>

  <span> <mat-icon class="close-icon" (click)="onYes()">close</mat-icon></span>
</div>
