import { Account } from "./account-model";
import { Address } from "./address-model";
import { JobsiteModel } from "./jobsite-model";
import { BillingDetails } from "./order-model";
import { ProjectModel } from "./project-model";
import { QuoteDocument } from "./quote-documents";
import { QuoteLineModel } from "./quote-line-model";
import { Contact } from "./quoteState-model";
import { SiteInfo } from "./site-details-model";

export class QuoteModel {
  quoteId: string;
  quoteName: string;
  quoteNumber: string;
  quoteDate: string;
  account: Account | null;
  startDate: string;
  endDate: string;
  priceBookId: string;
  primaryContact: Contact;
  billToContact: Contact;
  primarySiteContact: Contact;
  shipToContact: Contact;
  shippingAddress: SiteInfo;
  billTiming: string;
  billingPeriod: string;
  declineDamageWaiver: boolean;
  orderType: string;
  autoPay: boolean;
  billToAddress: Address;
  billingComplete: boolean;
  orderedinSalesforce: boolean;
  invoiceDeliveryMethod: string;
  paymentMode: string;
  preOrderFlowComplete: boolean;
  siteComplete: boolean;
  billingApprovalStatus: string;
  facilityName: string;
  subdivisionName: string;
  status: string;
  ordered: boolean;
  billCycleDay: string;
  chargeType: string;
  eECPercent: number;
  eSFPercent: number;
  fuelSurchargePercent: number;
  lastBillingDate: string;
  legalEntity?: string;
  legalEntityCode: string;
  branchCode: string;
  BusinessType: string;
  jobSites: JobsiteModel[];
  creditCardPaymentStatus: string;
  billingSystem: string;
  cPQTemplate: string;
  enableServerSidePricingCalculation: boolean;
  taxEntityUseCode: string;
  avaTaxCompanyCode: string;
  aVASFCPQIsSellerImporterOfRecord: boolean;
  avaSalesTaxAmount: number;
  avaSalesTaxMessage: string;
  duration: string;
  createdById: string;
  createdDate: string;
  expirationDate: string;
  sourceSystem: string;
  quoteReferenceNumber: string;
  ussContact: Contact;
  oneTimeSubtotal: number;
  oneTimeTax: number;
  oneTimeTotal: number;
  recurringSubtotal: number;
  recurringTax: number;
  recurringTotal: number;
  quoteDocuments:QuoteDocument[];

  quoteLines: QuoteLineModel[];
  projectDetails:ProjectModel;
 
 
}
