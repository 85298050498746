import { Location } from "@angular/common";
import { ProductDetails } from "src/app/models/jobsite-model";
import { OrderService } from "src/app/features/orders/services/order.service";
import { Component, effect, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddLocationPopupComponent } from "../dialogbox/add-location-popup/add-location-popup.component";
import {
  newLocation,
  QuoteLineGrouped,
  QuoteLineModel,
} from "src/app/models/quote-line-model";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { ToastrService } from "ngx-toastr";
import { AssetLocation, ColumnData, OrderModel, WorkOrder } from "src/app/models/order-model";
import { distinctUntilChanged, Subscription, take } from "rxjs";
import { ScanService } from "src/app/features/scan/services/scan.service";
import { RejectpopupComponent } from "../dialogbox/reject-popup/reject-popup.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-location-assignment",
  templateUrl: "./location-assignment.component.html",
  styleUrls: ["./location-assignment.component.scss"],
})
export class LocationAssignmentComponent {
  bundleData: QuoteLineGrouped[] = [];
  additionalColumns: string[] = [];
  newLocationValues: newLocation[] = [];
  bundleIndex: number;

  isReduceQuantity: boolean = false;
  editOrderColumndata: ColumnData;
  subscription: Subscription;
  orderData: OrderModel | null;
  previousReducedNumber: number = 0;
  columnData: ColumnData;
  bundle: QuoteLineModel[];
  assetDetailsSignal: {
    assetDetails:  AssetLocation,
    completedServices: WorkOrder[]|[],
    upcomingServices: WorkOrder[]|[],
  }|null;
  isEditedSite: boolean = false;
  isSerchByUnitNumber: boolean = false;
  isMobile: boolean = false;
  @Input() screenName: string; // Input property to receive screen name
  @Input() MobileEditOrderScreen: string; // Input property to receive screen name

  @Output() editOrderDataSitewise = new EventEmitter<ColumnData>(); // Output property to emit the edited column data
  @Output() isSingleQuantity = new EventEmitter<boolean>();
  urlSegments: boolean = false;
  isSingleUnitMobile: boolean = false;

  constructor(
    public dialog: MatDialog,
    public quoteStateService: QuoteStateService,
    public toastr: ToastrService,
    public orderService: OrderService,
    private scanService: ScanService,
    public router: Router
  ) {
    effect(
      () => {
        this.bundleData = this.quoteStateService.multiLocationData();
        let isSelectedBundle = this.bundleData.filter(
          (item) => item.isSelected
        );
        this.bundleData.forEach((item: QuoteLineGrouped, index: number) => {
          if (item.isSelected) {
            this.bundleIndex = index;
          }
        });
        this.extractNewLocationValues(isSelectedBundle[0]);
      },
      { allowSignalWrites: true }
    );
  }
  ngOnInit() {
    this.urlSegments = this.router.url.split("/").includes("quotecreation") || this.router.url.split("/").includes("quote-creation");
    if (window.innerWidth <= 600) {
      this.isMobile = true;
      
    } else {
      this.isMobile = false;
    }
   
    if (!this.isMobile && this.screenName === "editOrder") {
     
      
      if (this.newLocationValues.length == 0) {
       
        this.bundleData = this.quoteStateService.multiLocationData();
        let isSelectedBundle = this.bundleData.filter(
          (item) => item.isSelected
        );
      
        this.bundleData.forEach((item: QuoteLineGrouped, index: number) => {
          if (item.isSelected) {
            this.bundleIndex = index;
          }
        });
     
        this.extractNewLocationValues(isSelectedBundle[0]);
      }
     

      this.subscription = this.orderService.orderSummary.subscribe((result) => {
        if (result) {
          this.orderData = result;
          this.bundle = this.quoteStateService.prepareBundleData(
            this.orderData.quoteModel.quoteLines
          );
       

          if (this.orderData.quoteModel && this.orderData?.assetLocations) {
            let filteredJobSites = this.orderData.quoteModel.jobSites.filter(
              (site) =>
                this.orderData?.assetLocations.some(
                  (location) => site.address.id === location.jobsiteId
                )
            );

            // Assign the filtered jobsites back to quoteModel
            this.orderData.quoteModel.jobSites = filteredJobSites;
          }

          this.quoteStateService.mapJobitesToBundleData(
            this.orderData.quoteModel
          );
          
          // If user coming by scanning asset.

          this.assetDetailsSignal = this.scanService.assetDetailsSignal();

          const column =
            `newLocation${this.assetDetailsSignal?.assetDetails?.siteAddressId}` ||
            "";

          const matchedBundle = this.bundle.filter((bundle) => {
            return (
              bundle.product.name ===
              this.assetDetailsSignal?.assetDetails?.bundleName
            );
          });
          const findSelectedColumn = this.newLocationValues.find(
            (item) => item.columnId === column
          );
        

          if (
            matchedBundle.length > 0 &&
            column === findSelectedColumn?.columnId &&
            findSelectedColumn?.productDetails?.quantity > 0
          ) {
            this.orderService.setReduceQuantityState(true);
            this.orderService.setDrawerState(true);

            this.updateColumnData(findSelectedColumn);
          }
        } else {
          const urlSegments = this.router.url.split("/");
          const lastId = urlSegments[urlSegments.length - 2];

          this.orderService.getOrderById(lastId);
        }
      });
    } 
    if(this.isMobile && this.MobileEditOrderScreen === "editOrderChangeQuantity" && !this.urlSegments) {
   
      if (this.newLocationValues.length == 0) {
        this.bundleData = this.quoteStateService.multiLocationData();
        let isSelectedBundle = this.bundleData.filter(
          (item) => item.isSelected
        );
        this.bundleData.forEach((item: QuoteLineGrouped, index: number) => {
          if (item.isSelected) {
            this.bundleIndex = index;
          }
        });
        this.extractNewLocationValues(isSelectedBundle[0]);
      }

      this.subscription = this.orderService.orderSummary.subscribe((result) => {
        if (result) {
          this.orderData = result;
          this.bundle = this.quoteStateService.prepareBundleData(
            this.orderData.quoteModel.quoteLines
          );

          if (this.orderData.quoteModel && this.orderData?.assetLocations) {
            let filteredJobSites = this.orderData.quoteModel.jobSites.filter(
              (site) =>
                this.orderData?.assetLocations.some(
                  (location) => site.address.id === location.jobsiteId
                )
            );

            // Assign the filtered jobsites back to quoteModel
            this.orderData.quoteModel.jobSites = filteredJobSites;
          }

          this.quoteStateService.mapJobitesToBundleData(
            this.orderData.quoteModel
          );
          this.emitSingleQuantityEvent();
          // If user coming by scanning asset
          this.assetDetailsSignal = this.scanService.assetDetailsSignal();

          const column =
            `newLocation${this.assetDetailsSignal?.assetDetails?.siteAddressId}` ||
            "";

          const matchedBundle = this.bundle.filter((bundle) => {
            return (
              bundle.product.name ===
              this.assetDetailsSignal?.assetDetails?.bundleName
            );
          });
          const findSelectedColumn = this.newLocationValues.find(
            (item) => item.columnId === column
          );
        

          if (
            matchedBundle.length > 0 &&
            column === findSelectedColumn?.columnId &&
            findSelectedColumn?.productDetails?.quantity > 0
          ) {
            this.orderService.setReduceQuantityState(true);
            // this.orderService.setDrawerState(true);

            this.onFocus(findSelectedColumn);
          }
        } else {
          const urlSegments = this.router.url.split("/");
          const lastId = urlSegments[urlSegments.length - 2];

          this.orderService.getOrderById(lastId);
        }
      });
    }
  }

  emitSingleQuantityEvent() {
    
    if(this.bundle?.length == 1 && this.bundle[0]?.quantity == 1 && this.isMobile){
      this.isSingleUnitMobile = !this.isSingleUnitMobile;
      this.isSingleQuantity.emit(this.isSingleUnitMobile);

    }
    
  } 
  //Function is called when user comes from serach by unit number screen on web view to open the drawer
  toggleQuantityUI() {
    if (this.isEditedSite) {
      this.orderService.setDrawerState(false);
      let drawerCurrentState = this.orderService.drawerState();

      const dialog = this.dialog
        .open(RejectpopupComponent, {
          width: "430px",
          panelClass: "limit-exceed-dialog",
          data: {
            message:
              "Previous request for change quantity is in progress. Do you want to request to new change?",
            drawerStatus: drawerCurrentState,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === "yes") {
            this.orderService.setDrawerState(!this.orderService.drawerState());
          }
        });
    } else {
      this.orderService.setDrawerState(!this.orderService.drawerState());

      // this.showQuantityChangeDrawer = !this.showQuantityChangeDrawer;
    }
  }

  extractNewLocationValues(data) {
    this.newLocationValues = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && key.startsWith("newLocation")) {
        this.newLocationValues.push(data[key]);
       
      }
    }
  }

  openAddLocationPopup(): void {
    this.dialog
      .open(AddLocationPopupComponent, {
        width: "80%",
        maxHeight: "80vh",
        panelClass: "add-location-popup",
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (result.hasOwnProperty("column")) {
            this.addLocationColumn({ ...result.subSiteDetails, bundles: [] });
            return;
          }
          this.addLocationColumn({ ...result, bundles: [] });
        }
      });
  }

  addLocationColumn(data) {
    const newLocationName =
      "newLocation" + data.address.id + (this.additionalColumns.length + 1);

    this.bundleData.forEach((item: QuoteLineGrouped) => {
      item[newLocationName] = {
        quantityQuoted: 0,
        ...data,
        columnId: newLocationName,
      };
    });
    this.quoteStateService.multiLocationData.set(this.bundleData);
  }

  onView(column: string) {
    const bundle = this.bundleData?.find(
      (item) => item[column] && item[column]?.address?.id
    );
    this.dialog
      .open(AddLocationPopupComponent, {
        width: "80%",
        maxHeight: "80vh",
        panelClass: "add-location-popup",
        disableClose: true,
        data: {
          type: "view",
          column: column,
          subSiteDetails: bundle ? bundle[column] : this.bundleData[0][column],
          subsiteLength: this.newLocationValues.length,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == true) {
          if (this.newLocationValues.length == 1) {
            this.toastr.error(
              "Atleast one location must exists for the quote."
            );
            return;
          }

          this.bundleData.forEach((item: QuoteLineGrouped) => {
            delete item[column];
          });

          this.quoteStateService.multiLocationData.set(this.bundleData);
        }

        this.bundleData.forEach((item: QuoteLineGrouped) => {
          item[result.column] = {
            ...item[result.column],
            ...result.subSiteDetails,
          };
        });
        this.quoteStateService.multiLocationData.set(this.bundleData);
      });
  }

  getAvailableQuantityByIndex() {
    const bundle = this.bundleData[this.bundleIndex];
    if (bundle == undefined) return 0;
    const jobSiteIds = Object.keys(bundle).filter((key) =>
      key.includes("newLocation")
    );
    const total = jobSiteIds.reduce((totalQuantityQuoted, jobSiteId) => {
      return totalQuantityQuoted + bundle[jobSiteId].quantityQuoted;
    }, 0);
    return bundle.quantity - total;
  }

  addQuantity(column: string) {
    while (this.getAvailableQuantityByIndex() > 0) {
      this.bundleData[this.bundleIndex][column]["quantityQuoted"] =
        this.bundleData[this.bundleIndex][column]["quantityQuoted"] + 1;
      this.quoteStateService.multiLocationData.set(this.bundleData);
      break;
    }
  }

  reduceQuantity(column: string) {

    if (this.bundleData[this.bundleIndex][column]["quantityQuoted"] > 0) {
      this.bundleData[this.bundleIndex][column]["quantityQuoted"] =
        this.bundleData[this.bundleIndex][column]["quantityQuoted"] - 1;
      this.quoteStateService.multiLocationData.set(this.bundleData);
    }
  }
  onclickMinusIcon() {
    
     this.orderService.setReduceQuantityState(true);

    
   
  }

  onclickPlusIcon() {
    this.orderService.setReduceQuantityState(false);
  }

  
  //If user is coming from search by unit number screen on mobile view then this function will be called
  onFocus(columnData) {
   
    this.editOrderColumndata = columnData;

    this.editOrderDataSitewise.emit(this.editOrderColumndata);
  }

  //If user is coming from search by unit number screen on web view then this function will be called
  updateColumnData(columnData) {
    if (!this.isMobile && this.assetDetailsSignal) {
      this.isEditedSite = columnData?.isEdited;
      const contractId = this.orderData?.contractId;
      this.orderService.updateEditOrderValues({
        ...this.orderService.getCurrentEditOrderState(),
        selectedSite: columnData?.address,
        contractId: contractId,

        sitewiseSelectedBundle: columnData?.productDetails,
        // bundlesData: this.uniqueBundleArray,
        siteDetails: columnData?.address,
        orderName: this.orderData?.quoteName,
        siteContact: columnData?.contact,
        quantityQuoted: columnData?.quantityQuoted,
        startDate: this.orderData?.startDate,
        endDate: this.orderData?.endDate,
      });
      this.toggleQuantityUI();
    }
  }
  // Function to reset the previous reduced number
  resetPreviousReducedNumber() {
    this.previousReducedNumber = 0;
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
