import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { AppSettings } from "./app.settings";
import { Settings } from "./app.settings.model";
import { NavigationEnd, Router } from "@angular/router";
import { UssApiService } from "./features/myuss/services/uss-api/uss-api.service";
import { User } from "./models/profile-model";
import { LoadingService } from "./shared/services/loading/loading.service";
import { RfqService } from "./features/landing/services/rfq/rfq.service";
import { ConfigService } from "./shared/services/config/config.service";
import { DOCUMENT } from "@angular/common";
import { tr } from "date-fns/locale";
import { ProfileService } from "./features/accounts/services/profile/profile-services.service";
import { LogRocketService } from "./shared/services/log-rocket/log-rocket.service";
import { Subscription, firstValueFrom } from "rxjs";
import { AuthenticationService } from "./shared/services/authentication/authentication.service";
import { PendoService } from "./shared/services/pendo/pendo.service";
import * as LiveUpdates from "@capacitor/live-updates";
import { Platform } from "@ionic/angular";
declare let gtag: Function;
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";

import { MatDialog } from "@angular/material/dialog";
import { SplashScreen } from '@capacitor/splash-screen';
import config from "capacitor.config";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "MySiteServices";
  loader: boolean = false;
  message: string = "";
  isMessage: boolean = false;
  public settings: Settings;
  gTrackingId: string;
  authSubscription: Subscription;
  constructor(
    public appSettings: AppSettings,
    // private auth: AuthService,
    public router: Router,
    private api: UssApiService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private rfqService: RfqService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private configService: ConfigService,
    private profileService: ProfileService,
    private logRocketService: LogRocketService,
    private auth: AuthenticationService,
    private pendoService: PendoService,
    private platform: Platform,
    private dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
    this.gTrackingId =
      this.configService.getConfigProperty("GOOGLE_TRACKING_ID");
    // this.crash();
    if (this.platform.is("hybrid")) {
      this.initializeFirebase();
      this.recordException();
    }
  }

  async crash() {
    await FirebaseCrashlytics.crash({ message: "Test" });
  }

  async initializeFirebase() {
    try {
      await FirebaseCrashlytics.log({ message: "Test" });
      console.log("Firebase Crashlytics initialized");
    } catch (error) {
      console.error("Error initializing Firebase Crashlytics", error);
    }
  }

  async recordException() {
    try {
      await FirebaseCrashlytics.recordException({
        message: "This is a non-fatal message.",
      });
      console.log("Firebase Crashlytics initialized");
    } catch (error) {
      console.error("Error initializing Firebase Crashlytics", error);
    }
  }

  async ngOnInit() {
      
    // LiveUpdatesSetup
    
    if (this.platform.is("hybrid") && config?.plugins?.LiveUpdates?.enabled) {
      await LiveUpdates.setConfig({
        channel: this.configService.getConfigProperty("LIVE_UPDATE_CHANNEL"),
      });
      const result = await LiveUpdates.sync();
      if (result.activeApplicationPathChanged) {
        console.log("activeApplicationPathChanged");
        await LiveUpdates.reload();
      }else{
        console.log("noActiveApplicationPathChanged");
        await SplashScreen.hide();
      }    
    }else{
      await SplashScreen.hide();
    }


    this.authSubscription = this.auth.authenticationChange$.subscribe(
      (auth) => {
        console.log("auth change", auth);
        if (auth) {
          this.logRocketService.init();
          this.pendoService.init();
          this.authSubscription?.unsubscribe();
        }
      }
    );
    this.loadingService.loader.subscribe((result) => {
      this.loader = result.value;
      this.isMessage = result.isMessage;
      this.message = result.message;
    });

    // loading google map script
    let gmap = `https://maps.googleapis.com/maps/api/js?key=${this.configService.getMapKey()}&libraries=places`;
    this.loadScript(gmap);

    // loading google analytics script
    let gAnalytics = `https://www.googletagmanager.com/gtag/js?id=${this.gTrackingId}`;
    this.loadScript(gAnalytics, true);
    this.setUpAnalytics();
    this.logOutEvent();
  }

  _listener = async (event) => {
    console.log("event", event);
    if (event.storageArea === localStorage) {
      //authId and userId if removed from local storage then user logs out
      if (event.key === "authID" || event.key === "userId") {
        if (
          localStorage.getItem("authID") == null ||
          localStorage.getItem("userId") == null
        ) {
          this.auth.logout();
        } else {
          window.location.reload();
        }
      }
    }
    //user logs out from another tab
    if (event.key === null) {
      this.auth.logout();
    }
  };

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {}

  /**
   * Loads script
   * @param scriptUrl
   */
  loadScript(scriptUrl: string, analytics = false): void {
    const script = this.renderer.createElement("script");
    script.src = scriptUrl;
    this.renderer.appendChild(this.document.head, script);
    // analytics script
    if (analytics) {
      script.onload = () => {
        // The script has loaded, and you can call the function
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).gtag = function gtag() {
          (window as any).dataLayer.push(arguments);
        };
        (window as any).gtag("js", new Date());
        (window as any).gtag("config", this.gTrackingId);
        console.log("analytics loaded");
      };
      script.onerror = () => {
        // The script has failed to load
        console.log("analytics failed to load");
      };
    }
  }

  /**
   * Sets up analytics
   */
  setUpAnalytics() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (typeof gtag === "function") {
          gtag("config", this.gTrackingId, {
            page_path: event.urlAfterRedirects,
          });
        } else {
          console.log("analytics event not tracked");
        }
      }
    });
  }
  logOutEvent() {
    window.addEventListener("storage", (event) => {
      if (event.key === "logoutEvent") {
        this.auth.logout();
      }
    });
  }

  ngOnDestroy() {
    if (window.removeEventListener) {
      window.removeEventListener("storage", this._listener, false);
    }
  }
}
