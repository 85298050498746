<div class="popup-body">
  <div class="title"><h3 >Terms and Conditions</h3></div>

  <div class="description">
    <p style="margin: 0 0 3px 0; padding: 5px">
      <strong>1. Acceptance.</strong> Customer shall be deemed to have accepted
      these terms and conditions (&#8220;Agreement&#8221;) upon the earliest of:
      (i) two business days after receipt of a Company invoice; (ii) delivery of
      Equipment including, among others, portable restrooms, trailers, fencing,
      roll-off dumpster, portable storage containers, and/or temporary power
      equipment (collectively or individually, the &#8220;Equipment&#8221;) to
      the Customer&#8217;s designated site (the &#8220;Site&#8221;) and use or
      acceptance thereof; (iii) acknowledgment or other conduct of Customer
      (including payment against any invoice); or (iv) Company&#8217;s
      performance of any services Customer has requested. This Agreement
      supersedes any inconsistent terms of any purchase order or other Customer
      documents. All agreements are subject to approval by Company.
    </p>
    <p >
      <strong>2. Payment Terms.</strong> Customer shall pay all charges due to
      Company during the term (the &#8220;Period&#8221;) shown on the relevant
      invoice. If credit is approved by Company, invoices are due and payable 10
      days from the date on the invoice. If credit is not approved by Company
      prior to performance, invoices are due and payable by credit card payment
      before Company will begin performance of the Services. The fees charged by
      Company apply to the full Period and shall not be prorated (i.e., charges
      for less than a full Period shall not be prorated). Customer shall be
      liable to Company for all collection expenses (including reasonable
      attorneys&#8217; fees), and interest at the rate of 1.5% per month, or
      such lesser rate as may be the maximum lawful rate, on all overdue
      accounts. Customer shall pay all additional charges for services
      separately requested or made necessary by Customer&#8217;s breach of this
      Agreement, including moving/relocation charges, special service charges,
      and special delivery and removal charges. For payments by check, Customer
      authorizes Company to use information from Customer&#8217;s check to make
      a one-time electronic fund transfer from Customer&#8217;s account or to
      process this transaction as a check. Customer shall pay all taxes,
      including sales tax, license fees and permit fees arising out of the use
      of the Equipment. Customer shall pay such taxes whether such taxes are
      shown on the relevant quotation or invoice or whether such taxes are later
      claimed by a governmental authority. In the event of a claim by a
      governmental agency for taxes related to the Equipment, Customer shall pay
      to Company such taxes on demand.
    </p>
    <p >
      <strong>3. Service.</strong> Company offers servicing as an option on all
      portable restrooms. If Customer orders servicing, Company will remove any
      domestic septic waste (&#8220;DSW&#8221;) from portable restrooms on the
      service day(s) scheduled by Company. If Company is unable to service the
      Equipment as scheduled due to a holiday, inclement weather, site
      restrictions, site inaccessibility or other circumstances, Company shall
      service the Equipment on the next available business day subject to
      Company&#8217;s other service commitments. Customer shall provide Company
      timely, sufficient, and unobstructed access to Equipment, including
      extended hours or after business hours access, as necessary to perform
      Services. The pricing of this Agreement is based upon easy access to Site,
      firm and level ground and a dry location. Company will not remove any
      waste other than DSW from portable restrooms.
    </p>
    <p >
      <strong>4. Damage Waiver.</strong> Pricing attachment included herein,
      shall include the benefit of the Company damage waiver program that covers
      all Damage occurred through any acts of God, or accidental structural
      damage to all portable restrooms, hand washing stations and holding tanks.
      EXCEPT (i) Customer shall be liable for theft or disappearance of any
      Equipment and for any losses or damage resulting from any willful or
      negligent acts or omissions of Customer or any of its agents, contractors,
      or employees; and (ii) Customer shall exercise all rights available under
      its insurance required by Section 9 hereof, and Customer shall take all
      actions necessary to process and pursue all insurance claims. Customer
      shall pay Company the actual cost of repair or replacement of the
      Equipment. The Customer shall not be responsible to Company for any minor
      wear and tear under normal utilization and or any damage caused by
      Company. Customer shall promptly notify Company of any loss or damage to
      the Equipment and shall provide Company with copies of all reports
      relating to same, including police reports, informal investigation
      reports, and insurance reports.
      <b>
        This Damage Waiver does not apply to portable restrooms, hand washing
        stations and holding tanks contaminated with Hazardous Materials while
        in the Customer&#8217;s possession.
      </b>
      When Company performs at the Customer&#8217;s direction, Company is not
      liable for damage caused to the equipment or damage caused to delivery
      location or truck access path, except to the extent caused by
      Company&#8217;s sole negligence or willful misconduct.
    </p>
    <p >
      <strong>5. Equipment Responsibility.</strong> Company will deliver the
      Equipment to the Site at the location selected by Customer at the
      Period&#8217;s commencement. Customer warrants and represents it is solely
      responsible for and has exercised due diligence and care in selecting a
      safe location at the Site for placement of any Equipment, and further
      agrees to direct and supervise the Equipment&#8217;s placement. Title to
      all Equipment remains with Company. Customer shall not modify or move the
      Equipment from or within the Site absent Company&#8217;s written consent.
      If Customer moves the Equipment from or within the Site without
      Company&#8217;s written consent, Customer immediately assumes all
      responsibility and liability for all losses and costs incurred by Company.
      Customer warrants and represents it is familiar with the safe and proper
      use of the Equipment. Customer shall not sell, rent, lease or otherwise
      lose possession of the Equipment, nor shall Customer permit any lien to be
      placed on the Equipment. Customer acknowledges that Company has no control
      over the use of the Equipment by Customer, and Customer agrees to comply,
      at Customer&#8217;s sole expense, with all applicable governmental and
      quasi- governmental laws and guidelines, including ANSI Standard Z4.3 and
      PASIPASI&#8217;s published requirements in its &#8220;Guide for Clean
      Portable Sanitation&#8221;, if applicable. Customer further agrees to (i)
      obtain and comply with all applicable governmental and quasi-governmental
      licenses, permits, registrations, permissions, and other approvals
      (&#8220;Permits&#8221;) applicable to the Equipment (including, but not
      limited to, Permits allowing the delivery and placement of the Equipment
      at the Site); and (ii) comply with all applicable Permits held by Company
      applicable to the Equipment.
    </p>
    <p >
      <strong>6. Equipment and Service Selection.</strong> Customer represents
      and warrants that it has chosen the type of Equipment, the number of
      Equipment units, the type of Service and the frequency of Service based on
      the exercise of its own due diligence and care in assessing its own needs
      and is not relying on any information provided by Company in making any
      such choices.
    </p>
    <p >
      <strong>7. Equipment Contamination.</strong> Customer represents and
      warrants that any waste material to be collected in the Equipment or
      disposed of by Company does not include any radioactive, volatile,
      biohazardous (excluding noninfectious DSW), flammable, explosive, special
      waste, or hazardous materials (including but not limited to asbestos,
      petroleum, paints and any substance identified by a governmental agency as
      being hazardous or toxic) or their equivalent (collectively,
      &#8220;Hazardous Materials&#8221;). At all times, Customer shall hold all
      title to and liability for all waste material. Company will not remove
      tires, Hazardous Materials, or appliances from dumpsters (collectively,
      &#8220;Prohibited Waste&#8221;). Customer will be responsible for all
      removal, cleanup, remediation, fines, penalties and other costs arising
      from or relating to the presence of Prohibited Waste attributable to
      Customer&#8217;s possession of the Equipment. Customer will be responsible
      for all removal, cleanup, remediation, fines, penalties, and other costs
      arising from or relating to the presence of Prohibited Waste attributable
      to Customer&#8217;s possession of the Equipment. Customer will be
      responsible for all fines or penalties on overweight containers.
      Mattresses or other bulky items found in dumpsters may result in
      additional fees. If Prohibited Waste is found in or around the Equipment,
      Customer shall arrange and pay for separate removal, disposal and
      remediation of such waste and Equipment. Customer may not terminate the
      Period and shall be responsible for all accrued charges until such
      Prohibited Waste is removed and the Equipment is remediated.
    </p>
    <p >
      <strong>8. Liability &#38; Indemnification.</strong> Except to the extent
      Customer is not liable under the Damage Waiver program described in
      Section 4, Customer agrees to defend, indemnify and hold harmless Company
      to the maximum extent permitted by law against and for all claims,
      lawsuits, damages, expenses, penalties, fines, and other losses arising
      out of any of (a) the rental, delivery, condition, possession,
      maintenance, use or operation of Equipment delivered to or rented by
      Customer, including but not limited to any claims that might be brought
      against only Company by an employee of Customer, (b) waste material
      collected in the Equipment or disposed of by Company, or (c) any damage to
      underground pipes, sewers, wires, conduits or utilities resulting from
      Customer &#8217;s failure to comply with Section 14. Customer &#8217;s
      indemnity and defense obligations apply to the maximum extent permitted by
      law to all injuries, damages and losses regardless of whether same are
      caused, or are alleged to have been caused, in whole or in part by
      Company&#8217;s, Customer&#8217;s, or a third party&#8217;s acts or
      omissions, except that Customer will have no obligation to indemnify or
      defend Company to the extent the injury, damage, or loss was actually
      caused by Company&#8217;s sole negligence or willful misconduct. Customer
      expressly agrees and will cause its insurer to accept a tender by Company
      to Customer of any claim arising out of the rental, delivery, condition,
      possession, maintenance, use or operation of the Equipment. Company, its
      officers, directors and agents shall not, under any circumstances, be
      liable to Customer for consequential, incidental, special, exemplary or
      punitive damages arising out of or relating to the Equipment.
      Customer&#8217;s exclusive remedy for any claims or causes of action
      arising out of or related to the Equipment shall be recovery of direct
      damages in an amount not to exceed the amount paid by Customer for use of
      the Equipment.<br />
      EXCEPT AS OTHERWISE SPECIFICALLY PROVIDED HEREIN, ALL EQUIPMENT IS
      PROVIDED TO CUSTOMER &#8220;AS IS,&#8221; &#8220;WHERE IS,&#8221; AND
      &#8220;WITH ALL FAULTS,&#8221; AND THERE ARE NO REPRESENTATIONS OR
      WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE CONDITION
      OR PERFORMANCE OF THE EQUIPMENT, ITS MERCHANTABILITY OR FITNESS FOR A
      PARTICULAR PURPOSE.
    </p>
    <p >
      <strong>9. Assumption of Risk; Insurance.</strong> Customer assumes all
      risk and liability for injury (including death to any person or property
      and for all other risks and liabilities arising from the rental, delivery,
      condition, possession, maintenance, use or operation of the Equipment.
      Customer agrees, at its own expense and at all times during the term of
      the Agreement, to maintain Commercial General Liability
      (&#8220;CGL&#8221;) insurance with limits of not less than $1,000,000 per
      occurrence for sums that an insured must pay as damages because of bodily
      injury or property damage arising out of the condition, possession,
      maintenance, use, operation, erection, dismantling, servicing or
      transportation of the Equipment. Customer and its agents will cooperate
      with Company and Customer&#8217;s insurers in any claim or suit arising
      therefrom and will do nothing to impair or invalidate the applicable
      insurance coverage. In addition, Customer agrees, at its own expense and
      at all times during the term of the Agreement, to maintain Property
      Insurance in an amount adequate to cover any damage to, or loss of, the
      Equipment. Customer&#8217;s Property Insurance must cover non-owned
      Equipment while in Customer &#8217;s care, custody and control. Customer
      agrees to add Company as an Additional Insured and Loss Payee on all
      insurance required by the Agreement. Customer&#8217;s CGL insurance must
      be primary and non-contributory with any insurance maintained by Company
      and must include a waiver of subrogation in favor of Company. The amount,
      terms and conditions of the insurance maintained by Customer must be
      reasonably acceptable to Company. Customer agrees to abide by all terms
      and conditions of all such insurance. Customer agrees to provide Company
      with Certificates of Insurance (&#8220;COI&#8221;) evidencing the
      insurance required by the Agreement. Company&#8217;s acceptance of
      Customer&#8217;s COI will not be deemed a waiver or modification of
      Customer&#8217;s insurance, indemnity, or any other obligations under the
      Agreement. The provisions of this Section 9 are in addition to, and do not
      limit, qualify, or waive any obligations of Customer under this Agreement,
      including but not limited to Customer&#8217;s obligations under Section 8
      above. Customer&#8217;s fulfillment of its insurance obligations does not
      limit Customer&#8217;s liability under Section 8 above. The provisions of
      Section 8 above does not limit or qualify the provisions of this Section 9
      or the scope of insurance coverage provided to Company as an Additional
      Insured or Loss Payee.
    </p>
    <p >
      <strong>10. Termination.</strong> Company may terminate this Agreement and
      immediately remove the Equipment if (i) Customer fails to pay any amount
      when due, (ii) Customer breaches the Agreement, (iii) there is a loss of
      or damage to the Equipment, (iv) a lien is placed, or is proposed to be
      placed, on any Equipment, (v) a proceeding in bankruptcy or for other
      protection from creditors is commenced by or against Customer, or (vi)
      Company &#8217;s convenience. Company shall not be responsible for losses
      due to removal of Company&#8217;s Equipment pursuant to this paragraph.
    </p>
    <p >
      <strong>11. Governing Law; Non-Waiver; Amendments.</strong>This Agreement
      is governed by the laws of the state where the Site is located, without
      giving effect to principles of conflicts of laws. Each party submits to
      the jurisdiction of any state or federal court sitting in such state in
      any action or proceeding arising out of or relating to this Agreement. No
      failure by Company to exercise any right hereunder shall operate as a
      waiver of any other right hereunder, and a waiver of any right on one
      occasion shall not constitute a waiver of any such right on any future
      occasion. All modifications to this Agreement must be in a writing signed
      by both parties.
    </p>
    <p >
      <strong>12. Errors &#38; Omissions.</strong> Company reserves the right to
      correct any erroneous information that may appear in an invoice including,
      without limitation, Customer &#8217;s name or address, or billing amounts.
    </p>
    <p >
      <strong>13. Fencing.</strong> For fence rental and installation, any new
      fence orders are subject to a minimum install fee and will be listed on
      the face of this agreement. If the fence is required for an additional
      length of time beyond the initial term listed on the face of this
      agreement, a residual (rental) fee will apply to all fence and related
      products on site. This residual percentage amount will appear on your
      agreement and will be invoiced each billing cycle until the fence is
      removed. All partial deliveries and removals are subject to a minimum trip
      charge as outlined in the agreement. Company does not engage in or sign
      project labor agreements (PLA). Customer will, however, provide fence to
      our customers for self-installation in these instances. Customer is
      responsible to provide the current project wage rates to Company, and
      company reserves the right to correct invoices at any time, should
      adjustments be required to account for any wage determinations or wage
      rates. Customer shall establish all property line stakes and grade stakes.
      Unless otherwise agreed, fences shall follow ground lines/level. Prior to
      install, Customer shall provide Company with the location and character of
      any underground pipes, sewers, wires, conduits, obstructions, conditions,
      or restrictions which may interfere with or be damaged at install or
      thereafter. Customer shall notify any and all underground service entities
      in advance of install. Customer shall cooperate fully in Company &#8217;s
      installation by (a) clearing a sufficient working area of all obstructions
      and removable hazards, including clearing all trees and brush for six feet
      on either side of fence location, (b) surveying, grading, locating, and
      staking fence line and identifying/verifying all property and utility
      lines, (c) notifying and safeguarding Company of all potential hazards,
      and (d) coordinating Company &#8217;s work with all others on the Site.
      Company may extend install deadlines and Customer shall pay for any
      expenses resulting from Customer &#8217;s compliance with these terms.
      Customer is responsible for all relocation/re-installation costs. Customer
      shall inspect and accept the fence within twenty-four (24) hours of
      installation.
    </p>
    <p >
      <strong>14. Conditional Payments.</strong> Any payment that Customer sends
      Company for less than the full balance due that is marked &#8220;paid in
      full&#8221; or contains a similar notation, or contains a similar
      notation, or that Customer otherwise tenders in full satisfaction of a
      disputed amount, must be sent to the address listed in Section 16. Company
      reserves all rights regarding these payments (e.g., Company may accept the
      check and Customer will still owe any remaining balance). Company may
      refuse to accept any such payment by returning it to Customer, not cashing
      it or destroying it.
    </p>
    <p >
      <strong>15. Price Adjustments:</strong> Company reserves the right to
      impose a price increase at any time with or without notice to Customer.
      Company retains the right to impose an energy &#38; compliance fee to
      invoices at Company &#8217;s discretion.
    </p>
    <p >
      <strong>16. Notices.</strong> Any required notice shall be in writing
      delivered to United Site Services, Inc. 118 Flanders Road, Suite 1000,
      Westborough, MA 01581 Attn: (Legal Department). Any notice given pursuant
      to this contract shall be considered duly given when received by the
      representatives of the parties hereto. For information about our privacy
      practices, go to
      <span style="color: rgb(0, 82, 155)"
        ><u>https://www.unitedsiteservices.com/privacy-policy</u>.</span
      >
    </p>
  </div>

  <div class="terms-condition">
    <div [formGroup]="basicInfoForm">
      <mat-checkbox color="primary" formControlName="privacy"
        > Accept Terms and Conditions
      </mat-checkbox>
      <mat-error
        *ngIf="
          basicInfoForm.value.privacy === false &&
          basicInfoForm.controls.privacy.touched
        "
      >
         Accept the Terms and Conditions.
      </mat-error>
    </div>
  </div>

  <div class="button-row">
    <button mat-stroked-button (click)="cancel()" >
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="accept()">Accept</button>
  </div>
</div>
