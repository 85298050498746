import { Component, effect } from "@angular/core";
import { AssetLocation, WorkOrder } from "src/app/models/order-model";
import { ScanService } from "../../services/scan.service";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { noDataAvlText } from "src/assets/config/constants";
import { OrderService } from "src/app/features/orders/services/order.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

@Component({
  selector: "app-asset-details",
  templateUrl: "./asset-details.component.html",
  styleUrls: ["./asset-details.component.scss"],
})
export class AssetDetailsComponent {
  btnconfig = {
    name: "Edit Order",
    url: "",
  };
  assetDetails: AssetLocation | undefined;
  lastServiceDetails: WorkOrder | undefined;
  nextServiceDetails: WorkOrder | undefined;
  serviceDetails: WorkOrder[] | [] = [];
  contractId: string | undefined = "";
  noDataAvlText = noDataAvlText;
  isEditOrderEnabled: boolean = false;    
  constructor(
    public scanService: ScanService,
    private router: Router,
    private _Activatedroute: ActivatedRoute,
    private toastr: ToastrService,
    private orderService: OrderService,
    private profileService: ProfileService 
    ) {
    effect(() => {
      const details = this.scanService.assetDetailsSignal();

      this.assetDetails = details?.assetDetails;
      this.contractId = details?.assetDetails.contractId;
      this.serviceDetails = details?.upcomingServices || [];
      this.lastServiceDetails = details?.completedServices.sort(
        (a, b) =>
          new Date(b.startDate).getMilliseconds() -
          new Date(a.startDate).getMilliseconds()
      )[0];

      this.nextServiceDetails = details?.upcomingServices[0];

      this.btnconfig.url = `orders/edit-order/${details?.assetDetails?.contractId}`;
      if (details == null) {
        this._Activatedroute.params.pipe(take(1)).subscribe((params) => {
          if (params.unitNumber && params.unitNumber.length >= 8) {
            this.scanService
              .getAssetDetailsByUnitNumber(params.unitNumber)
              .pipe(take(1))
              .subscribe((res) => {
                if (res) {
                  this.scanService.setAssetDetails(res);
                } else {
                  this.toastr.error("No asset found with this unit number");
                  router.navigate(["/home"]);
                }
              });
          } else {
            router.navigate(["/home"]);
          }
        });
      }
    });
  }
  ngOnInit() {
    this.orderService.orderSummaryBehaviorSubject.next(null);
    if(this.profileService.selectedAccount().myussModules?.myussCasesEnabled && this.profileService.selectedAccount().myussModules?.myussOrdersEnabled){
      this.isEditOrderEnabled = true;
    }  
}

  onTabChanged(index: number) {
    if (index === 0) {
      this.serviceDetails =
        this.scanService.assetDetailsSignal()?.upcomingServices || [];
    } else {
      this.serviceDetails =
        this.scanService.assetDetailsSignal()?.completedServices || [];
    }
  }
}
