import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyUSSWrapperComponent } from './components/myuss-wrapper/myuss-wrapper.component';
import { ThemeModule } from 'src/app/theme/theme.module';
import { RouterModule } from '@angular/router';
import { myUSSRoutes } from './myuss.routes';
import { ScanModule } from "../scan/scan.module";



@NgModule({
    declarations: [
        MyUSSWrapperComponent,
    ],
    exports: [
        MyUSSWrapperComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        ThemeModule,
        RouterModule.forChild(myUSSRoutes),
        ScanModule
    ]
})
export class MyUSSModule { }
