import { Injectable, signal } from '@angular/core';

export interface FixedButtonModel{
  name:string;
  isMainButton:boolean;
  isIconButton:boolean;
  icon:string;
  disabled:boolean;
  loading:boolean;
  componentName:string;
  strokedButton?:boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FixedButtonService {

  constructor() { }
  public buttonsSignal=signal<FixedButtonModel[]>([]);
  public clickedSignal=signal<FixedButtonModel | undefined>(undefined);

  setButtons(buttons:FixedButtonModel[]){
    this.buttonsSignal.set(buttons);
  }

  setClickedButton(button:FixedButtonModel){
    this.clickedSignal.set(button);
    setTimeout(() => {
      this.clickedSignal.set(undefined);
    }, 200);
  }

}


