<mat-card class="card" (click)="onCardClick()">
  <mat-card-header
    class="card-header pb-3"
    fxLayoutAlign="space-between center"
  >
    <mat-card-title class="m-0 card-title">
      {{ cardContent.cardHeader }}
    </mat-card-title>

    <div fxLayout="row" fxLayoutAlign="center center">
      <img
        *ngIf="cardContent.showEditIcon"
        src="../../../../assets/img/icons/edit-square-icon.svg"
        alt="edit icon to edit project details"
        width="22px"
        class="mr-2"
        (click)="onClick('edit')"
      />
      <img
        *ngIf="cardContent.showBillingIcon"
        src="../../../../assets/img/icons/dollar-icon.svg"
        alt="icon to navigate to billing screen"
        width="22px"
        class="mr-2"
        (click)="onClick('billing')"
      />
      <div
        *ngIf="cardContent.showStatus"
        class="status px-3"
        [ngStyle]="cardContent.statusStyle"
      >
        {{ cardContent.status }}
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="py-3 card-content">
    <table>
      <ng-container *ngFor="let content of cardBody">
        <tr>
          <td>{{ content.key }}</td>
          <td class="site_address_css padd_css">{{ content.value }}</td>
        </tr>
      </ng-container>
    </table>
  </mat-card-content>
</mat-card>