import { Component, Input } from "@angular/core";

@Component({
  selector: "app-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"],
})
export class TileComponent {
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() countFlag: boolean = false;
  @Input() count: number = 0;
  @Input() icon: string = "";
  @Input() isbulletPoints:boolean = false;
  @Input() bulletPoints: Array<string> = [];
  @Input() divider: boolean = false;
}
