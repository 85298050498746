import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

export const featureGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const profileService = inject(ProfileService);
  const feature = await profileService.getUserProfileValue(route.data.feature);
 
  if (feature) {
    return true;
  } else {
    router.navigate(["home"]);
    return false;
  }
};
