import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { AppConfig } from "../../../models/app-config";
// import {
//   AuthClientConfig,
//   AuthConfig,
//   AuthConfigService,
// } from "@auth0/auth0-angular";
import config from "capacitor.config";
import { Platform } from "@ionic/angular";
import { ProviderOptions } from "@ionic-enterprise/auth";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private readonly CONFIG_URL = "assets/config/config.json";
  public config: AppConfig;
  // authClientConfig: AuthClientConfig;
  handler: HttpBackend;
  isNative: boolean;

  constructor(handler: HttpBackend, private platform: Platform) {
    // this.authClientConfig = authClientConfig;
    this.handler = handler;
  }

  public getConfigProperty(key: string): any {
    return this.config[key];
  }
  public getMapKey(): string {
    if (this.platform.is("hybrid")) {
      if (this.platform.is("android")) {        
        return this.config["GOOGLE_MAP_API_KEY_ANDROID"];
      }
      if (this.platform.is("ios")) {        
        return this.config["GOOGLE_MAP_API_KEY_IOS"];
      }
    }
    return this.config["GOOGLE_MAP_API_KEY"];
  }

  public async load(): Promise<any> {
    await this.loadConfigurations();
    // this.loadAuthConfig();
    // this.loadStripeKey();
  }

  async getProviderOptions() {
    let auth0Config: ProviderOptions;
    this.isNative = this.platform.is("hybrid");
    const { appId } = config;
    if (!this.config?.auth0?.domain) {
      await this.loadConfigurations();
    }

    const redirectUri = this.isNative
      ? `${appId}://${this.config.auth0.domain}/capacitor/${appId}/callback`
      : `${window.location.origin}/login-callback`;

    const logoutUrl = this.isNative
      ? `${appId}://${this.config.auth0.domain}/capacitor/${appId}/callback`
      : `${window.location.origin}`;
    const discoveryUrl = `https://${this.config.auth0.domain}/.well-known/openid-configuration`;
    auth0Config = {
      audience: this.config.auth0.audience,
      clientId: this.config.auth0.clientId,
      discoveryUrl: discoveryUrl,
      logoutUrl: logoutUrl,
      redirectUri: redirectUri,
      scope: this.config.auth0.scope,
    };

    return auth0Config;
  }

  public async loadConfigurations(): Promise<any> {
    // TODO: do we want to not load config from a file in prod?
    // if that's the decision, then let's load everything from the environment
    console.log(`${window.location.origin}`);
    const client = new HttpClient(this.handler);
    const config = await firstValueFrom(client.get<AppConfig>(this.CONFIG_URL));
    this.config = config;
  }

  // public loadAuthConfig(): void {
  //   const authConfig = this.getAuthConfig();
  //   console.log(`loaded auth config`, authConfig);
  //   this.authClientConfig.set(authConfig);
  // }

  //load stripe key dynamically from config file on app initilization
  // public loadStripeKey(): void {
  //   const stripeKey = this.config.STRIPE_PUBLISHABLE_KEY;
  //   console.log(`loaded stripe key`, stripeKey);
  //   NgxStripeModule.forRoot(stripeKey);
  // }

  private getAuthConfig() {
    console.log(`getting auth config`);
    const native = this.platform.is("hybrid");
    const { appId } = config;
    const auth0Config = {
      domain: this.config.auth0.domain,
      clientId: this.config.auth0.clientId,
      authorizationParams: {
        redirect_uri: native
          ? `${appId}://${this.config.auth0.domain}/capacitor/${appId}/callback`
          : `${window.location.origin}/login-callback`, // this.config.auth0.redirectUri,
        audience: this.config.auth0.audience,
        discoveyurl: `https://${this.config.auth0.domain}/.well-known/openid-configuration`,
      },
      cacheLocation: "localstorage",
      httpInterceptor: {
        allowedList: [
          {
            uri: `${this.config.API_URL}api/*`,
            tokenOptions: {
              authorizationParams: {
                audience: this.config.auth0.audience,
                scope: this.config.auth0.scope,
              },
            },
          },
          // {
          //   uri: `${this.config.API_URL}rfq/*`,
          //   tokenOptions: {
          //     authorizationParams: {
          //       audience: this.config.auth0.audience,
          //       scope: this.config.auth0.scope,
          //     },
          //   },
          // },
        ],
      },
    };
    return auth0Config;
  }
}
