import { Component } from '@angular/core';
import {  UntypedFormBuilder, UntypedFormGroup,   Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { Platform } from "@ionic/angular";
import { ZipcodeService } from 'src/app/features/myuss/services/zipcode/zipcode.service';
import { PostalCode } from 'src/app/models/zipcode';

@Component({
  selector: 'app-landing-screen',
  templateUrl: './landing-screen.component.html',
  styleUrls: ['./landing-screen.component.scss']
})
export class LandingScreenComponent {
  public settings: Settings;
  public form: UntypedFormGroup; 
  loader: boolean = false;
  postalCode: string = '';
  isFocused:boolean=false;
  isNative: boolean;
  error: boolean = false;
  error2: boolean = false;
  
  

  constructor(public appSettings: AppSettings,
    public fb: UntypedFormBuilder,
    public router: Router,
    private auth: AuthenticationService,
    private platform: Platform,
    private zipcodeservice: ZipcodeService,
  ){
    this.settings = this.appSettings.settings;
    this.isNative = platform.is("hybrid");
    this.form = this.fb.group({
      postalCode: [
        null,
        Validators.compose([Validators.required, Validators.minLength(5)]),
      ],
    });
  }

  ngOnInit() {
    if(this.isNative){
      this.auth.authenticationChange$.subscribe((auth) => {
        if(auth){
          this.router.navigateByUrl("/home");
        }
      })
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }


  async onSubmit(form) {
    this.error2 = false;
    this.error = false;
    if (this.form.valid) {
      const code = this.form.value as PostalCode;
      this.zipcodeservice.zipcodeValidation(code).subscribe((res) => {
        if (res?.data["isServiceable"]) {
          this.router.navigateByUrl("/loading");
          this.login()
        } else {
          this.error = false;
        }
      });
    } else {
      this.error = true;
    }
  }
 
 
  onkey(e) {
    console.log(e);
    this.form.patchValue({ postalCode: e.replace(/[^0-9]/g, "") });
  }
  

  login() {
    this.auth.login();
  }

}

