import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-popup",
  templateUrl: "./confirmation-popup.component.html",
  styleUrls: ["./confirmation-popup.component.scss"],
})
export class ConfirmationPopupComponent {
  success: boolean;
  isInactiveProject:boolean = false;
  constructor(
    private dialogref: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean
  ) {
    this.success = data["success"];
    this.isInactiveProject = data["isInactiveProject"];
  }

  onClose() {
    this.dialogref.close(this.success);
  }
}
