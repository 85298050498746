import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {phoneNumber} from 'src/assets/config/constants';

@Component({
  selector: 'app-weekend-day-validation-popup',
  templateUrl: './weekend-day-validation-popup.component.html',
  styleUrls: ['./weekend-day-validation-popup.component.scss']
})
export class WeekendDayValidationPopupComponent {
  phoneNumber = phoneNumber;
  constructor(
    private dialogref: MatDialogRef<WeekendDayValidationPopupComponent>,
    public router: Router,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public isEditedData: any
    
  ) {}
  ngOnInit() {}

  onYes() {
    this.dialogref.close("yes");
  }
  onOk(){
   
    this.location.back();
  }
 
}
