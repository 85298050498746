import { Routes } from "@angular/router";
import { BaseComponent } from "./components/base/base.component";
import { RfqComponent } from "./components/rfq/rfq.component";
import { ZipSearchComponent } from "./components/zip-search/zip-search.component";
import { UserInviteComponent } from "./components/user-invite/user-invite.component";
import { LoginCallbackComponent } from "./components/login-callback/login-callback.component";
import { rfqGuard } from "src/app/guards/rfq.guard";
import { PasswordResetComponent } from "./components/password-reset/password-reset.component";
import { EasyPayComponent } from "./components/easy-pay/easy-pay.component";
// import { RestrictedAccessComponent } from "./components/restricted-access/restricted-access.component";
import { isRegisteredGuard } from "src/app/guards/is-registered.guard";
import { RestrictedAccessComponent } from "src/app/shared/components/restricted-access/restricted-access.component";
import { authGuard } from "src/app/guards/auth/auth.guard";
import { LandingScreenComponent } from "./components/landing-screen/landing-screen.component";

export const routes: Routes = [
  // TODO: after completing refactor, the Base component should be the default route
  // { path: '', component: BaseComponent, pathMatch: "full" },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'password-reset/thank-you', component: PasswordResetComponent },
  { path: "zip-search", component: ZipSearchComponent },
  { path: "user-invite", component: UserInviteComponent },
  { path: "easypay-redirect", component: EasyPayComponent },
  { path: "landing-screen", component: LandingScreenComponent },
  

  {
    path: "rfq-redirect",
    canActivate: [rfqGuard],
    component: RfqComponent,
    data: { breadcrumb: "loading" },
  },
  {
    path: "login-callback",
    component: LoginCallbackComponent,
  },
  {
    path: "restricted-access", 
    canActivate:[authGuard],
    component: RestrictedAccessComponent,
  },
 
];
