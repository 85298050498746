import { is } from 'date-fns/locale';
import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ScanService } from "src/app/features/scan/services/scan.service";
import { phoneNumber } from "src/assets/config/constants";

@Component({
  selector: "app-search-unit-number",
  templateUrl: "./search-unit-number.component.html",
  styleUrls: ["./search-unit-number.component.scss"],
})
export class SearchUnitNumberComponent {
  unitNumber: FormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(10),
  ]);
  errorFlag: boolean = false;
  phoneNumber: string = phoneNumber
  isMobile: boolean = false;
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<SearchUnitNumberComponent>,
    private scanService: ScanService,
    private toastr: ToastrService
  ) {}
  ngOnInit() {
   
    if (window.innerWidth <= 600) {
      
      
      // this.isSerchByUnitOnWeb = false
     
      this.isMobile = true;
    } else {
     
     
    
      this.isMobile = false;
      
    }
  }

  onInput(inputString: string) {
    this.unitNumber.setValue(inputString.replace(/[^0-9]/g, ""));
  }
  
  close() {
    this.dialogRef.close();
  }

  search() {
    if (this.unitNumber.valid) {
      // this.scanService.setUnitSearchState(true);
     
      this.scanService
        .getAssetDetailsByUnitNumber(this.unitNumber.value)
        .subscribe((res) => {
          if (res) {
            this.errorFlag = false;
            this.scanService.setAssetDetails(res);
            this.dialogRef.close();
            this.router.navigate(["/scan/assest-details",this.unitNumber.value]);
          } else {
            this.errorFlag = true;
          }
        });
    }
  }
}
