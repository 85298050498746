import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppBarTitleService {
  constructor() {}

  pageTitleSignal = signal("My Site Services");

  setPageTitle(newTitle: string): void {
    this.pageTitleSignal.set(newTitle);
  }
}
