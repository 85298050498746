<div class="p-2 pl-3">
    <div>
        Select Location
    </div>
</div>

<mat-divider></mat-divider>

<div class="m-3">

<div>
    <p>Select which location you want to assign units on?</p>
</div>

<div class="content_div mt-1 w-100" >
    <mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    color="primary"
    [(ngModel)]="selectedSite"
    >
    <div class="radio_css mb-3" *ngFor="let site of jobSites;let i = index">
        <mat-radio-button class="example-radio-button" [value]="site"> <p>
    {{site.address.name}}
          </p>
        <p class="placement_note">
           {{site.address.placementNotes}}
        </p> </mat-radio-button>
    </div>      
  </mat-radio-group>
</div>
</div>


<mat-divider class=""></mat-divider>
<div class="btn_Div p-2 pr-3">
    <button class="btnsize" mat-raised-button (click)="onCancel()">Cancel</button>
    <button class="btnsize ml-2"  mat-raised-button color="primary" (click)="onSave()">Save</button>
</div>