import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appHideIfMediaQuery]'
})
export class HideIfMediaQueryDirective implements OnInit, OnDestroy {
  @Input('appHideIfMediaQuery') mediaQuery: string;
  private hasView = false;
  private subscription: Subscription;

  constructor(
    private templateRef: TemplateRef<{}>,
    private viewContainer: ViewContainerRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.subscription = this.breakpointObserver.observe(this.mediaQuery).subscribe(result => {
      if (result.matches) {
        if (!this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        }
      } else {
        if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
