<section *ngIf="contractFlag">
  <!-- search for orders list -->
  <div *ngIf="endPoint === 'orders'" class="search_filter mt-3 w-100">
    <div
      class="search_filter_css"
      [ngClass]="{ 'w-100': contractsFilter == 'Canceled' }"
    >
      <mat-form-field class="search_icon" appearance="outline">
        <mat-icon
          matPrefix
          aria-label="Search orders through order id or site address or status"
          role="img"
          aria-hidden="false"
          >search</mat-icon
        >
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="search"
          #searchInput
        />
      </mat-form-field>
    </div>

    <div
      class="menu_Filter pl-2"
      *ngIf="contractsFilter == 'Activated,Suspended,Draft'"
    >
      <button class="filter_button" [matMenuTriggerFor]="menuFilter">
        <img
          src="../../../../assets/img/icons/filter.png"
          role="img"
          aria-label="Filter orders based on status"
          aria-hidden="false"
        />
      </button>
      <mat-menu #menuFilter="matMenu">
        <p class="px-3 py-2" (click)="onFilterSelect('All')">All</p>
        <p
          class="px-3 py-2"
          *ngFor="let status of allStatus"
          (click)="onFilterSelect(status)"
        >
          {{ status }}
        </p>
      </mat-menu>
    </div>

    <div></div>
  </div>

  <div
    [ngClass]="{
      button_filter: projectTypeFilter.value === 'Orders with no project assign'
    }"
  >
    <div
      *ngIf="
        endPoint === 'orders' && flags?.myussProjectsEnabled && projectEnabled
      "
    >
      <mat-form-field class="project_filter w-100" appearance="outline">
        <mat-label>Project filter</mat-label>
        <mat-select
          placeholder="Ex. Active"
          (selectionChange)="onProjectFilter()"
          [formControl]="projectTypeFilter"
        >
          <mat-option value="All">All</mat-option>
          <mat-option
            *appHideIfReadOnly
            [value]="'Orders with no project assign'"
            >Orders with no project assign</mat-option
          >
          <mat-option
            *ngFor="let project of projectList"
            [value]="project.id"
            >{{ project.name }}</mat-option
          >
        </mat-select>
        <mat-icon
          matPrefix
          role="img"
          aria-label="Filter orders based on project"
          aria-hidden="false"
          ><img
            src="../../../../assets/img/icons/filter.svg"
            aria-hidden="true"
        /></mat-icon>
      </mat-form-field>
    </div>
    <div>
      <button
        class="mb-4"
        [ngClass]="{
          font_size: projectTypeFilter.value === 'Orders with no project assign'
        }"
        (click)="onAssignProject()"
        *ngIf="projectTypeFilter.value === 'Orders with no project assign'"
        mat-raised-button
        color="primary"
      >
        Assign to Project
      </button>
    </div>
  </div>

  <!-- search for case list -->
  <div class="search-and-filter">
    <div *ngIf="endPoint === 'cases' || endPoint === 'isOrderScreen'">
      <div class="w-100">
        <mat-form-field
          class="case-list-search prefix-icon-position w-100"
          appearance="outline"
        >
          <mat-icon matPrefix class="center-icon">search</mat-icon>
          <mat-label>Search</mat-label>
          <input
            matInput
            (keyup)="searchCases($event)"
            placeholder="search"
            #searchInput
          />
        </mat-form-field>
      </div>

      <div class="child child-two">
        <mat-slide-toggle
          [aria-label]="isCaseClosed.toString()"
          color="primary"
          [checked]="isCaseClosed"
          (change)="onToggleChange($event)"
        >
          <span *ngIf="screen != 'technical-support-list'">
            Show closed cases
          </span>
          <span *ngIf="screen == 'technical-support-list'">
            Show closed requests
          </span>
        </mat-slide-toggle>
      </div>
      <!-- This is the filter button -->
      <div *ngIf="isCaseClosed" class="filter">
        <mat-form-field class="case-list-search" appearance="outline">
          <mat-label>Duration</mat-label>
          <mat-select
            [(ngModel)]="selectedOption"
            (selectionChange)="selectedValue()"
          >
            <!-- <mat-option value="None">None</mat-option> -->
            <mat-option value="30 days">1 Month</mat-option>
            <mat-option value="60 days">2 Months</mat-option>
            <mat-option value="90 days">3 Months</mat-option>
            <mat-option value="Custom Date">Custom Date</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="date-filter">
        <div *ngIf="showFromToDate" class="filter">
          <mat-form-field
            class="example-full-width mt-4"
            class="date-filter-css"
            appearance="outline"
          >
            <mat-label>From Date</mat-label>
            <input
              matInput
              [matDatepicker]="fromDatePicker"
              (dateChange)="selectedStartDate($event)"
              [max]="maxDate"
            />
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matIconSuffix [for]="fromDatePicker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="showFromToDate" class="filter">
          <mat-form-field
            class="example-full-width mt-2"
            class="date-filter-css"
            appearance="outline"
          >
            <mat-label>To Date</mat-label>
            <input
              matInput
              [matDatepicker]="toDatePicker"
              (dateChange)="selectedEndDate($event)"
              [max]="maxDate"
              [min]="minEndDate"
            />
            <mat-datepicker-toggle matIconSuffix [for]="toDatePicker">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="selectedOption == 'Custom Date'">
          <button
            [disabled]="bothDateSelected()"
            class="custom-date-css"
            mat-raised-button
            color="primary"
            class="mb-4"
            (click)="getCustomDateClosedCases()"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="isIos ? 'card_size_ios' : 'card_size'">
    <!-- for order list -->
    <div *ngIf="endPoint === 'orders'">
      <div
        class="card_div order_list pb-2"
        *ngFor="let contractData of contracts"
      >
        <mat-card class="custom-card card_css">
          <mat-card-header
            class="card_header"
            fxLayoutAlign="space-between center"
          >
            <mat-card-title class="m-0">
              <mat-checkbox
                color="primary"
                (change)="onChecked(contractData.quoteId, $event)"
                *ngIf="
                  projectTypeFilter.value === 'Orders with no project assign'
                "
              ></mat-checkbox>
              {{ contractData.name }}</mat-card-title
            >
            <div class="px-2">
              <div class="action-button">
                <button
                  matTooltip="Edit Order"
                  [ngClass]="{
                    'disable-action':
                      contractData.status != 'Activated' ||
                      contractData.contractNumber == '',
                    'active-action':
                      contractData.status === 'Activated' ||
                      contractData.contractNumber
                  }"
                  (click)="
                    contractData.status === 'Activated' &&
                      editOrder(contractData.contractId)
                  "
                  [disabled]="
                    contractData.status != 'Activated' &&
                    contractData.contractNumber == ''
                  "
                  class="icon-button"
                >
                  <img
                    *ngIf="!isInActiveAccount"
                    src="../../../../assets/img/icons/edit_square_icon.svg"
                    alt="edit icon to edit user details"
                  />
                </button>
                <div
                  *ngIf="
                    contractData.status == 'Activated' ||
                      contractData.status == 'Canceled';
                    else other_Status
                  "
                >
                  <div
                    *ngIf="
                      contractData.status == 'Activated' &&
                      contractData.contractNumber
                    "
                    class="active_status px-3"
                  >
                    {{ contractData.status }}
                  </div>
                  <div
                    *ngIf="
                      contractData.status == 'Activated' &&
                      contractData.contractNumber == ''
                    "
                    class="other_status px-3"
                  >
                    In-progress
                  </div>

                  <div
                    *ngIf="contractData.status == 'Canceled'"
                    class="cancel_status px-3"
                  >
                    {{ contractData.status }}
                  </div>
                </div>
              </div>
              <ng-template #other_Status>
                <div class="other_status px-3">
                  {{ contractData.status }}
                </div>
              </ng-template>
            </div>
          </mat-card-header>
          <mat-card-content (click)="onVisibility(contractData)" class="py-3">
            <table class="order_list">
              <tr>
                <td>Site Address</td>
                <td class="site_address_css padd_css">
                  {{ contractData?.shippingAddress || "-" }}
                </td>
              </tr>
              <tr>
                <td>Payment Method</td>
                <td>
                  <div fxLayoutAlign="start center">
                    <p *ngIf="contractData.easyPayDetails?.cardNo">
                      <span
                        *ngIf="contractData.easyPayMode == 'card'"
                        class="p-1 pl-1"
                        fxLayoutAlign="start center"
                      >
                        <img
                          [src]="
                            cardIcon +
                            contractData.easyPayDetails?.cardBrand +
                            '.png'
                          "
                          alt="Payment method card logo"
                          *ngIf="
                            [
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(
                              (contractData?.easyPayDetails)!.cardBrand
                            )
                          "
                          height="20px"
                          class="pl-1"
                        />
                        <mat-icon
                          *ngIf="
                            ![
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(
                              (contractData?.easyPayDetails)!.cardBrand
                            )
                          "
                          class="p-1"
                          >credit_card</mat-icon
                        >
                      </span>

                      <mat-icon
                        *ngIf="contractData.easyPayMode == 'bank'"
                        aria-label="Payment method bank logo"
                        role="img"
                        aria-hidden="false"
                        class="p-1"
                        >account_balance</mat-icon
                      >
                    </p>
                    <p *ngIf="contractData.easyPayDetails?.cardNo">****</p>
                    <p class="cardNo_div padd_css">
                      {{
                        contractData.easyPayDetails?.cardNo
                          ? contractData.easyPayDetails?.cardNo
                          : "Manual"
                      }}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
           
            <mat-expansion-panel (click)="$event.stopPropagation()" [disabled]="contractData?.casesCount === 0" class="mt-2">
              <mat-expansion-panel-header>
            <p *ngIf="contractData?.casesCount == 0" class="open-case">
              Open Cases <span class="case-count">{{ 0 }}</span>
            </p>
            <p *ngIf="contractData?.casesCount !== 0" class="open-case">
              Open Cases <span class="case-count">{{contractData?.casesCount}}</span>
            </p>
            </mat-expansion-panel-header>
            <mat-divider></mat-divider>

            <div class="flex-container">
              {{ getCaseTypeCount(contractData.caseTypeWiseCasesCount) }}
              <div *ngIf="contractData.casesCount !== 0">
                <div class="caseTypes">
                  <img
                    src="../../../../../assets/img/ReduceQuantity.svg"
                    alt="Change Quantity image"
                  />
                  <p class="case-name">
                    {{ cases[0].caseType }}: {{ cases[0].caseCount }}
                  </p>
                </div>

                <div class="caseTypes">
                  <img
                    src="../../../../../assets/img/AddQuantity.svg"
                    alt="Change Quantity image"
                  />
                  <p class="case-name">
                    {{ cases[1].caseType }}: {{ cases[1].caseCount }}
                  </p>
                </div>

                <div class="caseTypes">
                  <img
                    src="../../../../../assets/img/AddNewUnit.svg"
                    alt="Add New Unit Type image"
                  />
                  <p class="case-name">
                    {{ cases[2].caseType }}: {{ cases[2].caseCount }}
                  </p>
                </div>

                <div class="caseTypes">
                  <img
                    src="../../../../../assets/img/ChangeServiceFrequency.svg"
                    alt="Change Service Frequency image"
                  />
                  <p class="case-name">
                    {{ cases[3].caseType }}: {{ cases[3].caseCount }}
                  </p>
                </div>

                <div class="caseTypes">
                  <img
                    src="../../../../../assets/img/Move.svg"
                    alt="Move image"
                  />
                  <p class="case-name">
                    {{ cases[4].caseType }}: {{ cases[4].caseCount }}
                  </p>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <!-- for case list -->
    <div *ngIf="endPoint !== 'orders' && isCases">
      <div
        class="card_div order_list pb-2"
        (click)="viewCaseDetails(case.id)"
        *ngFor="let case of allCases"
      >
        <mat-card class="custom-card card_css">
          <mat-card-header
            class="card_header"
            fxLayoutAlign="space-between center"
          >
            <mat-card-title
              class="m-0"
              *ngIf="screen != 'technical-support-list'"
              >Case No:{{ case.caseNumber }}</mat-card-title
            >
            <mat-card-title
              class="m-0"
              *ngIf="screen == 'technical-support-list'"
              >Support No:{{ case.caseNumber }}</mat-card-title
            >

            <div class="px-2">
              <div
                *ngIf="
                  case.status == 'New' ||
                    case.status == 'Closed' ||
                    case.status == 'In Progress' ||
                    case.status == 'Waiting on USS Team';
                  else other_Status
                "
              >
                <div *ngIf="case.status == 'New'" class="active_status px-3">
                  {{ case.status }}
                </div>

                <div *ngIf="case.status == 'Closed'" class="cancel_status px-3">
                  {{ case.status }}
                </div>
                <div
                  *ngIf="case.status == 'In Progress'"
                  class="inprogress-status px-3"
                >
                  {{ case.status }}
                </div>
                <div
                  *ngIf="case.status == 'Waiting on USS Team'"
                  class="inprogress-status px-3"
                >
                  In Progress
                </div>
              </div>
              <ng-template #other_Status>
                <div class="other_status px-3">
                  {{ case.status }}
                </div>
              </ng-template>
            </div>
          </mat-card-header>
          <mat-card-content class="py-3">
            <table class="order_list">
              <tr *ngIf="endPoint == 'cases'">
                <td>Site Address</td>
                <td class="cases-parameter">
                  {{ case.orderAddress?.street }}<br />
                  {{ case.orderAddress?.city }}, {{ case.orderAddress?.state }}
                  {{ case.orderAddress?.zipcode }}
                </td>
              </tr>

              <tr>
                <td>Opening Date</td>
                <td class="cases-parameter">
                  {{ case.createdDate | date : "MM/dd/yyyy" }}
                </td>
              </tr>
              <tr>
                <td>Last Updated</td>
                <td class="cases-parameter">
                  {{ case.lastModifiedDate | date : "MM/dd/yyyy" }}
                </td>
              </tr>
              <tr *ngIf="screen == 'technical-support-list'">
                <td>Subject</td>
                <td class="cases-parameter">
                  {{ case.subject }}
                </td>
              </tr>
              <tr *ngIf="screen != 'technical-support-list'">
                <td>Request Type</td>
                <td class="cases-parameter">{{ getCaseType(case.type) }}</td>
              </tr>
              <tr *ngIf="screen != 'technical-support-list'">
                <td>MyUss Case Type</td>
                <td class="cases-parameter">{{ case.myussCaseType }}</td>
              </tr>
              <tr *ngIf="endPoint == 'cases'">
                <td>Order ID</td>
                <td class="cases-parameter">
                  {{ getOrderNumber(case.orderNumber) }}
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div *ngIf="!isCases && !isOrderScreen" class="mt-1" fxLayoutAlign="center">
      <h2 class="no-cases" *ngIf="screen != 'technical-support-list'">
        There are no cases related to the account.
      </h2>
      <h2 class="no-cases" *ngIf="screen == 'technical-support-list'">
        There are no support request.
      </h2>
    </div>
    <div
      *ngIf="!isOrderCases && isOrderScreen"
      class="mt-1"
      fxLayoutAlign="center"
    >
      <h2 class="no-cases">There are no cases related to the order.</h2>
    </div>
    <div class="no_Data" *ngIf="!contractFlag || contracts?.length == 0">
      <p>No data available</p>
    </div>
  </div>
</section>
