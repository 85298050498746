<div class="my-google-map" ngClass.xs="p-0">
  <div *ngIf="showLabel" class="mb-1 p-2 px-3 label">
    <h3>Active Contracts Location</h3>
  </div>
  <google-map  [options]="options">
    <map-marker
    #markers="mapMarker"
    *ngFor="let marker of data"
    [position]="{ lat: marker.shippingAddressLat, lng: marker.shippingAddressLng }"
    (mapClick)="openInfoWindow(markers ,marker)"
  ></map-marker>
  <map-info-window>
    <!-- Use *ngFor to iterate over the combined list of order IDs and contract IDs -->
    <div >
      <ng-container *ngFor="let entry of orderAndContractIds; let i = index">
        <mat-chip class="order-chip" (click)="openOrderSummary(entry.contractId)">
          {{ entry.orderId }}
        </mat-chip>
      </ng-container>
    </div>
    <div class="info-window-content">{{ shippingAddress }} </div>
  </map-info-window>
  </google-map>
</div>
