import { id } from "date-fns/locale";
import { String } from "lodash";
import { BillingAddress, Contact } from "./quoteState-model";
import { AncillaryService, ProductModel } from "./product-model";
import { ProjectModel } from "./project-model";
import { QuoteModel } from "./quote-line-model";
import { Address } from "./address-model";
import { ProductDetails } from "./jobsite-model";
// import {QuoteModel} from './quote-model';

export class Contracts {
  billAddress: LatLng;
  quoteId: string;
  currentStatus: number;
  contractId: string;
  lastModifiedDate: string;
  startDate: string;
  name: string;
  shippingAddress: string;
  status: string;
  zipcode: string;
  shippingAddressLat: number;
  shippingAddressLng: number;
  paymentMethodId: string;
  recurringSubtotal: number;
  nextInvoiceDate: string;
  isAutoPay: boolean;
  easyPayDetails: EasyPayDetails;
  easyPayMode: string;
  projectDetails :ProjectModel;
  contractNumber?: string;
  casesCount?: number;
  caseTypeWiseCasesCount: any;

}

export class EasyPayDetails {
  cardBrand: string;
  cardNo: string;
  cardExpMonth: number;
  cardExpYear: number;
  country: string;
  displayBrand: string;
  expired: boolean;
}

export class LatLng {
  lat: number;
  lng: number;
}

export class OrderModel {
  contractId: string;
  status: string;
  quoteName: string;
  startDate: string;
  endDate: string;
  quoteSummary: QuoteSummary[];
  siteDetails: SiteDetails;
  billingDetails: BillingDetails;
  workOrder: WorkOrder[];
  assetLocations: AssetLocation[];
  productDetails: EditOrderState[];
  contractDocumentName: string;
  quoteId: string;
  contractDocumnetId: string;
  quoteModel: QuoteModel;
  contractNumber?: string;
}
export class QuoteSummary {
  bundleId: string;
  bundleQty: Number;
  bundleName: string;
  bundleOptionalId: string;
  asset: Asset[];
  service: Service[];
  ancillaryServices: AncillaryServices[];
  pAndD: PAndD[];
  scheduledServices: ScheduledServices;
}
export class Asset {
  assetId: string;
  assetOptionalId: string;
  assetQty: Number;
  assetName: string;
}

export class Service {
  serviceId: string;
  serviceOptionalId: string;
  serviceQty: Number;
  serviceName: string;
}
export class AncillaryServices {
  ancillaryServiceId: string;
  ancillaryServiceOptionalId: string;
  ancillaryServiceQty: Number;
  ancillaryServiceName: string;
}
export class PAndD {
  aAndDId: string;
  aAndDQty: Number;
  aAndDName: string;
}
export class ScheduledServices {
  startDate: String;
  endDate: String;
  day: String;
  frequency: Number;
  serviceFrequency: String;
  frequencyType: String;
}

export class SiteDetails {
  firstName: String;
  lastName: String;
  email: String;
  phone: String;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  street: string;
  siteStartTime: string | null;
  siteEndTime: string | null;
  placementNotes: PlacementNotes;
  fullName: string;
  id: string;
}
export class PlacementNotes {
  siteInstruction: String | null;
  placementInstruction: String | null;
}
export class BillingDetails {
  firstName: String;
  lastName: String;
  secondaryBillingContact: Contact;
  phone: String;
  cardDetails: Card;
  bankDetails: Bank;
  manualPaymentDetails: ManualPaymentDetails;
  billingAddress: BillingAddress;
}
export class Card {
  cardBrand: string;
  cardNo: string;
  cardExpMonth: number;
  cardExpYear: number;
  country: string;
}
export class Bank {
  accountHolderType: string;
  bankName: string;
  cardNo: number;
  email: string;
}
export class ManualPaymentDetails {
  message: string;
}
export class WorkOrder {
  type: string;
  status: string;
  startDate: string;
  endDate: String;
  id: String;
}

export class AssetLocation {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  assetName: string;
  serviceName: string;
  quantity: number;
  parentAddress: string;
  siteAddress: string;
  placementNote: string;
  subscriptionProductId?: string;
  serviceProductId?: string;
  jobsiteId?: string;
  bundleId?: string;
  bundleName?: string;
  ancillaryServiceList?: AncillaryService[];
  unitNumber?: string;
  contractId?: string;
  orderNumber?: string;
  assetId?: string;
  siteAddressId?: string;
  orderStatus?: string;
  numberOfServices?: number;
  servicePrice?: number;
  isServiceContractedPrice?: boolean;
}
export class Bundle {
  bundleId: string;
  bundleName: string;
  quantity: number;
  assetId: string;
  assetName: string;
  frequency: string;
  jobsiteId: string;
  serviceId: string;
  serviceName: string;
  serviceProductId: string;
  serviceSubscriptionId: string;
  ancillaryServiceList: AncillaryServices[];
  endDate: string;
  id: string;
  name: string;
  parentAddress: string;
  placementNote: string;
  startDate: string;
  addressId: string;
  columnId: string;
  placementNotes: string;
  quantityQuoted: number;
  siteName: string;
  isEdited: boolean;
  columnData: ColumnData;
  totalQuantity: number;
  subscriptionProductId: string;
  unitNumber: string;
}
export class ColumnData {
  addressId: string;
  bundles: Bundle[];
  columnId: string;
  endDate: string;
  placementNotes: string;
  quantityQuoted: number;
  siteName: string;
  startDate: string;
  isEdited: boolean;
  bundleName: string;
  jobsiteId: string;
  subscriptionProductId: string;
  bundleId: string;
  serviceProductId: string;
  bundlesData: Bundle[];
  contractId: string;
  address: Address;
  productDetails: ProductDetails;

  contact: Contact;
  isSearchByUnit?: boolean;
}

export class EditOrderState {
  addressId: string;
  bundleName: string;

  columnId: string;
  endDate: string;
  startDate: string;
  placementNotes: string;
  quantityQuoted: number;
  siteName: string;
  bundles: Bundle[];
  isEdited: boolean;
  columnData: ColumnData;
  bundlesData: Bundle[];
  jobsiteId: string;
  subscriptionProductId: string;
  bundleId: string;
  serviceProductId: string;
  contractId: string;
  serviceSubscriptionId: string;
  sitewiseSelectedBundle: ProductDetails;
  selectedSite: Address;
  siteDetails: Address;
  orderName: string;
  siteContact: Contact;
  isSerchByUnit: boolean;
}

export class EditOrderModel {
  type: string;
  quantity: number;
  jobsiteId: string | undefined;
  assetId: string;
  changedDate?: string;
  startDate: string;
  endDate: string;
  serviceSubscriptionId: string;
  serviceProductId: string;
 
  pickupReasonCode: string;
  orderId: string;
  rootSub: string;
  unitNumbers: string[];
  note: string;
  
}

export class EditedData{
  message: string;
  drawerStatus: boolean;
  reduceQuantityMessage: string;

}


