
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, Renderer2, signal, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from 'src/app/features/accounts/services/profile/profile-services.service';
import { CaseDetailsStateService } from 'src/app/features/cases/services/case-details-state.service';
import { allowedExtensions, fileSizeLimit, maxNoOfFiles } from 'src/assets/config/constants';
import { ToastrService } from 'ngx-toastr';
import { FileDetails, UploadDialogInput } from 'src/app/models/case-details-model';
import { Platform } from '@ionic/angular';

import {MatExpansionModule, MatExpansionPanel} from '@angular/material/expansion';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  providers: [MatExpansionModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDialogComponent {
  
  public form: FormGroup;
  public files: File[];
  public allFiles: FileDetails[] = [];
  @Input() record: UploadDialogInput;
  public isCommentScreen = false;
  public maxFilesContant = maxNoOfFiles;
  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  showUploadDiv = false;
  isAndroid = false;
  readonly panelOpenState = signal(false);

  constructor(public dialogRef: MatDialogRef<UploadDialogComponent>,
    private builder: FormBuilder,
    private caseService: CaseDetailsStateService,
    private profileService: ProfileService,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogInput,
    private platform: Platform,
    private renderer:Renderer2,
    private cdRef: ChangeDetectorRef
  ) {
    this.isAndroid = platform.is('android');
    this.form = this.builder.group({
      files: ['', Validators.required]
    });
    if (this.data.type === 'comment') {
      this.isCommentScreen = true;
    }
    
  }
  ngAfterViewInit(): void {
    this.handleExpansionPanel();
   
  }
  // ngAfterContentInit(): void {
  //   this.cdr.detectChanges();
  // }

  handleExpansionPanel() {
    console.log('handleExpansionPanel', this.isCommentScreen);
    setTimeout(() => {

    if (this.isCommentScreen) {
      this.expansionPanel.open();
    } else {
      this.expansionPanel.close();
    }
  }, 1000);
  }
  onCancel(): void {
    // Logic to handle Cancel
    this.dialogRef.close();
  }
  truncateFileName(name: string, limit: number): string {
    if (name.length > limit) {
      return name.substring(0, limit) + '...';
    }
    return name;
  }
  getImageByExtension(extension: string): string {
    switch (extension) {
      case 'pdf':
        return '../../../assets/img/icons/pdf.svg';
      case 'png':
        return '../../../assets/img/icons/png.svg';
      case 'jpg':
        return '../../../assets/img/icons/jpg.svg';
      case 'jpeg':
        return '../../../assets/img/icons/jpg.svg';
      case 'doc':
        return '../../../assets/img/icons/doc.svg';
      case 'docx':
        return '../../../assets/img/icons/docx.svg';
      default:
        return '../../../assets/img/icons/doc.svg';
    }
  }
  validateFiles(event) {
    this.files = event;
    //calculate max no. of files that can be uploaded(one session)
    let maxFiles = this.maxFilesContant - this.allFiles.length;
    if (maxFiles == 0) {
      this.toastr.error("You have uploaded maximum number of files.");
      return;
    }
    let notSupportedFile = false;
    let count = this.files.length <= maxFiles ? this.files.length : maxFiles;
    for (let i = 0; i < count; i++) {
      let file = this.files[i];
      let extension = file.name.split('.').pop()?.toLowerCase() || '';
      if (allowedExtensions.indexOf(extension) >= 0 && file.size < fileSizeLimit) {
        this.allFiles.push({
          name: file.name,
          size: file.size,
          type: file.type,
          status: 'In process',
          loader: true,
          file: file
        });
        this.uploadFiles(file);
        this.hideDiv();
      } else {
        notSupportedFile = true;
      }
    }
    if (notSupportedFile) {
      this.toastr.error("For some files format is not supported.");
    }
  }
  async uploadFiles(file) {
   
    await setTimeout(() => {
      const formData = new FormData();
      formData.append('file', file);
      let entityId = "";
      if (this.record) {
        //caseId
        entityId = this.record.id;
      } else {
        //commentId
        entityId = this.data.id;
      }
      this.caseService.uploadAttchment(`${this.profileService.selectedAccount().accountId}`,
        `${entityId}`, formData)
        .subscribe((res) => {
          console.log("res", this.allFiles);
          this.allFiles=this.allFiles.map(tempfile => {
            if (file.name === tempfile.name && file.size === tempfile.size) {
              tempfile.loader = false;
              if (res == 1000) {
                tempfile.status = 'Success';
              } else {
                tempfile.status = 'Retry';
              }
              return tempfile;
            }
            return tempfile;
          });
          this.cdRef.detectChanges(); 
        });
    }, 3000);
  }
  onRetry(file: FileDetails) {
    file.status = 'In process';
    file.loader = true;
    this.uploadFiles(file.file);
  }

  
  hideDiv() {
    this.showUploadDiv = false;
  }

  showDiv() {
    this.showUploadDiv = true;
  }
 
   
  

}
