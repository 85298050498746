import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class HelperFunctionService {
  constructor() {}

  space(event: any) {
    // console.log(event.target.selectionStart, event.code);
    if (
      ["Space", "Enter"].includes(event.code) &&
      event.target.selectionStart == 0
    ) {
      event.preventDefault();
    }
  }

   toCamelCase(str: string): string {
    const words = str?.split(' ');
    const camelCasedRest = words
      ?.map((word, index) => {
        return index === 0
          ? word.toLowerCase() 
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');

    return camelCasedRest;
  }

  convertStringToObject(inputString: string): Record<string, boolean> {
    const keysArray = inputString?.split(';');
    return keysArray?.reduce((acc: Record<string, boolean>, key: string) => {
      const trimmedKey = key.trim();
      const camelCaseKey = this.toCamelCase(trimmedKey);
      acc[camelCaseKey] = true;
      return acc;
    }, {});
  }

  getMatchingTrueFields(userModules:Record<string,boolean>, accountModules:Record<string,boolean>) {
    if(!userModules || !accountModules) return {};
    let enabledModules:Record<string,boolean> = {};
  
    for (let module in userModules) {
      if (userModules.hasOwnProperty(module) && userModules[module] === true && accountModules[module] === true) {
        enabledModules[module] = true;
      }
    }
  
    return enabledModules;
  }


}
