import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@Component({
  selector: 'app-inprogress-request-pop-up',
  standalone:true,
  templateUrl: './inprogress-request-pop-up.component.html',
  styleUrls: ['./inprogress-request-pop-up.component.scss'],
  imports:[FlexLayoutModule,MatIconModule]
})
export class InprogressRequestPopUpComponent {

  constructor(private dialogref: MatDialogRef<InprogressRequestPopUpComponent>) {}
  
  onClose() {
    this.dialogref.close();
  }
}
