import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-condition-popup',
  templateUrl: './terms-and-conditions-popup.component.html',
  styleUrls: ['./terms-and-conditions-popup.component.scss']
})
export class TermsAndConditionPopupComponent {
  basicInfoForm: FormGroup;
  isAccepted: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public addressId: string,
    private fb: FormBuilder,
  ) { 
    this.basicInfoForm = fb.group({
      privacy: [false, [Validators.required]],
    });


    // Subscribe to valueChanges of the privacy control
    this.basicInfoForm.get('privacy')?.valueChanges.subscribe(value => {
      console.log("ia accepted value",value);
      this.isAccepted = value; // Update isAccepted flag based on checkbox value
    });
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  accept() {
    if (this.isAccepted) {
      this.dialogRef.close({ action: 'accept' });
    }
    this.basicInfoForm.markAllAsTouched();
  }
}
