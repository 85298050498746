/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { LoggerInterceptor } from "./logger/logger-interceptor";
import { HttpErrorInterceptor } from "./http-error/http-error.interceptor";
import { GlobalLoaderInterceptor } from "./global-loader/global-loader.interceptor";
import { authInterceptorInterceptor } from "./auth-interceptor/auth-interceptor.interceptor";
import { AccountIDInterceptor } from "./account-id/account-id.interceptor";

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: authInterceptorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AccountIDInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: GlobalLoaderInterceptor, multi: true },
];
