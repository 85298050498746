import { Component, ElementRef, HostListener, NgZone, ViewChild, Renderer2  } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { AddLocationPopupComponent } from "../dialogbox/add-location-popup/add-location-popup.component";
import { MatDialog } from "@angular/material/dialog";
import {
  QuoteLineGrouped,
  QuoteLineModel,
} from "src/app/models/quote-line-model";
import { Subscription, distinctUntilChanged, elementAt, filter } from "rxjs";
import { QuoteState, SiteDetails } from "src/app/models/quoteState-model";
import { QuoteCreationService } from "src/app/features/quotes/services/quote-creation.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { JobSiteLocation } from "src/app/models/jobsite-model";
import { Driver, driver , DriveStep, Popover } from "driver.js";
import { set } from "lodash";
import { is } from "date-fns/locale";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
// import "driver.js/dist/driver.css";






@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent {

  displayedColumns: string[] = ["bundle"];
  // columnsToDisplay: string[] = this.displayedColumns.slice();
  dataSource: MatTableDataSource<any>;
  data = ["bundle"];
  additionalColumns: string[] = [];
  public isMobileView: boolean= false;;
  
  private isScrolled: boolean=false;
  private driver: Driver;
 
  bundleData: QuoteLineGrouped[] = [];

  
  currentQuote: QuoteState;
  intervalSubscription$: Subscription = new Subscription();
  pipe = new DatePipe("en-US");

 
  constructor(
    public quoteStateService: QuoteStateService,
    public quoteCreationService: QuoteCreationService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private ngzone:NgZone,
    private renderer: Renderer2,
    private profileService:ProfileService
    
  ) {
    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(this.bundleData);
  }

  ngOnInit() {
    if (window.innerWidth <= 959) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
   
    if (localStorage.getItem("siteDetailsId")) {
      const data = JSON.parse(
        localStorage.getItem("siteDetailsReqBody") as string
      );
      this.saveSiteDetails(data, true);
    }
    this.quoteStateService.currentQuote$
      .pipe(
        filter((quoteState) => quoteState.currentStep === 3),
        distinctUntilChanged(
          (prev, curr) =>
            prev.quoteModel.jobSites.length === curr.quoteModel.jobSites.length
        )
      )
      .subscribe((quoteState) => {
      

        this.currentQuote = quoteState;
        /**
         * Prepare bundle data for the table
         */
        this.bundleData = this.quoteStateService.prepareBundleData(
          quoteState.quoteModel.quoteLines
        );

        /**
         * Map job sites to bundle data
         */
        this.mapJobitesToBundleData(quoteState);
      });
     
  
  }
  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 959) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
 
 
  openAddLocationPopup(): void {
    this.dialog
      .open(AddLocationPopupComponent, {
        width: "80%",
        panelClass: "add-location-popup",
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (result.hasOwnProperty("column")) {
            this.addLocationColumn({ ...result.subSiteDetails, bundles: [] });
            return;
          }
          this.addLocationColumn({ ...result, bundles: [] });
        }
      });
  }

  addLocationColumn(data) {
    const newLocationName = "newLocation" +  (data.address.id) + (this.additionalColumns.length + 1);
    this.additionalColumns.push(newLocationName);
    this.data = [...this.data, newLocationName];
    this.displayedColumns = [...this.displayedColumns, newLocationName];

    this.bundleData.forEach((item) => {
      item[newLocationName] = {
        quantityQuoted: 0,
        ...data,
        columnId: newLocationName,
      };
    });
    console.log(this.bundleData);
  }
  onKeydown(e) {
    
    if(e.keyCode == 32){
return false;
    }else{
      return true;
    }
  
  }
  onChange(e, index: number, column: string) {
    console.log(e.target.value);
    const value = e.target.value;
    setTimeout(() => {
      if (value === "") {
        (document.getElementById(column + index) as HTMLInputElement).value =
          "0";
      }
      if (this.getAvailableQuantityColumn(column, index) >= Number(value)) {
        console.log(Number(value))
        this.bundleData[index][column]["quantityQuoted"] = Number(value);
      } else {
        (document.getElementById(column + index) as HTMLInputElement).value =
          "0";
        this.bundleData[index][column]["quantityQuoted"] = 0;
        this.toastr.error(
          "Cannot assign quantity more than available quantity."
        );
      }
    }, 0);
  }

  onFocus(index: number, column: string) {
    this.bundleData[index][column]["quantityQuoted"] = 0;
  }

  getLocations(column: string) {
    const location = this.dataSource.data.filter((location) => {
      const keys = Object.keys(location);
      return keys.includes(column) && location[column].address;
    })[0];

    return location[column];
  }

  getAvailableQuantityByIndex(index: number) {
    const bundle = this.dataSource?.data[index];
    if(bundle == undefined) return 0;
    const jobSiteIds = Object.keys(bundle).filter((key) =>
      key.includes("newLocation")
    );
    const total = jobSiteIds.reduce((totalQuantityQuoted, jobSiteId) => {
      return totalQuantityQuoted + bundle[jobSiteId].quantityQuoted;
    }, 0);
    return bundle.quantity - total;
  }

  getAvailableQuantityColumn(column: string, index: number) {
    const bundle = this.dataSource.data[index];
    const jobSiteIds = Object.keys(bundle).filter((key) =>
      key.includes("newLocation")
    );
    const total = jobSiteIds.reduce((totalQuantityQuoted, jobSiteId) => {
      return totalQuantityQuoted + bundle[jobSiteId].quantityQuoted;
    }, 0);
    return bundle.quantity - total;
  }

  getAdditionalServices(additionalServices: QuoteLineModel[]) {
    const addProduct = additionalServices.map((service) => {
      return service.product.description;
    });
    return addProduct;
  }

  mapJobitesToBundleData(quoteState: QuoteState) {
    const groupedLocations = quoteState.quoteModel.jobSites.reduce(
      (groupedLocations, jobSite) => {
        const addressId = jobSite.address.id || "";
        if (groupedLocations[addressId]) {
          groupedLocations[addressId].push(jobSite);
        } else {
          groupedLocations[addressId] = [jobSite];
        }
        return groupedLocations;
      },
      {}
    );
    Object.keys(groupedLocations).forEach((locationId) => {
      this.bundleData.forEach((item) => {
        item[`newLocation${locationId}`] = {
          quantityQuoted: 0,
          columnId: `newLocation${locationId}`,
          address: groupedLocations[locationId][0].address,
          contact: groupedLocations[locationId][0].contact,
        };
      });
    });

    this.additionalColumns = [];
    this.displayedColumns = ["bundle"];
    this.data = ["bundle"];

    Object.keys(groupedLocations).forEach((locationId) => {
      groupedLocations[locationId].forEach((location: JobSiteLocation) => {
        const index = this.bundleData.findIndex(
          (bundle) =>
            bundle.product.id === location.productDetails.bundleId &&
            bundle.quoteLineId === location.quoteLineId
        );
        this.bundleData[index][`newLocation${locationId}`] = {
          ...location,
          quantityQuoted: location.quantityQuoted,
        };
        if (!this.additionalColumns.includes(`newLocation${locationId}`)) {
          this.additionalColumns.push(`newLocation${locationId}`);
        }
        if (!this.data.includes(`newLocation${locationId}`)) {
          this.data = [...this.data, `newLocation${locationId}`];
        }
        if (!this.displayedColumns.includes(`newLocation${locationId}`)) {
          this.displayedColumns = [
            ...this.displayedColumns,
            `newLocation${locationId}`,
          ];
        }
      });
    });

    this.dataSource = new MatTableDataSource(this.bundleData);
  }

  onView(column: string) {

    const bundle= this.dataSource.data.find((item) => item[column]&& item[column]?.address?.id);
    this.dialog
      .open(AddLocationPopupComponent, {
        width: "80%",
        panelClass: "add-location-popup",
        disableClose: true,
        data: {
          type: "view",
          column: column,
          subSiteDetails: bundle[column],
          subsiteLength: this.additionalColumns.length,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == true) {
          if (this.additionalColumns.length == 1) {
            this.toastr.error(
              "Atleast one location must exists for the quote."
            );
            return;
          }

          this.bundleData.forEach((item) => {
            delete item[column];
          });
          const additionalColumnsindex = this.additionalColumns.indexOf(column);
          const dataIndex = this.data.indexOf(column);
          const displayedColumnsIndex = this.displayedColumns.indexOf(column);
          this.additionalColumns.splice(additionalColumnsindex, 1);
          this.data.splice(dataIndex, 1);
          this.displayedColumns.splice(displayedColumnsIndex, 1);
          this.dataSource = new MatTableDataSource(this.bundleData);
        }

        this.bundleData.forEach((item) => {
          item[result.column] = {
            ...item[result.column],
            ...result.subSiteDetails,
          };
        });
      });
  }

  async onContinue() {
    let productQuantity = 0;
    let quantityQuoted = 0;

    let locations = {};
    this.dataSource.data.forEach((jobSiteBundle) => {
      productQuantity += jobSiteBundle.quantity;

      Object.keys(jobSiteBundle).forEach((key: string) => {
        if (key.includes("newLocation")) {
          const bundle = {
            quantity: jobSiteBundle[key].quantityQuoted,
            bundleId: jobSiteBundle.product.id,
            bundleName: `${jobSiteBundle.assetList[0].product.description}  ${jobSiteBundle.services[0].product.description}`,
            assetId: jobSiteBundle.assetList[0].product.id,
            assetName: jobSiteBundle.assetList[0].product.description,
            serviceId: jobSiteBundle.services[0].product.id,
            serviceName: jobSiteBundle.services[0].product.description,
            quoteLineId: jobSiteBundle.quoteLineId,
          };
          quantityQuoted += jobSiteBundle[key].quantityQuoted;
          if (bundle.quantity === 0) {
            return;
          }
          if (locations[key]) {
            locations[key]?.bundles.push(bundle);
          } else {
            locations[key] = {
              addressId: jobSiteBundle[key].address.id,
              siteName: jobSiteBundle[key].address.siteName,
              // ...jobSiteBundle[key],
              bundles: [bundle],
            };
          }
        }
      });
    });


    if (productQuantity !== quantityQuoted) {
      this.toastr.error(
        "All quantities must be assigned to the added locations."
      );
      return;
    }

    let requestId = "";
    if (!localStorage.getItem("siteDetailsId")) {
      requestId = await this.quoteStateService.generateRequestID();
      localStorage.setItem("siteDetailsId", requestId);
    } else {
      requestId = localStorage.getItem("siteDetailsId") as string;
    }
    this.currentQuote = this.quoteStateService.getCurrentValue();
    const address = Object.assign(
      {},
      this.currentQuote.step3.SiteDetails,
      {
        startTime: this.currentQuote.step3.SiteDetails?.startTime
          ? this.currentQuote.step3.SiteDetails.startTime
          : "",
        endTime: this.currentQuote.step3.SiteDetails?.endTime
          ? this.currentQuote.step3.SiteDetails.endTime
          : "",
      }
    );

    const data = {
      requestId,
      ussPortalUserId:this.profileService.userProfile$.value?.ussPortalUserId,
      startDate: this.pipe.transform(
        this.currentQuote.step1.startDate,
        "yyyy-MM-dd"
      ),

      endDate: this.currentQuote.step1.endDate,
      contactData: this.currentQuote.step3.contactData,
      addressData: address,
      quoteId: this.currentQuote.quoteId as string,
      contactId: this.currentQuote.step3.contactData?.contactId,
      addressId: this.currentQuote.step3.SiteDetails?.addressId,
      contactExist: this.currentQuote.step3.contactData?.contactId
        ? true
        : false,
      subSites: Object.values(locations),
    };
    localStorage.setItem("siteDetailsReqBody", JSON.stringify(data));
    this.saveSiteDetails(data);
    
  }

  async saveSiteDetails(data: any, isRefreshed: boolean = false) {
    const callBack = () => this.quoteCreationService.saveSiteDetails(data);

    this.intervalSubscription$ = this.quoteStateService.startInterval(
      callBack,
      3000,
      "siteDetails"
    );
  }
 
 
 
  
  
   driverJs(){
    if(!this.isMobileView){
      const steps = this.getSteps();
      this.driver= driver({
        showProgress: true,
        steps: steps
      });
      this.driver.drive();
          
      
 }
}

private getSteps():any{
  return  [
    { element: '.bundle-details', popover: { title: 'Bundle Details', description: 'Product added to the quote.'} },
    {element: '.quantity_div', popover: { title: 'Quantity', description: 'Available and Total quantity of product.'} },
    {element: '.site_name', popover: { title: 'Location', description: 'Location on the site.', }},
    { element: '.quantity-input', popover: { title: 'Assigned Quantity', description: 'Assigned of quantity to location.'} },
    {element: '.more-option', popover: { title: 'Update and Delete Location', description: 'Click on this to Update and Delete Location.'} },
    {element: '.add_Location', popover: { title: 'Add Location', description: 'Add new location to the site' } },
      
  ];
}

addQuantity(index: number, column: string){
  while(this.getAvailableQuantityByIndex(index) > 0){
    this.bundleData[index][column]["quantityQuoted"] = this.bundleData[index][column]["quantityQuoted"] + 1;
    break;
  }
}
reduceQuantity(index: number, column: string){
  if(this.bundleData[index][column]["quantityQuoted"] > 0){
    this.bundleData[index][column]["quantityQuoted"] = this.bundleData[index][column]["quantityQuoted"] - 1;
  }
}

}