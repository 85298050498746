export const durationPicklistConstant = [
  "<1 Month",
  "1-3 Months",
  "4-6 Months",
  "6-12 Months",
  "12-24 Months",
  "24+ Months",
];

export enum DurationCategory {
  LessThan1Month = "<1 Month",
  OneToThreeMonths = "1-3 Months",
  FourToSixMonths = "4-6 Months",
  SixToTwelveMonths = "6-12 Months",
  TwelveToTwentyFourMonths = "12-24 Months",
  TwentyFourPlusMonths = "24+ Months"
}

export function getDurationCategory(months: number): DurationCategory {
  if (months < 1) {
    return DurationCategory.LessThan1Month;
  } else if (months >= 1 && months <= 3) {
    return DurationCategory.OneToThreeMonths;
  } else if (months >= 4 && months <= 6) {
    return DurationCategory.FourToSixMonths;
  } else if (months >= 7 && months <= 12) {
    return DurationCategory.SixToTwelveMonths;
  } else if (months >= 13 && months <= 24) {
    return DurationCategory.TwelveToTwentyFourMonths;
  } else {
    return DurationCategory.TwentyFourPlusMonths;
  }
}
