<section class="p-3">
  <div
    class="popup-header pb-3"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <img src="assets/img/icons/delete_with_background.svg" alt="" />
    <mat-icon class="close cursor-pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="popup-body" [formGroup]="reasonFormGroup">
    <p class="text py-2">Why do you wish to cancel this quote? *</p>
   <div class="py-2">
    
    <mat-radio-group class="w-100" color="primary" formControlName="reason" aria-label="Select an option">
      <mat-radio-button value="Project Canceled">Project Canceled</mat-radio-button>
      <mat-radio-button value="Price">Price</mat-radio-button>
      <mat-radio-button value="Customer Service">Customer Service</mat-radio-button>
      <mat-radio-button value="Other">Other</mat-radio-button>
    </mat-radio-group>
    <mat-error class="px-3" *ngIf="reasonFormGroup.controls['reason'].touched && reasonFormGroup.controls['reason'].invalid"> Required.</mat-error>

   </div>
   

    <mat-form-field
      class="w-100 comment_textarea textarea"
      appearance="outline"
    >
      <textarea
        matInput
        class="no_resize"
        formControlName="feedback"
        placeholder="Share your feedback!"
        rows="3"
      ></textarea>
    </mat-form-field>
  </div>
  <div
    class="btn-wrapper"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="12px"
  >
    <button
      mat-stroked-button
      color="primary"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="close()"
      class="cancel-btn"
    >
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="confirm()">Confirm</button>
  </div>
</section>
