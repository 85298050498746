import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import {  NEVER, Observable,from, switchMap, } from "rxjs";
import { ConfigService } from "../../services/config/config.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
@Injectable()
export class authInterceptorInterceptor implements HttpInterceptor {
  authToken: string;
  constructor(
    private authService: AuthenticationService,
    private configService: ConfigService,
    private profileService:ProfileService
  ) {

  }
  async getAuthToken(): Promise<string> {
    // Simulate an async operation, e.g., token retrieval
    return this.authService.getAccessToken() as Promise<string>;
  }
 

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    if (
      this.profileService.isRestrictedAccess() &&
      !req.url.includes("users/me") && !req.url.includes("signup") && !req.url.includes("auth0/last-login")
    ) {
      return NEVER;
    }
    
    if(req.url.includes(`${this.configService.getConfigProperty("API_URL")}`) && !req.url.includes("rfq")) {

    
    return from(this.getAuthToken()).pipe(
      switchMap(token => {
        if(token) {
         
                // Clone the request to add the new header
        const authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
        // Pass on the cloned request instead of the original request
        return next.handle(authReq);}
        
        return next.handle(req);
      })
    );
  }
  return next.handle(req);

  }
}

