import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { firstValueFrom } from "rxjs";
import { AuthenticationService } from "../shared/services/authentication/authentication.service";

export const sysadminGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const auth = inject(AuthenticationService);
  try {
    let token = await auth.getAccessToken()||"";
    const payload = JSON.parse(atob(token.split('.')[1]));
    console.log("payload: ",payload);
    if (payload['permissions'].includes('all:redis:sysadmin')) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error getting token", error);
    return false;
  }
};
