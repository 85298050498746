import { Component, Input } from "@angular/core";
import { OrderModel } from "src/app/models/order-model";
import { OrderService } from "../../../features/orders/services/order.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-order-summary-header",
  templateUrl: "./order-summary-header.component.html",
  styleUrls: ["./order-summary-header.component.scss"],
})
export class OrderSummaryHeaderComponent {
  @Input() orderName: string | undefined = "";
  @Input() siteAddress: string | undefined = "";
  @Input() startDate: string | undefined = "";
  @Input() endDate: string | undefined = "";
  @Input() status: string | undefined = "";
  @Input() showEdit: boolean = false;
  @Input() btnConfig: {
    name: string;
    url: string;
  } = {
    name: "Edit Order",
    url: "",
  };
  constructor(private router:Router){}

  ngOnInit() {}

  click() {
this.router.navigateByUrl(this.btnConfig.url);
  }
}
