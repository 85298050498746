<div [ngClass]="isRoleBased ? 'parent-div':''" >
  <div [ngClass]="isRoleBased ? 'grid-container':'wrapper'">
    <mat-card [ngClass]="isRoleBased ? 'card-role':'card-selection'">
      <mat-card-title class="center-header">
        <img
        class="customer-icons"
        src="../../../assets/img/icons/existing-customer.svg"
        alt=""
      />
        <h5>
          Existing Customer
        </h5>
      </mat-card-title>
      <mat-divider></mat-divider> 
      <!-- <mat-card-content [ngClass]="isRoleBased ? 'role-signup-card':'signup-card'"> -->
      <mat-card-content class="role-signup-card"> 
        <p class="sub-info" *ngIf="isRoleBased"> Please contact your Account Owner or Admin to link your user to the existing customer account. If you do not know who that is or need additional assistance, send an email to <span class="copyable">{{customerOnboardingEmail}}</span> and provide <b>one</b> of the following:</p>
  
       <p class="sub-info" *ngIf="!isRoleBased"> Please send an email to <span class="copyable">{{customerOnboardingEmail}}</span> and provide <b>one</b> of the following:</p>
        <ul class="main-info pl-3">
          <li>Your United Site Services account number and name
            <br>-- OR --
          </li>
          <li>A copy of a recent invoice</li>
        </ul>
      </mat-card-content>
      <div class="button-container"> 
        <button mat-raised-button color="primary" class="rounded-button" (click)="openGmailCompose()"  >
          Email Us
        </button>
      </div>
      <div class="bottom-heading">
        <p>This extra step helps to keep your data safe by preventing unauthorized access.</p>
        <p>Thank you for your help in protecting your account.</p>
      </div>
      
  
    </mat-card>
  
    <mat-card (click)="cardClick()" [ngClass]="isRoleBased ? 'card-role':'card-selection'">
      <mat-card-title class="center-header">
        <img
        class="customer-icons"
        src="../../../assets/img/icons/new-customer.svg"
        alt=""
      />
        <h5>
          New Customer
        </h5>
      </mat-card-title>
      <mat-divider></mat-divider> 
      <mat-card-content  [ngClass]="isRoleBased ? 'role-signup-card':'signup-card'">
        <p class="main-info" ><strong> NOTE:</strong> If you are an existing customer and click "Create Account", you will be creating a new customer account that will be separate from any history with United Site Services.</p>
      </mat-card-content>
      <div class="spacer"></div>
      <div [ngClass]="isRoleBased ? 'role-button-container':'button-container'"> 
        <button mat-raised-button color="primary" class="rounded-button" mat-dialog-close>
          Create New Account
        </button>
      </div>
    </mat-card>
  </div>
</div>

