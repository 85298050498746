<button mat-icon-button [matMenuTriggerFor]="userHelp" #userHelpTrigger="matMenuTrigger"
  *ngIf="helpDocsEnabled || technicalSupportEnabled">
  <mat-icon class="help-icon" aria-hidden="false" aria-label="help icon" fontIcon="help">help_outline</mat-icon>
</button>

<mat-menu #userHelp="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu p-2">
  <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->

  <div  *ngIf="helpDocsEnabled" class="menu_items p-1" (click)="openLinkInNewTab()">
    <div class="icon_text">
      <!-- <mat-icon class="px-2">edit</mat-icon> -->
      <div>
        <p class="text_blue text_css">Knowledge Base</p>
        <!-- <p class="text_blue text_description">Lorem ipsum dolor sit amet ipsum dolor</p> -->
      </div>

    </div>

    <div>
      <mat-icon color="primary">arrow_forward</mat-icon>
    </div>
  </div>




  <div  *ngIf="technicalSupportEnabled" class="menu_items p-1" (click)="onTechnicalSupport()">
    <div class="icon_text">
      <!-- <mat-icon class="px-2">edit</mat-icon> -->
      <div>
        <p class="text_blue text_css">Technical Support</p>
        <!-- <p class="text_blue text_description">Lorem ipsum dolor sit amet ipsum dolor</p> -->
      </div>

    </div>

    <div>
      <mat-icon color="primary">arrow_forward</mat-icon>
    </div>
  </div>

  <!--</span>-->
</mat-menu>